@value v-med from '../../../styles/breakpoints.css';
@value v-standardBorder from '../../../styles/global_ui/util.css';

.imageShadow {
  box-shadow: 0px 1px 4px -1px rgba(0,0,0,0.15);
}

/* Featured */
.featuredCard {
  border-bottom: v-standardBorder;
}

.featuredCard:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.featuredHero {
  flex-direction: column;
  padding-bottom: 30px;
}

.featuredSm {
  flex-direction: row-reverse;
  padding: 30px 0;
}

.featuredImgWrapperSm {
  margin-left: 15px;
  max-width: 213px;
  height: 120px;
}

/* Light Card (Latest, Trending) */
.lightCardWrapper {
  border-bottom: v-standardBorder;
  padding: 15px 0;
  justify-content: space-between;
  word-break: break-word;

  &:last-child { border: none }
}

/* Sponsored */
.sponsoredCard {
  display: flex;
  flex: 1;
  overflow-wrap: break-word;
}

.sponsoredImgWrapper {
  height: 67px;
  margin-right: 15px;
  max-width: 67px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sponsoredDataWrapper {
  align-self: center;
  width: 100%;
}

.sponsoredDataWrapperLg {
  align-self: center;
  margin-left: 15px;
  width: 100%;
}

@media v-med {
  .featuredImgWrapperSm {
    max-height: 120px;
    max-width: 120px;
    overflow: hidden;
  }

  .marginLeftReset { margin-left: 0; }

  .sponsoredCardLg {
    flex-direction: column;
    align-items: flex-start;
  }

  .sponsoredDataWrapperLg {
    align-self: flex-start;
    margin-top: 10px;
  }
}
