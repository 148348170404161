@value v-hackster, v-staticWhite, v-white from '../../../styles/global_ui/colors.css';

.badge {
  color: v-staticWhite;
  background-color: v-hackster;
  position: absolute;
  top: -3px;
  left: 9px;
  border-radius: 12px;
  font-size: 10px;
  min-height: 15px;
  min-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  width: 500px;
  border-radius: 4px;
  line-height: 1;
}

.footer {
  text-align: center;
  border-top: var(--standardBorder);
  padding: 10px 15px;
}

.header {
  text-align: right;
  border-bottom: var(--standardBorder);
  padding: 10px 15px;
}

.icon {
  position: relative;
}

.list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  max-height: 350px;
  overflow-y: scroll;
}

.loadingIcon {
  display: inline-block;
  width: 60px;
  margin: 30px;
}

.onboarding {
  background-color: v-white;
  padding: 20px 30px 30px 30px;
  border-bottom: var(--standardBorder);
  text-align: center;
}