.button {
  align-items: center;
  background-color: transparent;
  color: var(--graphite);
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1.6rem;
  justify-content: center;
  padding: 2.5% 3.5%;
  position: relative;
  margin-right: 2px;

  svg {
    path {
      fill: var(--charcoal);
    }
  }

  &.active {
    background-color: var(--hacksterHover);

    svg {
      path {
        fill: var(--staticWhite);
      }
    }
  }

  &:hover {
    background-color: var(--hacksterHover);

    svg {
      path {
        fill: var(--staticWhite);
      }
    }

    .tooltip {
      display: flex;
    }
  }

  &:focus {
    outline: none;
  }
}

.caret {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.6);
}

.label {
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--white);
  font-size: 10px;
  padding: 3px 5px;
  white-space: nowrap;
  min-width: 100%;
}

.menu {
  background-color: var(--white);
  border-radius: 1px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
  color: var(--graphite);
  display: block;
  font-size: 1rem;
  margin-top: 1%;
  position: absolute;
  z-index: 100;

  &:hover {
    color: var(--graphite);
  }
}

.option {
  padding: 10px 15px;
  position: relative;

  &:hover {
    background-color: var(--asphalt);
  }
}

.tooltip {
  align-items: center;
  display: none;
  flex-direction: column;
  position: absolute;
  top: 43px;
  width: 100%;
}