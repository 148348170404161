@value v-hacksterHover, v-graphite, v-staticWhite, v-white from '../../../styles/global_ui/colors.css';
@value v-standardBoxShadow from '../../../styles/global_ui/util.css';

.tooltip {
  margin: 0;
  position: absolute;
  z-index: 1000;
}


.tooltipInner {
  background-color: v-hacksterHover;
  border-radius: 0;
  color: v-staticWhite;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 8px;
  position: relative;
  white-space: nowrap;
}

.tooltipInner:before {
  border-color: transparent;
  border-style: solid;
  border-width: 5px 5px 5px;
  content: '';
  height: 0;
  position: absolute;
  width: 0;
}

.tooltipInnerMultiline {
  white-space: normal;
}

.wrapper {
  display: block;
  position: relative;

  .tooltip {
    opacity: 0;
    transition: opacity 250ms ease 100ms, visibility 250ms 100ms;
    visibility: hidden;
  }
}

.wrapper:hover {
  z-index: 2;

  .tooltip {
    opacity: 1;
    transition-delay: 0s;
    visibility: visible;
  }
}

/* shared utilities */
.centerH {
  left: 50%;
  transform: translateX(-50%);
}

.centerV {
  top: 50%;
  transform: translateY(-50%);
}

/* Direction-specific adjustments */
.bottom {
  composes: centerH;
  top: 100%;
  padding-top: 5px;

  .tooltipInner:before {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: v-hacksterHover;
  }
}

.left {
  composes: centerV;
  right: 100%;
  padding-right: 5px;

  .tooltipInner:before {
    border-left-color: v-hacksterHover;
    margin-top: -5px;
    right: -10px;
    top: 50%;
  }
}

.right {
  composes: centerV;
  left: 100%;
  padding-left: 5px;

  .tooltipInner:before {
    border-right-color: v-hacksterHover;
    left: -10px;
    margin-top: -5px;
    top: 50%;
  }
}

.top {
  composes: centerH;
  bottom: 100%;
  padding-bottom: 5px;

  .tooltipInner:before {
    border-top-color: v-hacksterHover;
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
  }
}

/**
 * New theme - TODO: use for all once we get the go-ahead from design
 */
.tooltipV2 {
  .tooltipInner {
    background-color: v-graphite;
    border-radius: 4px;
    box-shadow: v-standardBoxShadow;
    color: v-white;
    /* TODO: this is so dirty. can we switch ASAP? */
    font-family: "proxima-nova", "HelveticaNeue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    padding: 15px 30px;

    a,
    a:focus,
    a:hover {
      color: v-white;
      text-decoration: underline;
    }
  }

  .bottom {
    .tooltipInner:before {
      border-bottom-color: v-graphite;
    }
  }

  .left {
    .tooltipInner:before {
      border-left-color: v-graphite;
    }
  }

  .right {
    .tooltipInner:before {
      border-right-color: v-graphite;
    }
  }

  .top {
    .tooltipInner:before {
      border-top-color: v-graphite;
    }
  }
}