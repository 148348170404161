/**
 * Place only non-table specific styles here (i.e. flex styles for Drag and Drop tables).
 */

.cell {
  align-items: center;
  display: flex;
  flex: 1;
  border-left: var(--standardBorder);
  padding: 15px;
}

.cell0 {
  composes: cell;
  border: none;
  flex: 3;
}
