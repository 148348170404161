.enter, .appear {
  opacity: 0.01;
}

.enter.enterActive, .appear.appearActive {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exit.exitActive {
  opacity: 0.01;
}

.appear.appearActive, .enter.enterActive, .exit.exitActive {
  will-change: opacity;
  transition: opacity 250ms ease-in-out;
}
