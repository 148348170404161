@value v-pebble, v-charcoal, v-graphite, v-white, v-fog from '../../../../styles/global_ui/colors.css';
@value v-small from '../../../../styles/breakpoints.css';

.arrow {
  fill: v-graphite;
}

.arrowWrapper {
  align-self: center;
  cursor: pointer;
}

.card {
  border-bottom: 2px solid v-fog;
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

.cardInner {
  display: flex;
  justify-content: space-between;
}

.childrenSection {
  align-self: center;
  display: flex;
}

.dialogContainer {
  margin: 0 auto;
  max-width: 605px;
}

.dialogTitle {
  font-size: 32px;
  text-align: center;
}

.dialogDismissArrow {
  color: v-graphite;
  font-weight: 100;
  opacity: 1;
  z-index: 1;
}

.dropdown {
  position: relative;
  overflow: hidden;
}

.icon {
  height: 36px;
  margin-right: 10px;
  min-width: 36px;
  width: 36px;
}

.info {
  color: v-pebble;
}

.list {
  background-color: v-white;
  padding: 20px;
}

.noRecordsMessage {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.roundIcon {
  border-radius: 50%;
}

.roundIcon img {
  border-radius: 50%;
}

.subtitle {
  margin-bottom: 2px;
}

.textSection {
  display: flex;
  font-size: 15px;
  line-height: 18px;
  padding-right: 10px;
}

.title {
  color: v-graphite;
  display: block;
  font-weight: bold;
  line-height: 100%;
  margin-bottom: 2px;
}

.title:hover,
.title:focus {
  color: v-charcoal;
  text-decoration: none;
}

@media v-small {
  .dialogTitle {
    font-size: 24px;
  }

  .subtitle {
    display: none;
  }
}