.placeholderLink {
  font-weight: bold;
}

.label {
  font-size: 1em;
  font-weight: bold;
}

.content {
  font-size: 15px;
}
