/* TODO: This can get removed, the only NewFeatureTooltip is for sticky footer */
.arrow {
  position: absolute;
  background: var(--white);

  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
  }

  &:after {
    border-color: transparent;
    border-width: 7px;
  }

  &:before {
    border-color: rgba(29, 172, 248, 0);
    border-width: 8px;
  }
}

.container {
  border: 1px solid var(--hackster);
  margin-bottom: 20px;
  position: relative;

  &.bottom {
    .arrow {
      top: -16px;

      &:after {
        border-bottom-color: var(--white);
        margin-left: -7px;
        top: 2px;
      }

      &:before {
        border-bottom-color: var(--hackster);
        margin-left: -8px;
      }
    }
  }

  &.left {
    .arrow {
      right: 0;

      &:after {
        border-right-color: var(--white);
        margin-top: -7px;
      }

      &:before {
        border-right-color: var(--hackster);
        margin-top: -8px;
      }
    }
  }

  &.right {
    .arrow {
      left: 0;

      &:after {
        border-left-color: var(--white);
        margin-top: -7px;
      }

      &:before {
        border-left-color: var(--hackster);
        margin-top: -8px;
      }
    }
  }

  &.top {
    .arrow {
      bottom: 0;

      &:after {
        border-top-color: var(--white);
        margin-left: -7px;
      }

      &:before {
        border-top-color: var(--hackster);
        margin-left: -8px;
      }
    }
  }
}

.dismissBtn {
  background-color: transparent;
  border: 0;
  color: var(--hackster);
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  text-transform: uppercase;

  &:hover {
    color: var(--hacksterHover);
  }

  &:focus {
    outline: none;
  }
}

.inner {
  background-color: var(--white);
  padding: 15px 20px;
}

.msg {
  font-size: 14px;
  margin-bottom: 10px;

  i {
    color: var(--hackster);
    display: block;
    font-size: 30px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0 10px;
  }
}