.root {
  background-color: var(--white);
  min-height: 500px;
}

/**
 * Table View
 */
.headerCell {
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
  margin-bottom: 5px;
}

.ghostCell {
  align-items: center;
  border-right: var(--standardBorder);
  display: flex;
  flex-wrap: nowrap;
  height: 50px;
  padding: 0 15px;
}

.ghostDragBox {
  display: flex;
  flex-basis: 8%;
}

.greyNothing {
  composes: loader from '../../../styles/animation.css'; /* TODO: Remove composes and inline the loader styles or use the loader helpers.  */
  border-radius: 4px;
  border: none;
  height: 30px;
  width: 100%;
}

.tableContainer {
  border-bottom: var(--standardBorder);
  margin-bottom: 100px;
}

.tableHeader {
  display: flex;
}

/**
 * AddSectionView
 */
.inputContainer {
  max-width: 500px;
}

.menuContainer {
  background-color: var(--white);
  border: var(--standardBorder);
  border-top: none;
  max-height: 200px;
  max-width: 500px;
  overflow: auto;
  position: absolute;
  width: 100%;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.partOption {
  display: flex;
  font-size: 14px;
}

.partOptionDisabled {
  color: var(--pebble);
}

.partOptionName {
  font-weight: bold;
  margin-right: 10px;
}