@value v-standardBorder, v-standardBoxShadow from '../../../../react/styles/global_ui/util.css';


.contents {
  iframe {
    border: v-standardBorder;
    box-shadow: v-standardBoxShadow;
  }
}

.accordionIcon {
  max-width: 55px;
}