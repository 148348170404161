:global {

  /** Draft Overrides */
  .public-DraftStyleDefault-ul {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .public-DraftEditor-content {
    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }

  .draftster-block {
    padding-left: 40px;
    padding-right: 40px;
  }

  /** Helpers */
  .material-green {
    color: var(--success);
  }

  .material-red {
    color: var(--error);
  }

  .no-focus-outline {
    &:focus {
      outline: none;
    }
  }

  .block-padding {
    padding-left: 40px;
    padding-right: 40px;
  }

  .draftster-code-block {
    display: block;
  }

  /** Editor */
  .react-editor-wrapper {
    font-family: "proxima-nova", "HelveticaNeue", Helvetica, Arial, "Lucida Grande", sans-serif;

    .draftster-wrapper {
      background: var(--white);
      border: var(--standardBorder);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: none;
      margin-bottom: 10px;

      p {
        margin-bottom: 10px;
        margin-top: 0;
      }

      h3 {
        color: var(--pebble);
      }

      pre {
        display: block;
        background-color: var(--midnight);
        border-radius: 0px;
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 20px 40px;
        color: var(--white);
        overflow: visible;
        max-height: inherit;
      }

      blockquote {
        border-left: 5px solid var(--pebble);
        margin: 10px 40px;
      }
    }
  }

  .content-editable {
    padding: 2% 0;
    font-size: 1.5rem;
    word-wrap: break-word;
    font-family: "proxima-nova", "HelveticaNeue", Helvetica, Arial, "Lucida Grande", sans-serif;

    &:focus {
      outline: none;
    }
  }

  .no-outline-focus:focus {
    outline: none;
  }

  /** Embed Toolbar*/
  .react-editor-image-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);

    .reit-toolbar {
      .embed-toolbar-btn {
        padding: 12px 15px;
        height: 48px;
        width: 48px;
      }
    }
  }

  /** Video  */
  .react-editor-embeddable {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 20px 0px;

    &:focus {
      outline: 2px solid var(--sky);
    }

    .react-editor-embeddable-inner {
      display: flex;
      flex-direction: row;
      position: relative;

      .react-editor-figure {
        flex: 1 0 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
        margin: 0;

        .react-editor-embed-wrapper {
          display: block;
          z-index: 0;
          width: 100%;
          height: auto;
          max-height: 100%;
          position: relative;

          .react-editor-embed {
            width: 100%;
            max-width: 686px;
            max-height: 487px;
            height: auto;
            flex: 1;
            z-index: 1;
            align-self: center;
            box-sizing: border-box;
            overflow: hidden;
          }

          .react-editor-embed-override {
            max-height: 100%;
            max-width: 100%;
          }

          .react-editor-figcaption {
            color: var(--pebble);
            position: relative;
            bottom: 0;
            height: 30px;
            width: 100%;
            overflow: hidden;
            background-color: var(--white);
            padding: 5px;
            font-size: 1.4rem;
            font-style: italic;
            font-weight: 200;
            text-align: center;
            z-index: 1;
            align-self: center;
            border: none;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }

  /** File */
  .react-editor-file {
    display: flex;
    justify-content: center;
    align-items: center;
    border: var(--standardBorder);
    padding: 22px 17px;
    background: var(--fog);
    font-weight: bold;
    color: var(--pebble);
    margin: 20px 0px;

    a {
      margin-left: 10px;
    }
  }

  /** Placeholder */
  .react-editor-placeholder {
    text-align: center;
    min-height: 48px;
    width: 100%;
  }

  /** Widget Placeholder */
  .react-editor-widget-placeholder {
    border: var(--standardBorder);
    border-radius: 3px;
    padding: 10px;
    margin: 20px 0px;
    min-height: 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &.twitter {
      border: none;
      margin: 0;
    }

    .widget-placeholder-overlay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  /** PopUp */

  .react-link-popover-wrapper {

    .react-link-popover {

      .link-popover-init-container {
        background-color: var(--hackster);
        border-radius: 2px;

        .link-popover-input {
          display: flex;
          color: var(--staticWhite);
          background-color: var(--hackster);
          border-radius: 2px;
          padding: 5px;
          width: 250px;
          border: none;
          text-align: center;
        }

        .link-popover-input:focus {
          outline: none;
        }
      }

      .link-popover-change-container {
        background-color: var(--hackster);
        border-radius: 2px;
        max-width: 500px;
        padding: 5px 10px;

        label {
          display: flex;
          max-width: 500px;
          padding: 5px;
          color: var(--asphalt);
          margin-bottom: 5px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .link-popover-input {
          margin-left: 10px;
          color: var(--staticWhite);
          background-color: transparent;
          border: none;
          text-align: center;
        }

        .link-popover-input:focus {
          outline: none;
        }
      }
    }
  }

  /** Dialog */
  .no-scroll {
    overflow: hidden;
  }

  .fixed-toolbar-modal {
    position: fixed;
  }

  .dialog-body {
    display: flex;
    flex-direction: column;

    .actions-wrapper {
      display: flex;
      flex: 1;
      align-items: flex-end;
    }

    .close {
      font-size: 45px;
      position: absolute;
      top: 0;
      right: 8px;
      outline: 0;
      font-weight: normal;

      &:hover {
        color: var(--staticBlack);
      }

      &:focus {
        outline: none;
      }
    }
  }

  /** Messenger */
  .react-editor-messenger {
    z-index: 5000;
    position: fixed;
    width: 500px;
    max-width: 90%;
    border-width: 2px;
    padding: 15px 20px;
    right: 10px;
    top: 10px;

    .alert-success {
      color: var(--successToWhite);
      background-color: var(--successBG);

      .messenger-body {
        border-left: 14px solid var(--successToForest);
      }
    }

    .alert-danger {
      background-color: var(--errorBG);
      color: var(--errorToWhite);

      .messenger-body {
        border-left: 14px solid var(--errorToDarkError);
      }
    }

    .messenger-body {
      padding: 3% 10%;

      .messenger-close-button {
        background: transparent;
        border: none;
        position: absolute;
        top: 15px;
        right: 25px;
      }
    }
  }

  .messenger-appear {
    right: -500px;
  }

  .messenger-appear.messenger-appear-active {
    right: 10px;
    transition: right .5s ease-in-out;
  }
}