@value v-med-large from '../../../styles/breakpoints.css';

/* Active Contest */
.activeContestLargeCardWrapper {
  display: flex;
  max-height: 300px;
  min-height: 300px;
  background: var(--cardBGColor);
}

.activeContestImgWrapper {
  flex: 1;
  max-width: 630px;
  min-width: 630px;
}

.activeContestInfoContainer {
  display: flex;
  flex: 1;
}

.activeContestInfoWrapper {
  padding: 20px;
}

.activeContestPrizeContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  max-width: 270px;
  padding:  0 20px 20px 20px;
  width: 100%;
}

.activeContestPrizeIcon {
  height: 100%;
  max-height: 38px;
  max-width: 32px;
  width: 100%;
}

.activeMobileCardContainer {
  display: none;
  flex: 1;
  width: 100%;
}

.activeMobileImgAspectRatioPadding {
  display: block;
  padding-bottom: 47.62%;
}
/* End Active Contest */

/* Pinned Card */
.contestBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 15px 15px 15px;
}

.daysLeft {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.lazyImage {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.spacerForResizableCard {
  margin-top: 25%;
}
/* End Pinned Card */

/* Banner Card */
.bannerCard {
  &:hover {
    .bannerCardOverlay{
      background: var(--white);
      cursor: pointer;
      height: 100%;
      opacity: 0.9;
      visibility: visible;
      width: 100%;
    }
  }
}

.bannerCardOverlay {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 2.5%;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms ease-in-out;
  visibility: hidden;
}
/* End Banner Card */

/*Media Queries*/
@media v-med-large {
  .bannerCardOverlay {
    display: flex;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    top: 0;
    visibility: visible;
  }

  .bannerCardOverlayName {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
    display: inline-flex;
    padding: 3px 6px;
    position: inherit;
  }

  .proIconPastContests {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 2.5%;
  }

  .proIconMobileBg {
    background: rgba(255, 255, 255, 0.7);
  }
}

@media (max-width: 870px) {
  .activeContestActionable { width: 100%; }
  .activeContestLargeCardWrapper { display: none; }
  .activeContestStatusWrapper { margin-top: 30px; }
  .activeMobileCardContainer { display: block; }
}
/* Hides the far right panel of the large active card */
@media (max-width: 1025px) {
  .activeContestPrizeContainer { display: none; }
  .activeContestImg {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

