.appear {
  opacity: 0.01;
}
.appear.appearActive {
  opacity: 1;
  transition: opacity 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.enter {
  opacity: 0.01;
}
.enter.enterActive {
  opacity: 1;
  transition: opacity 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.exit {
  position: absolute;
  opacity: 1;
}
.exit.exitActive {
  opacity: 0;
}
