.enter {
  opacity: 0.01;
}
.enter.enterActive {
  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.exit {
  opacity: 1;
}
.exit.exitActive {
  transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  opacity: 0.01;
}