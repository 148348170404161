@value v-hackster, v-hacksterHover, v-white from '../../../styles/global_ui/colors.css';

.badge {
  border: 1px solid v-hackster;
}

.text {
  font-weight: bold;
  color: v-hackster;
  line-height: normal;
}

.sText {
  font-size: 6px;
  padding: 0px 1px;
}

.mText {
  font-size: 12px;
  padding: 0px 2px;
}

.lText {
  font-size: 28px;
  padding: 0px 4px;
}

.hacksterLogo {
  background: v-hackster; /* static ? */
  color: var(--staticWhite);
}

.small {
  padding: 1px;
}

.medium {
  padding: 3px;
}

.large {
  padding: 5px;
}

.sContainer {
  height: 9px;
}

.mContainer {
  height: 16px;
}

.lContainer {
  height: 35px;
}