@value v-hackster, v-lake, v-sky from '../../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../../styles/global_ui/util.css';


.label {
  margin-bottom: 0;
  padding: 5px 10px;
  border-radius: v-standardBorderRadius;
  border: 1px solid v-lake;
  cursor: pointer;
}

.label:hover {
  outline: 1px solid v-hackster;
}

.checked {
  background: var(--hacksterDisabled);
  div {
    color: var(--lake) !important;
  }
}