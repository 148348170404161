@value v-med from '../../../styles/breakpoints.css';

.formPanel {
  width: 370px;
  flex-shrink: 0;
}

.panelWithCTA {
  display: flex;
  flex-direction: row;
  max-width: 970px;
}

@media v-med {
  .panelWithCTA {
    flex-direction: column;
    max-width: 466px;
  }

  .formPanel {
    width: 466px;
  }
}

/* not pretty, but it works (616 = 466inner + (15margin * 2) + (30padding * 2)) */
@media (max-width: 616px) {
  .formPanel {
    width: 100%;
  }
}
