.root {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 20px 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  width: 100%;
}

.images {
  display: flex;
  flex-direction: row;
  position: relative;
}

.loader {
  align-items: center;
  /* do we want to update this? */
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--staticWhite);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.figure {
  flex: 1 0 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0;
  opacity: 0;
  display: none;
  transition: opacity 1.2s ease;
}

.show {
  display: flex;
  opacity: 1;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  z-index: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  position: relative;
}

.image {
  max-height: 487px;
  max-width: 100%;
  height: auto;
  z-index: 1;
  align-self: center;
  box-sizing: border-box;
  overflow: hidden;
}

.figcaption {
  color: var(--pebble);
  position: relative;
  bottom: 0;
  height: 30px;
  width: 100%;
  overflow: hidden;
  background-color: var(--white);
  padding: 5px;
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 200;
  text-align: center;
  z-index: 1;
  align-self: center;
  border: none;

  &:focus {
    outline: none;
  }
}

.controlBtn {
  color: var(--pebble);
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0px;
  height: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--pebble);
  }
}

.left {
  left: -35px;
}

.right {
  right: -35px;
}

.reorderDialog {
  background-color: rgba(0, 0, 0, 0.541176);
}