@value v-hackster from '../../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../../styles/global_ui/util.css';

.label {
  margin-bottom: 0;
  padding: 6px 7px;
  line-height: 0;
  cursor: pointer;
  svg {
    width: 28px;
  }
}

.label:hover {
  outline: 1px solid v-hackster;
  border-radius: v-standardBorderRadius;
}

.checked {
  outline: 1px solid v-hackster;
  border-radius: v-standardBorderRadius;
  background: var(--hacksterDisabled);
}