@value v-error, v-errorBG, v-errorToWhite, v-fog, v-hackster, v-sky, v-hacksterHover, v-charcoal, v-white from '../../../styles/global_ui/colors.css';

.actions {
  padding: 15px;
  background-color: v-fog;
  border-radius: 3px;
  border: var(--standardBorder);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inlineCell {
  display: flex;
  gap: 5px;
  align-items: center;
}

.filterForm label {
  margin: 0;
}

.row td {
  border: var(--standardBorder);
  padding: 10px;
}

.row td.control:hover {
  background-color: v-fog;
}

.row td.control {
  background-color: v-white;
  border: none;
  max-width: 40px;
  padding: 0;
}

.row:has(input:checked) {
  background-color: v-fog;
  color: v-sky;
}

.row:has(input:checked) td.control:hover {
  background-color: v-white;
}

.rowDeleted:has(input:checked),
.rowDeleted {
  background-color: v-errorBG;
  color: v-errorToWhite;
}

.deleteLabel {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin-bottom: 0;
  cursor: pointer;
  text-align: center;
}

.deleteLabel input {
  cursor: pointer;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--standardBorder);
}

.pageHeader h1 {
  font-size: 40px;
  font-weight: bold;
}

.pencilIcon {
  width: 15px;
}

.popover_container {
  max-width: 80vw;
}