@value v-asphalt, v-asphaltHover, v-charcoal, v-staticCharcoal, v-error, v-errorHover, v-fog, v-graphite, v-staticGraphite, v-hackster, v-hacksterHover, v-lake, v-ocean, v-pebble, v-sky, v-staticWhite, v-white from './colors.css';

/**
 * general button styles. "primary" color scheme is implicitly defined here.
 * color modifiers below override this.
 */
.jumbo,
.lg,
.md,
.sm,
.xs,
.xxs,
.jumbo:disabled:hover,
.lg:disabled:hover,
.md:disabled:hover,
.sm:disabled:hover,
.xs:disabled:hover,
.xxs:disabled:hover {
  background-color: v-hackster;
  border: 1px solid v-hackster;
  border-radius: 4px;
  outline: none;
  color: v-staticWhite;
}

.jumbo,
.lg,
.md,
.sm,
.xs,
.xxs {
  font-weight: bold;
}

/* anchor overrides (for defeating bootstrap) */
a.jumbo,
a.lg,
a.md,
a.sm,
a.xs,
a.xxs {
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.jumbo,
.lg,
.md {
  font-size: 16px;
  line-height: 21px;
}

/* state variations for default color style */
.lg:active,
.lg:hover,
.md:active,
.md:hover,
.sm:active,
.sm:hover,
.xs:active,
.xs:hover,
.xxs:active,
.xxs:hover {
  background-color: v-hacksterHover;
  border-color: v-hacksterHover;
}

.lg:active,
.md:active,
.sm:active,
.xs:active,
.xxs:active {
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}

.lg:focus,
.md:focus,
.sm:focus,
.xs:focus,
.xxs:focus {
  border-color: v-hacksterHover;
}

.disabled,
.jumbo:disabled,
.lg:disabled,
.md:disabled,
.sm:disabled,
.xxs:disabled,
.xs:disabled {
  cursor: default;
  /* anchor un-override */
  opacity: 0.5;
}

.lg:hover,
.lg:focus,
.md:hover,
.md:focus,
.sm:hover,
.sm:focus,
.xs:focus,
.xxs:focus {
  color: var(--staticWhite);
  /* TODO: this maybe should be refactored into color modifier classes rather than the global reset */
  outline: none;
}

/* more anchor overrides */
a.jumbo:hover,
a.jumbo:focus,
a.lg:hover,
a.lg:focus,
a.md:hover,
a.md:focus,
a.sm:hover,
a.sm:focus,
a.xs:hover,
a.xs:focus,
a.xxs:hover,
a.xxs:focus {
  text-decoration: none;
}

/**
 * Size modifiers
 */
.lg {
  padding: 15px 20px;
}

.md {
  padding: 10px 15px;
}

.sm,
.xs {
  font-size: 14px;
  line-height: 18px;
}

.xxs {
  font-size: 12px;
  line-height: 14px;
}

.sm,
.xxs {
  padding: 5px 10px;
}

.xs {
  padding: 3px 8px;
}

/* "jumbo" encompasses size and color - override color at own risk */
.jumbo,
.jumbo:disabled:hover {
  background-color: v-white;
  border: 1px solid v-white;
  color: v-graphite;
  padding: 15px 30px;
}

.jumbo:active,
.jumbo:focus {
  border-color: var(--borderColor);
  outline: none;
}

.jumbo:active,
.jumbo:hover {
  color: v-hackster;
}

.jumboInner {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.jumboIcon {
  max-width: 45px;
  width: 100%;
}

/**
 * Color modifiers ("primary" is implicitly implemented as default above)
 */
.danger,
.danger:disabled:hover {
  background-color: var(--errorToDarkError);
  border-color: var(--errorToDarkError);
}

.danger:active,
.danger:hover {
  background-color: var(--staticErrorHover);
  border-color: var(--staticErrorHover);
}

.danger:focus {
  border-color: v-errorHover;
}

.secondary,
.secondary:disabled:hover {
  background-color: v-lake;
  border-color: v-lake;
}

.secondary:hover,
.secondary:active {
  background-color: v-ocean;
  border-color: v-ocean;
}

.secondary:focus {
  border-color: v-sky;
}

.tertiary {
  color: var(--staticWhite);
  background-color: var(--cobalt);
  border-color: var(--cobalt);
}

.tertiary:hover,
.tertiary:active,
.tertiary:focus {
  color:var(--staticWhite);
  background-color: var(--cobaltHover);
  border-color: var(--cobaltHover);
}

.tertiary:disabled{
  color: var(--staticWhite);
  background-color: v-lakeDisabled;
  border-color: v-lakeDisabled;
}

.tertiary:focus {
  outline: 1px solid var(--cobalt);
  color: var(--staticWhite);

}

.white {
  background-color: v-staticWhite;
  border-color: v-staticWhite;
  color: v-staticCharcoal;
}

.white:active,
.white:hover {
  background-color: v-fog;
  border-color: v-fog;
}

.white:focus {
  border-color: v-fog;
  background-color: v-fog;
}

.white:disabled {
  background-color: v-staticWhite;
  opacity: .5;
}

/* the base "outline" class is a static white outline */
.outline,
.outline:disabled:hover {
  background-color: transparent;
}

.outline,
.outline:disabled:hover,
.outline:hover,
.outline:active,
.outline:focus {
  border-color: v-staticWhite;
}

.outline:active,
.outline:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.outline:focus {
  background-color: transparent;
  box-shadow: 0px 0px 0px 1px v-staticWhite;
  /* so button doesn't jump, but still consistent with styles */
}

.outlineBlack,
.outlineBlack:disabled:hover,
.outlineBlack:active,
.outlineBlack:focus,
.outlineBlack:hover {
  border-color: v-graphite;
  color: v-graphite;
}

.outlineBlack,
.outlineBlack:disabled:hover {
  background-color: transparent;
}

.outlineBlack:active,
.outlineBlack:hover {
  background-color: var(--fog);
}

.outlineBlack:focus {
  background-color: transparent;
  box-shadow: 0px 0px 0px 1px v-graphite;
  /* so button doesn't jump, but still consistent with styles */
}

.outlineBlue,
.outlineBlue:disabled:hover,
.outlineBlue:active,
.outlineBlue:hover,
.outlineBlue:focus {
  background-color: transparent;
  border-color: v-hackster;
  color: v-hackster;
}

.outlineBlue:disabled {
  background-color: var(--fog);
  opacity: .5;
}

.outlineBlue:active,
.outlineBlue:hover {
  background-color: var(--fog);
}

.outlineBlue:focus {
  background-color: transparent;
  box-shadow: 0px 0px 0px 1px v-hackster;
  /* so button doesn't jump, but still consistent with styles */
}


/* Blank style - resets size and color modifiers, will honor font size if used with a size class above */
.blank {
  padding: 0;
}

.blank,
.blank:active,
.blank:hover,
.blank:focus,
.blank:disabled,
.blank:disabled:hover {
  background-color: transparent;
  border: none;
  color: inherit;
  box-shadow: none;
  outline: none;
}

.transparent {
  background-color: transparent;
  border-color: transparent;
}

.transparent:active,
.transparent:hover {
  background-color: v-fog;
  border-color: v-hackster;
}

.transparent:focus {
  border-color: v-hackster;
}

.transparent:disabled {
  opacity: .5;
}

/* cancel button - looks like a link, but has padding to align with other buttons */
.cancel,
.cancel:active,
.cancel:hover,
.cancel:focus,
.cancel:disabled,
.cancel:disabled:hover {
  background-color: transparent;
  border: 2px solid transparent;
  /* for alignment with other buttons */
  box-shadow: none;
  color: v-graphite;
  outline: none;
}

.cancel:hover,
.cancel:focus,
a.cancel:hover,
a.cancel:focus {
  text-decoration: underline;
}

.cancel,
.cancel:disabled:hover,
.cancel:disabled:focus {
  text-decoration: none;
}

/**.
 * Utilities for content/layout. use alongside a color modifier and/or a size modifier
 */
.dropdown.lg {
  padding-left: 7px;
  padding-right: 7px;
}

.dropdown.md {
  padding-left: 5px;
  padding-right: 5px;
}

.dropdown.sm {
  padding-left: 3px;
  padding-right: 3px;
}

.flatLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  gap: 2px;

  .lg:not(:first-of-type),
  .md:not(:first-of-type),
  .sm:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }

  .lg:not(:last-of-type),
  .md:not(:last-of-type),
  .sm:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.icon {
  font-weight: normal;
  line-height: 0;
  /* Otherwise the button gets bigger */
}

.icon.lg {
  padding: 15px;
}

.icon.md {
  padding: 10px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.icon.sm {
  padding: 6px;

  svg {
    height: 13px;
    width: 13px;
  }
}

.inputPairedRight {
  margin-left: 10px;
  max-height: 45px;
}


.pill {
  border-radius: 9999px;
}

.pill.sm {
  padding: 5px 15px;
}

.pill.md {
  padding: 10px 25px;
}

.pill.lg {
  padding: 15px 30px;
}

.unset {
  all: unset;
  line-height: 0;
}