.container {
  align-items: center;
  border-left: 2px solid var(--borderColor);
  display: flex;
  margin-left: 30px;
  max-width: 300px;
  padding-left: 10px;
}

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  max-width: calc(100% - 31px);  /* Minus the outer width of the icon */
}
