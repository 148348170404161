@value v-asphalt from '../../../../styles/global_ui/colors.css';

.icon {
  align-items: center;
  color: v-asphalt;
  display: flex;
  height: 100%;
  left: 0;
  padding-left: 15px;
  position: absolute;
}
