.placeholder {
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: center;
  width: 100%;
  padding: 0px 30px;
}

.placeholderContainer {
  background-color: var(--white);
  border: var(--standardBorder);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  min-height: 750px;
}
