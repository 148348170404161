@value v-standardBorder, v-standardBorderRadius from '../../../../styles/global_ui/util.css';
@value v-white from '../../../../styles/global_ui/colors.css';

.column {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 30px;
  width: 100%;
}

.column + .column {
  border-left: v-standardBorder;
}

.columnFeatured {
  composes: column;
  flex: 0 0 66.67%;
  max-width: 66.67%;
}

.linkList {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuContainer {
  background: v-white;
  border: v-standardBorder;
  border-bottom-left-radius: v-standardBorderRadius;
  border-bottom-right-radius: v-standardBorderRadius;
  box-shadow: 3px 8px 16px 2px rgb(0 0 0 / 5%), -3px 8px 16px 2px rgb(0 0 0 / 5%);
  border-top: none;
  display: flex;
  max-width: 1230px;
  width: 100%;
  z-index: 11;
}

.menuRoot {
  display: flex;
  justify-content: center;
  left: -32px;
  position: absolute;
  top: 33px;
  width: 926px;
}

/* Helps the GridList sit with proper padding. */
.projectGrid > :last-child,
.projectGrid > :nth-last-child(2)  {
  margin-bottom: -15px;
}

.projectGrid > :nth-child(even) {
  padding-left: 30px;
}
