@value v-med from '../../../../../styles/breakpoints.css';

.card {
  display: flex;
}

.imageContainer {
  display: block;
}

.content {
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  header {
    display: flex;
    align-items: center;
  }
}

@media v-med {
  .card {
    flex-wrap: wrap;
  }

  .imageContainer {
    display: none;
  }

  .content {
    margin-right: 0;
    order: 1;
    width: 100%;
    flex-shrink: 0;
  }
}
