@value v-med-min from '../../../../styles/breakpoints.css';

.buttons {
  flex-wrap: wrap;
}

.fileButton {
  width: 100%;
}

.or {
  margin: 10px 0;
  text-align: center;
  width: 100%;
}

.previewWrapper {
  margin-bottom: 15px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.urlInputWrapper {
  align-items: center;
  display: flex;
}

/**
* "responsive" view is the default view, which will have all elements horizontally arranged
* at wide window sizes. Otherwise, it will always be vertically stacked. this is for
* narrower containers that don't follow the standard form sizing
*/
@media v-med-min {
  .responsive {
    .buttons {
      flex-wrap: nowrap;
    }

    .fileButton {
      width: auto;
    }

    .or {
      margin: 0 20px;
      width: auto;
    }
  }
}