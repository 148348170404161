@value v-med from '../../../../../styles/breakpoints.css';

@media v-med {
  .largeCardInfoSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
