@value v-small from '../../../../styles/breakpoints.css';

@media v-small {
  .cardResponsive {
    flex-direction: row;
    align-items: center;
  }

  .imageResponsive {
    width: 120px!important;
    height: 120px;
    padding: 0;
  }

  .textResponsive {
    width: auto!important;
    flex: 1 1 0;
    margin: 0 0 0 15px!important;
  }
}
