@value v-fog, v-hackster, v-graphite, v-white from '../../../styles/global_ui/colors.css';
@value v-standardBorder from '../../../styles/global_ui/util.css';

.author {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  word-break: break-word;
}

.authorButton {
  composes: author;
  white-space: normal !important;
  text-align: left;
}

.authorButton:hover {
  text-decoration: underline;
}

.authorsContainer {
  border: none;
  left: 40px;
  bottom: 24px;
  min-width: 150px;
}

.authorsList {
  background-color: v-white;
  border: v-standardBorder;
  border-radius: 2px;
  list-style: none;
  margin: 0;
  max-height: 200px; /* 5 items max. Avoid raising this due to most card lists have an overflow hidden */
  overflow-y: scroll;
  padding: 2px 10px;

  li {
    margin: 0;
    padding: 10px 0px;
  }

  li + li {
    border-top: 1px solid v-fog;
  }
}

.dropdown {
  position: relative;
}

.imageContainer {
  display: block;
  position: relative;
  width: 100%;
}

.itemImage {
  display: block;
  height: 0;
  position: relative;
  overflow: hidden;
  padding-top: 75%;
}

.lazyImage {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.moreInfo {
  align-items: center;
  border-top: var(--secondaryBorder);
  background: color-mix(in srgb, var(--transparentToFog) 10%, transparent);
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
}

.overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 150ms ease;
  visibility: hidden;
}

.card:has(.imageContainer:focus) {
  outline: 2px solid v-hackster;
}


.card:hover .overlay {
  background-color: v-white;
  color: v-graphite;
  opacity: .95;
  visibility: visible;
}

.stat {
  display: flex;
  font-size: 12px;
  font-weight: normal;
}

.stats {
  align-items: center;
  display: flex;
  flex: 1;
}

.statNumber {
  margin-left: 5px;
}

.title {
  composes: title from '../cards.css';
  margin: 0 0 5px 0;
}