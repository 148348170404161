@value v-graphite, v-pebble from '../../../styles/global_ui/colors.css';
@value v-med, v-small from '../../../styles/breakpoints.css';

.dummyContainer {
  padding: 0px 0px 20px 0px;
  border: none;
}

.externalLink {
  fill: v-pebble;
  margin: 0 6px;
}

.projectListContainer {
  width: 100%;
}

.wrapper {
  display: flex;
}

.childWrapper {
  width: 100%;
}

.description {
  font-size: 15px;
}

.description a:hover {
  cursor: pointer;
}

/**
 * Navbar
 */
.navbar {
  list-style-type: none;
  border-left: 1px solid v-pebble;
  height: 100%;
  padding: 5px 0px 5px 20px;
  margin: 0px;
  min-width: 285px;
}

.navbarItem {
  margin-bottom: 5px;
}

.navbarLink {
  font-weight: bold;
  font-size: 15px;
  color: v-pebble;

  &:hover {
    color: v-graphite;

    .externalLink {
      fill: v-graphite;
    }
  }
}

.navbarLinkHighlight {
  color: v-graphite;
}

/*
since this container shares the screen with a sidebar, the grid's breakpoints (based on a full width) dont make sense
this is a ~custom~ breakpoint that allows the grid to break in a UI frendly way for this situation

TODO: maybe change projectList to take a class for the grid cells so that we can style those directly without using !important
*/

@media (max-width: 988px) {
  .listWrapper > div {
    flex: 0 0 50% !important;
  }
}

@media v-med {
  .wrapper {
    flex-direction: column;
  }

  .navbar {
    margin-bottom: 30px;
  }
}

@media v-small {
  .listWrapper > div {
    flex: 0 0 100% !important;
  }
}
