@value v-standardBorder from '../../../styles/global_ui/util.css';
@value v-hackster, v-white from '../../../styles/global_ui/colors.css';
@value fadeIn, fadeOut from '../../../styles//transitions/fade_in_out.css';

.dialogContent {
  background-color: v-white;
  z-index: 12;
}

.dialogClose {
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogClose:hover,
.dialogClose:focus,
.dialogClose:focus-visible {
  background-color: v-hackster;
  color: v-white;
}

.transitionEnabled[data-state='open'] {
  animation: fadeIn 250ms ease-out;
}

.transitionEnabled[data-state='closed'] {
  animation: fadeOut 250ms ease-in;
}
