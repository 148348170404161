@value v-asphalt, v-error, v-fog, v-graphite, v-hackster, v-white from '../../../../styles/global_ui/colors.css';

.arrowButtons {
  display: inline-flex;
  flex-direction: column;
}

.buttons {
  visibility: hidden;
}

.clear {
  &:hover, &:focus {
    color: v-error;
  }
}

.input {
  caret-color: transparent;
  color: v-graphite;
  cursor: default;
  display: inline-block;
  padding: 0;
  width: 2em;
  text-align: center;
}

.input,
.input:active,
.input:hover,
.input:focus
{
  border: none;
  outline: none;
  box-shadow: none;
}

.inputWide {
  composes: input;
  width: 3em;
}

.input::placeholder {
  color: v-asphalt;
}

.input::selection {
  background-color: transparent;
}

.input:focus {
  background: v-hackster;
  color: v-white;
}

.toggle:hover, .toggle:focus {
  color: v-hackster;
}

.wrapper {
  background-color: v-white;
  width: auto;
}

.wrapperMobile {
  composes: wrapper;
  padding: 15px 10px;
}

.wrapperDesktop {
  composes: wrapper;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding: 5px;
}

.wrapper:hover .buttons {
  visibility: visible;
}

.wrapperDisabled, .wrapperDisabled input {
  background-color: v-fog;
  color: v-asphalt;

  &:hover {
    border-color: var(--borderColor);
    .buttons {
      visibility: hidden;
    }
  }
}
