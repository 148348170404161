.btn {
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btnPrimary {
  background-color: var(--hacksterHover);
  border-color: var(--lake);
  color: var(--staticWhite);

  &:hover {
    background-color: var(--hacksterHover);
  }

  &:focus {
    outline: none;
  }
}

.btnLink {
  background-color: transparent;
  color: steelblue;

  &:focus {
    outline: none;
  }
}