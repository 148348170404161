@value  v-fog, v-graphite, v-sky from './global_ui/colors.css';

.link {
  color: v-graphite;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 7px 15px;
}

.link:hover,
.link:focus {
  text-decoration: none;
}

.list {
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 5px 0;
  text-align: left;
  width: 230px;
}

.listItem {
  color: v-graphite;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  word-wrap: break-word;
}

.listItem:hover,
.listItem:focus {
  background-color: v-fog;
}
