.card {
  display: flex;
  padding: 15px;
  width: 597px;
  max-width: 100%;
  background: var(--white);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 15px;
}

.description {
  font-size: 15px;
  margin-top: 10px;
}

.moreInfo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}