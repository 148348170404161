@value v-asphalt, v-hackster, v-graphite, v-sky, v-white from '../../../../styles/global_ui/colors.css';

/**
 * List
*/
.row {
  border-top: var(--standardBorder);
  background-color: v-white;
  display: flex;
}

.row:hover {
  .dragHandle {
    color: v-graphite;
  }
}

.rowHighlight {
  composes: row;
  background-color: var(--skyToLake);
}

/**
 * Card
*/
.buttonCntr {
  display: flex;
  flex-wrap: nowrap;
}

.cell {
  align-items: center;
  border-left: var(--standardBorder);
  color: v-graphite;
  display: flex;
  flex-wrap: nowrap;
  font-size: 15px;
  font-weight: 500;
  height: auto;
  overflow: auto;
  padding: 10px 15px;

  &:nth-child(2) {
    border-left: none;
  }
}

.cell60 {
  flex-basis: 60%;
}

.cell30 {
  flex-basis: 30%;
}

.cell20 {
  flex-basis: 20%;
}

.cell10 {
  flex-basis: 15%;
}

.dragHandle {
  align-items: center;
  color: v-asphalt;
  display: flex;
  height: auto;
  flex-basis: 5%;
  justify-content: center;

  &:hover {
    border-left: 2px solid v-hackster;
    cursor: pointer;
    .dragHandleIcon {
      color: v-hackster;
    }
  }
}

/**
 * Promoted Content Card
 */
.contentCardTitle {
  composes: cell;
  composes: cell60;
  justify-content: space-between;
}

.noContentMsg {
  composes: cell;
  border-left: none;
  justify-content: center;
  padding: 15px;
  width: 100%;
}

/**
 * NameCell
 */

.nameCell {
  composes: cell;
  composes: cell30;
  justify-content: space-between;
}

.saveBtn {
  margin-right: 5px;
  padding: 2px 6px;
}

.metaMsg {
  color: red;
  font-size: 10px;
}

.tag {
  background-color: var(--hacksterDisabledToJet);
  border-radius: 4px;
  color: var(--lakeToWhite);
  font-size: 14px;
  font-weight: bold;
  max-height: 100%;
  overflow: auto;
  padding: 5px 10px;
}

.title {
  color: v-graphite;
  display: flex;
  flex-direction: column;
}

.titleInput {
  height: 30px;
  margin-right: 10px;
  padding: 10px;
}

/**
 * Loader
 */
.ghostCell {
  align-items: center;
  border-left: var(--standardBorder);
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
  padding: 0 15px;

  &:nth-child(2) {
    border-left: 0;
  }
}

.ghostDragBox {
  display: flex;
  flex-basis: 5%;
}

.greyNothing {
  composes: loader from '../../../../styles/animation.css';
  border-radius: 4px;
  border: none;
  height: 30px;
  width: 100%;
}
