@value v-small from '../../../../styles/breakpoints.css';
@value v-fog, v-white from '../../../../styles/global_ui/colors.css';

.container {
  background-color: v-white;
}

@media v-small {
  .navList {
    border-top: 1px solid v-fog;
  }
}
