@value v-med-large  from '../../styles/breakpoints.css';



@media v-med-large {
  .header {
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    h2 {
      font-size: 16px;
      line-height: 22px;
    }
  }
}