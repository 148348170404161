@value v-med, v-med-large from '../../../styles/breakpoints.css';

@media v-med-large  {
  .imageWrapper {
    flex-wrap: wrap;
    a {
      width: 100%;
    }
    a > div {
      height: auto !important;
    }
  }
}