@value v-med, v-small from '../../../styles/breakpoints.css';

.arrow {
  flex: 1;

  polyline {
    stroke: var(--staticWhite);
  }
}

.arrowUp {
  transform: scaleY(-1);
}

.buttonWrapper {
  align-self: flex-end;
  margin-top: 5px;
}

.cog {
  flex: 1;
  margin: 0;
}

.label {
  display: flex;
  align-items: center;
}

.labelText {
  margin: 0 5px;
}

.list {
  font-size: 14px;
  margin-top: 5px;
  padding: 0;
  width: 150px;

  li {
    padding: 5px 10px;
  }

  li a:hover {
    text-decoration: none;
  }

  li a:focus {
    text-decoration: none;
  }
}

@media v-small,
v-med {
  .buttonWrapper {
    margin-left: 5px;
    margin-top: 0;
  }
}