@value v-small from '../../../../styles/breakpoints.css';
@value v-success from '../../../../styles/global_ui/colors.css';

.adMenuWrapper {
  align-self: start;
  padding-top: 5px;
  margin-left: 5px;
}

.campaignCard {
  justify-content: flex-start;
  max-width: 33%;
}

.campaignStatCard {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.campaignStatCard + .campaignStatCard {
  margin-top: 15px;
}

.campaignStatsContainer {
  margin: 0 15px;
  width: auto;
}

.statusBar {
  background-color: v-success;
  display: flex;
  height: 100%;
  justify-content: left;
}

.statusBarContainer {
  width: 100%;
  height: 20px;
}

@media v-small {
  .campaignCard {
    margin-bottom: 15px;
    min-width: 100%;
    width: 100%;
  }

  .campaignCardContainer { flex-direction: column; }

  .campaignStatsContainer {
    align-self: center;
    min-width: 100%;
    width: 100%;
  }
}
