
.root {
  align-items: center;
  display: flex;
  font-size: 15px;
  padding: 8px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.message {
  margin: 0 auto;
}

.link {
  font-weight: bold;
}

.link-success {
  color: var(--success);
  &:hover {
    color: var(--success);
  }
}

.link-error {
  color: var(--error);
  &:hover {
    color: var(--error);
  }
}

.dismiss {
  background-color: transparent;
  border: none;
}

.dismiss:focus {
  outline: none;
}

.success {
  composes: root;
  background-color: var(--successBG);
  color: var(--successToWhite);
}

.error {
  composes: root;
  background-color: var(--errorBG);
  color: var(--errorToWhite);
}