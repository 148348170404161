@value v-pebble from '../../../styles/global_ui/colors.css';
@value v-med from '../../../styles/breakpoints.css';

.container {
  display: flex;
  border-bottom: var(--standardBorder);
  padding: 30px 0px;
}

.container:first-child {
  padding-top: 0;
}

.singleCardContainer {
  composes: container;
  border-bottom: none;
}

.imageContainer {
  display: flex;
  align-self: center;
  margin-right: 30px;
}

.image {
  min-width: 255px;
  cursor: pointer;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0;
}

.links {
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 15px;
}

.linkDivider {
  color: v-pebble;
  margin: 0 8px;
}

.description {
  color: v-pebble;
  min-width: 200px;
  word-break: break-word;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-flow: row wrap;
}

/**
* Dummy Card
*/

.dummyContainer {
  display: flex;
}

.dummyBlock {
  composes: loader from '../../../styles/animation.css';
  border: none;
}

.dummyImage {
  composes: dummyBlock;
  width: 255px;
  padding-bottom: 65%;
}

.dummyHeader {
  composes: dummyBlock;
  display: inline-block;
  height: 40px;
  width: 160px;
}

.dummyDescription {
  composes: description;
  composes: dummyBlock;
  margin-bottom: 4px;
  height: 64px;
}

.dummyButtons {
  composes: dummyBlock;
  width: 260px;
  height: 36px;
}

@media v-med {
  .container {
    flex-direction: column;
  }

  .dummyContainer {
    flex-direction: column;
  }

  .imageContainer {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
