.itemsContainer {
  display: flex;
  flex: 1; /* IE11 */
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 15px 0;
}

.loader {
  align-items: center;
  display: flex;
  font-size: 32px;
  padding: 30px;
}

.scrollButton {
  align-items: center;
  background: color-mix(in srgb, var(--fog) 80%, transparent);
  border: none;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 200ms ease-out;
  width: 32px;
  z-index: 1;
}

.scrollButton:focus {
  outline: none;
}

.scrollContainer {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* firefox windows - hide scrollbar */
  -ms-overflow-style: none;
  width: 100%;
}

.scrollLeft {
  composes: scrollButton;
  left: 10px;
}

.scrollLeft:hover {
  transform: translate(-3px, -50%);
}

.scrollRight {
  composes: scrollButton;
  right: 10px;
}

.scrollRight:hover {
  transform: translate(3px, -50%);
}

.wrapper {
  position: relative;
}
