.enter {
  opacity: 0.01;
  max-height: 0px;
}
.enter.enterActive {
  opacity: 1;
  max-height: 150px;
  transition: all 350ms ease-in-out;
}
.exit {
  opacity: 1;
  max-height: 150px;
  transform: translateX(0);
}
.exit.exitActive {
  opacity: 0.01;
  max-height: 0px;
  transition: all 350ms ease-in-out;
  transform: translateX(-100%);
}
