@value v-asphaltHover from '../../../styles/global_ui/colors.css';
@value v-med from '../../../styles/breakpoints.css';

.root {
  display: flex;
  margin-bottom: 45px;
  min-height: 100px;
  width: 100%;
}

.adFAQLink {
  font-size: 8px;
  line-height: 8px;
}

.adFAQLink,
.adFAQLink:hover,
.adFAQLink:focus {
  color: v-asphaltHover;
}

/* TODO: why? */
.anchor {
  /*display: flex;
  height: 100%;*/
}

.disclaimerWrapper {
  align-items: flex-end;
  display: flex;
}

.image {
  min-width: 125px;
}

.text {
  margin-top: -4px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-width: 145px;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  height: 100%;
}

@media v-med {
  .root {
    justify-content: center;
    margin-bottom: 0;
  }
}
