.closeButton:active {
  box-shadow: none;
}

.nextButton {
  font-size: 12px;
  line-height: 14px;
}

.popupContainer {
  max-width: 289px;
}

