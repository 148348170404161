@value v-charcoal, v-white from '../../../styles/global_ui/colors.css';

.number {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.thumb {
  background-color: v-white;
  height: 30px;
  position: relative;
  width: 30px;
  color: v-charcoal;
}

.thumb + .thumb {
  margin-left: 10px;
}

.numberViewed {
  composes: number;
  color: v-white;
  background-color: rgba(0,0,0,0.3);
}
