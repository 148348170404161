.cardBody {
  align-items: flex-start;
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
}

.statusPill {
  border-radius: 14px;
  height: 28px;
  padding: 0 15px;
}
