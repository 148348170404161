@value v-small from '../../../../styles/breakpoints.css';
@value v-error, v-staticFog, v-hackster, v-white from '../../../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../../../styles/global_ui/util.css';

.dismissBtn {
  &:hover {
    color: v-error;
    cursor: pointer;
  }
}

.dismissBtnWrapper {
  align-items: center;
  display: flex;
  flex-basis: 5%;
  height: 100%;
  padding-right: 30px;
}

.dragHandleWrapper {
  align-items: center;
  display: flex;
  flex-basis: 5%;
  height: 100%;
  padding: 0 30px;

  &:hover {
    color: v-hackster;
    cursor: pointer;
  }
}

.eventCard {
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: space-between;
  width: 100%;
}

.eventCardSponsorsFixedHeight {
  max-height: 96px;
  overflow-y: auto;
  padding: 10px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.iconCircle {
  align-items: center;
  border: var(--standardBorder);
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 10px;
  width: 30px;
}

.listItem {
  align-items: center;
  border-bottom: var(--standardBorder);
  display: flex;
  justify-content: space-between;
  min-height: 85px;
  padding: 15px 0;
}

.listItemAuthorCell {
  flex-basis: 10%;
  white-space: pre-line;
}

.listItemDateCell {
  flex-basis: 25%;
}

.listItemMobileAuthorStatus {
  display: none;
}

.listItemSelectable:hover {
  border-bottom: 1px solid v-hackster;
}

.listItemCoverImage {
  border-radius: v-standardBorderRadius;
  height: 64px;
  width: 64px;
}

.listItemCoverImageWrapper {
  display: block;
  position: relative;
  margin-right: 30px;
}

.listItemTitleCell {
  align-items: center;
  display: flex;
  flex-basis: 50%;
  word-break: break-word;
}

.selectionContainer {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selectionBox {
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: center;
  padding: 0 30px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.selectionBoxEmpty {
  align-items: center;
  display: flex;
  height: 96px;
  justify-content: center;
  width: 100%;
}

.sortableElement + .sortableElement {
  border-top: var(--standardBorder);
}

.sortableGhost {
  background-color: v-white;
  border-radius: 10px;
  box-shadow: 3px 3px 5px 5px v-staticFog;
}

@media v-small {
  .eventCard {
    height: 100%;
    padding: 15px 0;
  }

  .listItemCoverImage {
    height: 60px;
    width: 60px;
  }

  .listItem {
    align-items: flex-start;
    min-height: 120px;
  }

  .listItemCoverImageWrapper { margin-right: 15px; }
  .listItemAuthorCell { display: none; }
  .listItemDateCell { display: none; }

  .listItemData {
    display: flex;
    flex-direction: column;
  }

  .listItemMobileAuthorStatus { display: block; }

  .listItemTitleCell {
    align-items: center;
    flex-basis: 90%;
    height: 100%;
  }
}