@value v-small from '../../../styles/breakpoints.css';

.divider {
  width: 75%;
}

@media v-small {
  .divider {
    width: 100%;
  }
}
