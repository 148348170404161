.enter {
  opacity: 0.01;
  .translateY {
    transform: translateY(100px);
  }
}
.enter.enterActive {
  opacity: 1;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  .translateY {
    transform: translateY(0);
    transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
}
.exit {
  opacity: 1;
  .translateY {
    transform: translateY(0);
  }
}
.leave.exitActive {
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  opacity: 0.01;
}