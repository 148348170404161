@value v-med from '../../../styles/breakpoints.css';
@value v-asphalt, v-pebble, v-hackster, v-white from '../../../styles/global_ui/colors.css';

.container {
  display: flex;
  width: 100%;
  max-width: 400px;
}

.containerSimple {
  align-items: center;
  display: flex;
  flex: 2;
  height: 60px;
  min-width: 250px;
  width: 100%;
}

.dismiss {
  display: none;
  cursor: pointer;

  &:hover {
    color: v-hackster;
  }
}

.dismissWithinBorderWrapper {
  display: flex;
  padding-right: 15px;
}

.dismissWithinBorder {
  cursor: pointer;
  display: inline-block;
  align-self: center;

  &:hover {
    color: v-hackster;
  }
}

.hideDismiss {
  display: none!important;
}

.searchInputBase {
  padding: 10px 10px 10px 15px;
  width: 100%;
  border: var(--standardBorder);
  border-radius: 4px;
  font-size: 15px;
}

.searchInputBase:focus {
  outline: 0;
  border-color: v-hackster;
  box-shadow: none;
}

.input {
  composes: searchInputBase;
  border: none;
  width: 100%;
}

.input::placeholder {
  color: v-asphalt;
}

.inputFocused {
  border-color: v-hackster!important;
}

.inputContainer {
  border: var(--standardBorder);
  border-radius: 4px;
  display: flex;
  height: 45px;
  position: relative;
  width: 370px;
}

.listOpen {
  border-radius: 4px 4px 0 0;
}

.searchIcon {
  fill: v-pebble;
  height: 16px!important;
  width: 16px!important;
  cursor: pointer;
}

.searchIconWrapper {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  padding-right: 15px;

  svg:hover {
    fill: v-hackster;
  }
}

@media v-med {
  .container {
    align-items: flex-start;
    background-color: v-white;
    display: none;
    left: 0;
    max-width: 100%;
    padding: 10px;
    position: absolute;
    top: 72px;
    z-index: 12;
  }

  .dismiss {
    display: inline-block;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .inputContainer {
    width: 100%;
  }

  .onSearchPath {
    display: flex;
  }

  .viewPortHeightMax {
    height: 100vh;
  }
}
