@value v-small from '../../../../../../styles/breakpoints.css';
@value v-asphalt from '../../../../../../styles/global_ui/colors.css';
@value v-standardBorder, v-standardBorderRadius from '../../../../../../styles/global_ui/util.css';

.client {
  margin: auto;
  max-width: 50%;
}

.divImageBg {
  background: no-repeat center center;
  background-size: cover;
}

.previewBase {
  align-items: center;
  background-color: v-asphalt;
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
  display: flex;
  justify-content: center;
}

.previewContainer { display: flex; }

.previewMobileContainer { margin-left: 30px; }

@media v-small {
  .previewContainer { flex-direction: column; }
  .previewMobileContainer {
    margin-left: 0px;
    margin-top: 15px;
  }
}

/**
 * ContentPageAd
 */
.cpa {
  border-radius: v-standardBorderRadius;
  height: 202px;
  width: 270px;
}

.cpaPreview {
  composes: previewBase;
  height: 202px;
  width: 270px;
}

/**
 * HomePageBannerAd
 */
.hpba {
  border-radius: v-standardBorderRadius;
  width: 100%;
}

.hpbaPreview {
  composes: previewBase;
  height: 144px;
  width: 1170px;
}

.hpbaMobile {
  border-radius: v-standardBorderRadius;
  max-width: 345px;
  width: 100%;
}

.hpbaMobilePreview {
  composes: previewBase;
  height: 42px;
  width: 345px;
}

@media v-small {
  .hpba { display: none; }
  .hpbaPreview { display: none }
}

/**
 * NewsGalleryAd
 */
.newsGalPreview {
  height: 280px;
  max-width: 270px;
  width: 100%;
}

.newsGalMobilePreview {
  height: 120px;
  max-width: 370px;
  width: 100%;
}

.newsGalImage {
  composes: divImageBg;
  border-radius: v-standardBorderRadius;
  height: 152px;
  max-width: 270px;
  width: 100%;
}

.newsGalImagePreview {
  composes: previewBase;
  height: 152px;
  width: 270px;
}

.newsGalMobileImage {
  composes: divImageBg;
  border-radius: v-standardBorderRadius;
  min-height: 120px;
  min-width: 120px;
}

.newsGalMobileImagePreview {
  composes: previewBase;
  text-align: center;
  height: 120px;
  width: 120px;
}

.newsGalText { width: 270px; }
.newsGalMobileText { width: 235px; }

@media v-small {
  .newsGalMobileText {
    max-width: 235px;
    width: 100%;
  }
}

/**
 * NewsPageHeroAd
 */
.nphaDesktopContainer {
  max-width: 270px;
  width: 100%;
}

.nphaPreview {
  composes: previewBase;
  height: 500px;
  width: 270px;
}

.nphaMobileContainer {
  max-width: 345px;
  width: 100%;
}

.nphaMobilePreview {
  composes: previewBase;
  height: 86px;
}
