@value v-med from '../../../styles/breakpoints.css';
@value v-asphalt, v-hacksterHover, v-white from '../../../styles/global_ui/colors.css';

.arrow {
  fill: v-white;
  transform: translateY(-1px);
  z-index: 1000;
}

.arrow > path {
  stroke: var(--borderColor);
  stroke-linejoin: round;
  stroke-width: 1px;
}

.arrow > rect {
  fill: v-white;
}

.secondaryArrow {
  fill: v-hacksterHover;
  transform: translateY(-1px);
  z-index: 1000;
}

.secondaryArrow > path {
  stroke: v-hacksterHover;
  stroke-linejoin: round;
  stroke-width: 1px;
}

.secondaryArrow > rect {
  fill: v-hacksterHover;
}

.btn {
  all: unset;
  display: flex;
  align-items: center;
}

.content {
  max-width: 350px;
  animation: ease 200ms;
  z-index: 12;
}

.secondary {
  background-color: v-hacksterHover;
  border-radius: 0;
  color: #fafafa;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 8px;
  position: relative;
  white-space: nowrap;
}

@media v-med {
  .content {
    max-width: 290px;
  }
}

/* Animations */
.content[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.content[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.content[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.content[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
