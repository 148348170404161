.viewLink {
  composes: bodyM from '../../../styles/global_ui/typography.css';
  composes: link from '../../../styles/global_ui/typography.css';
  display: block;
  margin-top: 15px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 24px;
}

.viewAllLink {
  composes: bodyS from '../../../styles/global_ui/typography.css';
  composes: link from '../../../styles/global_ui/typography.css';
  display: block;
  font-weight: bold;
  margin-top: auto;
  padding-top: 30px;
}
