@value v-hackster, v-white from '../../../../../../styles/global_ui/colors.css';

.root {
  color: v-hackster;
  position: relative;
}

.dropdownContainer {
  background-color: v-white;
  border: 1px solid beige;
  left: 0;
  position: absolute;
  max-width: 500px;
  min-width: 250px;
  z-index: 100; /* Important for any dropdowns. */
}

.userAvatar {
  border-radius: 50%;
  height: 30px;
  margin-right: 10px;
  width: 30px;
}

.userCard {
  display: flex;
}

.userNames {
  width: 100%;
}
