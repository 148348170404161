@value v-small from '../../../styles/breakpoints.css';
@value v-error, v-fog, v-white from '../../../styles/global_ui/colors.css';
@value v-standardBorder, v-standardBorderRadius from '../../../styles/global_ui/util.css';

.adDummy {
  border-radius: 6px;
  height: 400px;
}

.adDummy2 {
  height: 10px;
  align-self: flex-end;
  width: 85px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.deleteBtn {
  align-items: center;
  background-color: v-white;
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
  display: none;
  margin-right: 10px;
  margin-top: 10px;
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;

  &:hover { color: v-error; }
}

.iconWrapper {
  height: 27px;
  width: 27px;
}

.selectorListBody {
  display: flex;
  justify-content: center;
}

/* Featured */
.featuredCardContainer {
  background-color: v-white;
  border-bottom: var(--standardBorder);
  position: relative;
  padding: 30px 0;

  &:first-child { padding-top: 0; }
  &:last-child { border-bottom: none; }
  &:hover {
    cursor: pointer;
    .deleteBtn { display: flex; }
  }
}

.featuredEmptyCard {
  align-items: center;
  background-color: v-fog;
  border: 1px dashed var(--borderColor);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;

  &:hover { cursor: pointer; }
}

.featuredEmptyCardHero {
  composes: featuredEmptyCard;
  min-height: 418px;
}

.featuredEmptyCardContainer {
  border-bottom: var(--standardBorder);
  padding: 30px 0;
}

.featuredEmptyCardContainer:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.featuredEmptyCardContainerHero {
  composes: featuredEmptyCardContainer;
  padding-top: 0;
}

/* Sponsored */
.sponsoredCardContainer {
  &:hover {
    cursor: pointer;
    .deleteBtn { display: flex; }
  }
}

.sponsoredEmptyCard {
  composes: featuredEmptyCard;
  padding: 15px;
  text-align: center;
  width: 100%;
}

/* IMPORTANT: This class must come last. */
.currentDraggingItem {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
  padding: 15px;
  z-index: 10;
}

@media v-small {
  .adDummy { height: 100px }
  .adDummy2 { align-self: center; }
  .container { margin-top: 30px; }
  .deleteBtn { display: flex; }
  .selectorListBody { margin-top: 30px; }

  /* Featured */
  .featuredEmptyCardHero { min-height: 355px; }
}
