@value v-med, v-small from '../breakpoints.css';
@value v-fog, v-white from './colors.css';
@value v-standardBorder, v-largeBorderRadius from './util.css';

.container {
  max-width: 570px;
  min-width: 570px;
  width: 100%;
}

.container675 {
  max-width: 675px;
  min-width: 675px;
  width: 100%;
}

.nestedFogContainer {
  background-color: v-fog;
  border-radius: 4px;
  height: 100%; /* To accept the last inputs margin-bottom */
  margin-bottom: 30px;
  padding: 30px;
  padding-bottom: 0; /* Inputs will already have the 30px */
}

.panel {
  background: v-white;
  border: v-standardBorder;
  border-radius: v-largeBorderRadius;
  margin-bottom: 45px;
}

.panelHeader {
  padding: 15px 30px;
}

.panelInner {
  padding: 45px 30px 15px 30px;
}

@media v-med {
  .container {
    max-width: 345px;
    min-width: 345px;
  }

  .container675 {
    max-width: 345px;
    min-width: 345px;
  }
}

@media v-small {
  .container {
    min-width: 100%;
  }

  .container675 {
    min-width: 100%;
  }

  .panel {
    margin-bottom: 30px;
  }

  .panelHeader {
    padding: 15px;
  }

  .panelInner {
    margin-bottom: 30px;
    padding: 30px 15px 0 15px;
  }
}
