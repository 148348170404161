.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  min-height: 235px;
  border: var(--standardBorder);
  text-align: center;
}

.thumb {
  composes: card;
  padding: 30px 20px;
}

.subTitle {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.thumbImageContainer {
  margin-bottom: 15px;
}

.thumbContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventCard {
  padding: 15px;
  border: var(--standardBorder);
  min-width: 345px;
}

.eventDate {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;

  div {
    font-size: 36px;
    line-height: 28px;
  }
}
