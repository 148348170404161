@value v-asphalt, v-hackster, v-pebble, v-white from '../../../../styles/global_ui/colors.css';

.panel {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 4px;
  padding: 30px;
}

.addProjectButton {
  composes: panel;
  flex: 1 1 50%;
}

.addProjectButton + .addProjectButton {
  margin-left: 15px;
}

/* weird nesting necessary because transition container (.panelRow) can't have margins/padding */
.panelRow + .panelRow {
  .panel {
    margin-top: 15px;
  }
}

.panelRow:last-child {
  .panel {
    margin-bottom: 30px;
  }
}

.rowContainer {
  margin-bottom: 30px;
}

.rowHeader {
  border-bottom: 2px solid var(--borderColor);
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.registrationRow {
  display: flex;
  justify-content: space-between;
}

.selectInput {
  overflow-x: hidden;
}

.statusDummy {
  composes: loader from '../../../../styles/animation.css';
  height: 44px;
  width: 100%;
}

/**
 * Timeline Styles
 */
.timelineContainer {
  padding-left: 30px;
  position: relative;
}

.baseLine,
.progressLine {
  background-color: v-asphalt;
  content: '';
  left: 0;
  position: absolute;
  width: 2px;
}

.baseLine {
  top: 25px;
}

.progressLine {
  background-color: v-hackster;
}

.event,
.pastEvent {
  margin-bottom: 30px;
}

.event::before,
.pastEvent::before {
  background-color: v-asphalt;
  content: '';
  height: 2px;
  left: 0px;
  margin-top: 23px;
  position: absolute;
  width: 15px;
}

.pastEvent {
  h3,
  p {
    color: v-pebble, v-white;
  }
}

.pastEvent::before {
  background-color: v-hackster;
}
