.arrow {
  bottom: 0;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 45px;
  z-index: 11;
}

.arrowLeft {
  composes: arrow;
  background-image: linear-gradient(90deg, var(--white) 50%, transparent 100%);
  left: 0;
}

.arrowRight {
  composes: arrow;
  justify-content: flex-end;
  background-image: linear-gradient(270deg, var(--white) 50%, transparent 100%);
  right: 0;
}

.scrollContainer {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* firefox windows - hide scrollbar */
  width: 100%;
  -ms-overflow-style: none;
}

.wrapper {
  position: relative;
  width: 100%;
}