@value v-fog, v-pebble, v-graphite from '../../styles/global_ui/colors.css';
@value v-med-large from '../../styles/breakpoints.css';

:global {
  .story-editor {
    .draftster-header-three {
      color: var(--graphite);
      margin: 0;
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 1.2em;
      font-weight: bold;
    }

    .draftster-paragraph {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.autosaveLabel {
  cursor: pointer;
}

.autosaveInput {
  cursor: pointer;
  margin-right: 8px !important;
}

.helpBox {
  background-color: var(--white);
  border: var(--standardBorder);
  border-radius: 4px;
  color: v-graphite;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px 35px;
}

.fixed {
  position: fixed;
  top: 10px;
  width: 255px;
}

.saving {
  color: v-pebble;
}

.statusWrapper {
  margin-bottom: 5px;
}

.tips {
  background-color: v-fog;
  border-radius: 4px;
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 10px;
}

@media v-med-large {
  .fixed {
    display: none;
  }
}