@value v-small, v-med from '../../../../styles/breakpoints.css';
@value v-asphalt, v-white from '../../../../styles/global_ui/colors.css';

/**
 * Components sharing file ['home_edit_page/StickyActionsBar']
 */

/* StickyActionsBar */
.actionsBarRoot {
  height: 65px;
  width: 100%;
}

.actionsBar {
  composes: actionsBarRoot;
  background-color: v-white;
  bottom: 0;
  position: absolute;
  z-index: 10;
}

.actionsBarInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 45px;
}

.actionsBreadcrumbRoot {
  margin-bottom: 0;
}

/* Publish Dialog */
.box {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 30px;
}

.boxBorder {
  border-left: var(--standardBorder);
}

.dialogBody {
  display: flex;
}

.dialogWrapper {
  border-radius: 4px;
  padding: 30px;
  max-width: 770px;
}

/* Top menu bar */
.topMenuBarRoot {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50px;
  padding: 15px;
}

@media v-small {
  .actionsBarInner { padding: 0 15px; }
  .actionsBreadcrumbRoot { flex: 1; }
  .actionsFormViewButtons {
    flex: 3;
    justify-content: flex-end;
  }
  .topMenuBarStatus { max-width: 45%; }
}

@media v-med {
  .box:nth-of-type(1) { padding: 0 0 30px 0; }
  .box:nth-of-type(2) { padding: 30px 0 0 0; }

  .boxBorder {
    border-left: none;
    border-top: var(--standardBorder);
  }
  .dialogBody { flex-direction: column; }
}
