.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.body {
  align-items: center;
  background-color: var(--white);
  border: var(--secondaryBorder);
  box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  width: 600px;
  z-index: 202;
}

.container {
  align-items: center;
  bottom: 0px;
  display: flex;
  left: 0px;
  justify-content: space-around;
  padding: 10px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 201;
}

.innerBody {
  padding: 20px;
  width: 100%;
}

.input {
  composes: input from "./formElements.css";
}

.mask {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  will-change: opacity;
  transform: translateZ(0px);
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 200;
  background-color: transparent;
  overflow: auto;
}