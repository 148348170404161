@value v-charcoal, v-error from '../../../../styles/global_ui/colors.css';

.checkbox {
  align-items: center;
  border: var(--standardBorder);
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  height: 23px;
  justify-content: center;
  min-width: 23px;
  width: 23px;

  &:focus {
    border: 1px solid v-charcoal;
    outline: none;
  }
}

.checkboxTop {
  align-self: flex-start;
  margin-top: 3px;
}

.checkboxWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;

  label { cursor: pointer; }
}

.checkboxWrapper:hover {
  .checkbox { border: 1px solid v-charcoal; }
}

.checkboxWrapper.disabled {
  cursor: default;
  opacity: 50%;
  label { cursor: default; }
}

.checkboxWrapper.disabled:hover {
  .checkbox { border: var(--standardBorder); }
}

.error {
  border-color: v-error;
}

.disabled {
}

.input {
  display: none;
}

.maxWidth { /* Limit width when theres no label.  Its for hover state. */
  max-width: 23px;
}
