@value v-small, v-med from '../../../styles/breakpoints.css';

.wrapper {
  max-width: 100%;
}

@media v-med {
  .wrapper {
    width: 90%;
    max-width: 586px;
  }
}

@media v-small {
  .wrapper {
    flex: 1;
    margin-bottom: 0;
    margin-top: 0;
    padding: 60px 15px;
    width: 100%;
  }
}

@media (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    .wrapper {
      border: var(--standardBorder);
    }
  }
}

[data-theme="dark"] {
  .wrapper {
    border: var(--standardBorder);
  }
}

