@value v-med-large from '../../../../styles/breakpoints.css';

.root {
  align-items: center;
  background-color: var(--white);
  border-top: 1px solid var(--transparentToAsphalt);
  bottom: 0;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-between;
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 3147483647; /* Was 200 but Intercom is blocking the X. Intercom is 2147483001 */
}

.bodyWrapper {
  flex: 1;
  padding-left: 20px; /* Width of the close button to center the body */
}

/** SocialFooterView **/
.socialFooterBodyWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.socialList {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--charcoal);
  }
}

.closeBtn {
  display: flex;
  color: var(--graphite)
}

@media v-med-large {
  .bodyAndSocialIcons {
    flex-direction: column;
  }

  .closeBtn {
    height: 100%;
  }

  .socialFooterBody {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    text-align: center;
  }

  .socialFooterBodyWrapper {
    flex-direction: column;
  }
}