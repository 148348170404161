@value v-hackster, v-white from '../../../styles/global_ui/colors.css';
@value v-small from '../../../styles/breakpoints.css';

.dropdownIconOpen {
  margin-right: 10px;
  transform: scaleY(-1);
}

.dropdownIconClosed {
  margin-right: 10px;
}

.filter {
  margin-right: 10px;
  margin-top: 15px;
}

.filterGroup {
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  transition: all 300ms ease-in-out;
}

.filtersButton {
  display: flex;
  flex: 1;
  font-weight: bold;
  justify-content: center;
  padding: 7px 0 6px;
}

.filtersToggle {
  align-items: center;
  border: var(--standardBorder);
  border-radius: 1px;
  display: none;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.filtersToggleCount {
  align-items: center;
  background-color: v-hackster;
  border-radius: 50%;
  color: v-white;
  display: flex;
  font-size: 13px;
  height: 20px;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
}

.marginLeftOnOne {
  margin-left: -1px;
}

.paginatorRoot {
  margin-top: 30px;
}

.wrapper {
  margin-bottom: 30px;
  margin-top: -15px;  /* To offset the margin-top: 15px; on .filter */
}

@media v-small {
  .filter {
    display: block;
    margin: 10px 0 0 0;
    width: 100%;
  }

  .filter button {
    display: block;
    width: 100%;
  }

  .filtersToggle {
    display: flex;
  }

  .filterGroupClosed {
    max-height: 0;
    overflow-y: hidden;
  }

  .wrapper {
    margin-bottom: 30px;
    margin-top: 9px;
  }
}
