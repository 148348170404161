.resetDraftGlobals {
  :global {
    .DraftEditor-editorContainer {
      z-index: unset; /* Important for any absolute positioned elements over contenteditable blocks */
    }
    .public-DraftEditor-content:first-child {
      padding-top: 0!important;
    }
    .public-DraftEditor-content:last-child {
      padding-bottom: 0!important;
    }
    .DraftEditor-root {
      width: 100%;
    }
  }
}

.root {
  font-size: 16px;
  word-break: break-word;
}

.column {
  display: block;
}

.row {
  display: flex;
  justify-content: space-between;
}

/**
 * Toolbar
**/
.toolbar {
  align-items: center;
  display: flex;
}

