.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dialog {
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.dismiss {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 35px;
  line-height: 1;
  position: absolute;
  outline: 0;
  opacity: 0.2;
  padding: 0;
  right: 8px;
  top: 0;
  -webkit-appearance: none;
}

.overlay {
  margin: auto;
}

.title {
  line-height: 1;
  margin: 0 0 20px;
  text-align: center;
}

.wrapper {
  background-color: var(--white);
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  min-width: 600px;
  padding: 50px;
  position: relative;
  z-index: 1050;
}