/* TODO: remove? */
@value v-asphalt, v-fog, v-hackster, v-white, v-graphite from '../../../styles/global_ui/colors.css';

.container {
  align-items: center;
  display: flex;
  height: 60px;  /* Forced to set height here due to inheritance */
}

.inputContainer {
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 14px;
  font-weight: bold;
}

.input {
  height: 34px;
  padding: 6px 12px;
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 0px 4px 4px 0px;
}

.input:focus {
  border-color: v-hackster;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
}

.input::placeholder {
  color: v-asphalt;
}

.searchIconWrapper {
  height: 34px;
  padding: 8px 12px;
  border: var(--standardBorder);
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  background-color: transparent;
  color: v-graphite;
  cursor: pointer;
}
