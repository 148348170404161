.root {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 500px;
  z-index: 100;
}

.popover {
  border-radius: 2px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
}

.arrow {
  border-color: var(--hackster) transparent transparent transparent;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  height: 0;
  width: 0;
}

.defaultMenu {
  align-items: center;
  background-color: var(--hackster);
  border-radius: 2px;
  display: flex;
  padding: 8px;
  max-height: 46px;

  a {
    padding: 5px;
    border-right: 1px solid var(--white);
    color: var(--staticWhite);
    padding-left: 8px;
    padding-right: 8px;

    &:hover {
      color: var(--fog);
    }
  }

  a:last-child {
    border-right: none;
  }
}

.input {
  composes: input from "../../styles/formElements.css";
}