@value v-med from '../../../../styles/breakpoints.css';

.cta,
.ctaLockedProject {
  flex: 0 1 506px;
  margin-right: 100px;
}

.ctaLockedProject {
  text-align: center;
}

.ctaImg {
  margin-top: 60px;
}

.ctaHeader {
  composes: h1 from '../../../../styles/global_ui/typography.css';
  margin-bottom: 15px;
}

.ctaSubHeader {
  composes: h2 from '../../../../styles/global_ui/typography.css';
  margin-bottom: 15px;
}

.promo {
  composes: cta;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.promoImg {
  text-align: center;
}

@media v-med {
  .cta,
  .ctaLockedProject {
    margin-right: 0;
    flex: 1 0 auto;
    text-align: center;
    margin-bottom: 30px
  }

  .ctaHeader {
    /* h2 style. can't compose because media query */
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -0.3;
  }

  .ctaImg {
    display: none
  }

  .promoImg {
    padding: 0 30px;
  }
}
