@value v-fog, v-graphite, v-white from '../../../../../styles/global_ui/colors.css';

.container {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 4px;
  color: v-graphite;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 34px;
  width: 100%;

  &:hover {
    border: var(--standardBorder);
  }
}

.input {
  border: none;
  display: inline-flex;
  flex: 1;
  font-size: 14px;
  height: auto;
  margin-top: 5px;
  padding: 0;
  width: auto;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding-left: 10px;
  padding-bottom: 5px;
  margin-right: 15px;
  width: 100%;
}

.menuOpened { /* Container focused */
  border: 1px solid var(--hackster);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);

  &:hover {
    border: 1px solid var(--hackster);
    border-bottom: none;
  }
}

/* Multi Selected */
.multiOpt {
  background-color: v-fog;
  border-radius: 2px;
  display: inline-flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 5px;
}