.tWrapper {
  border-radius: 2px;
  box-sizing: border-box;
  color: var(--white);
  font-size: 10px;
  margin-top: 8px;
  opacity: 0.9;
  padding: 0px 8px;
  position: absolute;
}

.tooltip {
  background-color: var(--pebble);
  border-radius: 2px;
  height: auto;
  padding: 6px;
  position: relative;
  top: 20px;
  transition: width 0ms cubic-bezier(0.23, 1, 0.32, 1) 50ms, opacity 100ms linear 200ms;
}

.icon {
  font-size: 2rem;
  color: var(--staticWhite);
}