@value v-asphaltHover, v-hackster from '../../../../../styles/global_ui/colors.css';

.wrapper {
  padding: 21px 15px;
  width: 100%;
}

.barBase {
  height: 10px;
  width: 100%;
}

.bg {
  composes: barBase;
  background: v-asphaltHover;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.fill {
  composes: barBase;
  animation: loading 1.5s ease infinite;
  background: v-hackster;
  border-radius: 5px;
}

/* TODO: get a better animation from designers; */
@keyframes loading {
  from {transform: translateX(-50%) scaleX(0);}
  50% {transform: translateX(0) scaleX(0.5);}
  to {transform: translateX(70%) scaleX(0.2);}
}
