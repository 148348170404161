@value v-asphalt, v-graphite, v-white from '../../../../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../../../../styles/global_ui/util.css';

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}

.tag {
  font-size: 13px;
  padding: 4px 10px;
  margin: 5px 10px 5px 0;
  background: var(--hacksterDisabledToJet);
  color: var(--lakeToWhite);
  font-weight: bold;
  border-radius: v-standardBorderRadius;

  em {
    font-style: normal;
  }
}

.tagHighlight {
  composes: tag;
  color: v-white;
  background: v-graphite;
}
