@value v-small, v-small-min, v-med-min, v-med-large-min from '../breakpoints.css';

.grid {
  display: flex;
  flex-flow: row wrap;
  min-width: 100%; /* For IE11 compatibility */
}

.cell {
  /* This "cell" class works with the gutter classes below to enforce gutters when nested */
}

.guttersH10 {
  margin-left: -10px;
  .cell {
    padding-left: 10px;
  }
}

.guttersV10 {
  margin-top: -10px;
  .cell {
    padding-top: 10px;
  }
}

.guttersH15 {
  margin-left: -15px;
  .cell {
    padding-left: 15px;
  }
}

.guttersV15 {
  margin-top: -15px;
  .cell {
    padding-top: 15px;
  }
}

.guttersH30 {
  margin-left: -30px;
  .cell {
    padding-left: 30px;
  }
}

.guttersV30 {
  margin-top: -30px;
  .cell {
    padding-top: 30px;
  }
}

.guttersH60 {
  margin-left: -60px;
  .cell {
    padding-left: 60px;
  }
}

.guttersV60 {
  margin-top: -60px;
  .cell {
    padding-top: 60px;
  }
}

/*
 * Cell widths expressed in fractions e.g. cell_1_4 = 1/4 container width.
 * Add more width classes as needed
 * NOTE: the -ms-flex coupled with the min/max width is an IE hack
 * to make the grid cells behave. Discuss dropping this IE support
 */
.cell_1_6 {
  flex: 1 0 16.6667%;
  max-width: 16.6667%;
  min-width: 16.6667%;
}

.cell_1_4 {
  -ms-flex: 1 0 20%;
  flex: 1 0 25%;
  max-width: 25%;
  min-width: 25%;
}

.cell_1_3 {
  -ms-flex: 1 0 30%;
  flex: 1 0 33.3333%;
  max-width: 33.3333%;
  min-width: 33.3333%;
}

.cell_1_2 {
  -ms-flex: 1 0 40%;
  flex: 1 0 50%;
  max-width: 50%;
  min-width: 50%;
}

.cell_1 {
  flex: 1 0 100%;
  min-width: 100%;
}

/*
 * Responsive sizes for cell width and gutter size.
 * Mobile first - i.e. "md" classes take effect at screen widths above 768px
 * Add additional classes as needed
 */
@media v-small-min {
  .cell_1_6_sm {
    flex: 1 0 16.6667%;
    max-width: 16.6667%;
    min-width: 16.6667%;
  }

  .cell_1_4_sm {
    -ms-flex: 1 0 20%;
    flex: 1 0 25%;
    max-width: 25%;
    min-width: 25%;
  }

  .cell_1_3_sm {
    -ms-flex: 1 0 30%;
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
    min-width: 33.3333%;
  }

  .cell_1_2_sm {
    -ms-flex: 1 0 40%;
    flex: 1 0 50%;
    max-width: 50%;
    min-width: 50%;
  }

  .cell_1_sm {
    flex: 1 0 100%;
    min-width: 100%;
  }

  .guttersH30_sm {
    margin-left: -30px;
    .cell {
      padding-left: 30px;
    }
  }

  .guttersV30_sm {
    margin-top: -30px;
    .cell {
      padding-top: 30px;
    }
  }

  .guttersH60_sm {
    margin-left: -60px;
    .cell {
      padding-left: 60px;
    }
  }

  .guttersV60_sm {
    margin-top: -60px;
    .cell {
      padding-top: 60px;
    }
  }
}

@media v-med-min {
  .cell_1_6_md {
    flex: 1 0 16.6667%;
    max-width: 16.6667%;
    min-width: 16.6667%;
  }

  .cell_1_4_md {
    -ms-flex: 1 0 20%;
    flex: 1 0 25%;
    max-width: 25%;
    min-width: 25%;
  }

  .cell_1_3_md {
    -ms-flex: 1 0 30%;
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
    min-width: 33.3333%;
  }

  .cell_1_2_md {
    -ms-flex: 1 0 40%;
    flex: 1 0 50%;
    max-width: 50%;
    min-width: 50%;
  }

  .cell_1_md {
    flex: 1 0 100%;
    min-width: 100%;
  }
}

@media v-med-large-min {
  .cell_1_6_ml {
    flex: 1 0 16.6667%;
    max-width: 16.6667%;
    min-width: 16.6667%;
  }

  .cell_1_4_ml {
    -ms-flex: 1 0 20%;
    flex: 1 0 25%;
    max-width: 25%;
    min-width: 25%;
  }

  .cell_1_3_ml {
    -ms-flex: 1 0 30%;
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
    min-width: 33.3333%;
  }

  .cell_1_2_ml {
    -ms-flex: 1 0 40%;
    flex: 1 0 50%;
    max-width: 50%;
    min-width: 50%;
  }

  .cell_1_ml {
    flex: 1 0 100%;
    min-width: 100%;
  }
}

/**
 * overrides for small viewports.
 * NOTE: This breaks the mobile-first principle. Find a way to refactor
 */
@media v-small {
  /* add-on class to scroll horizontally on small viewports */
  .hScrollSm {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .hScrollSm .cell {
    flex: 0 0 75%;
    min-width: 75%;
  }
}
