.body {
  position: fixed;
  bottom: 85px;
  right: -100vh;
  width: 310px;
}

.body[data-state="open"] {
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  100% { right: 20px; }
}