@value v-standardBorder, v-standardBorderRadius from '../../styles/global_ui/util.css';
@value v-large, v-med-large, v-small from '../../styles/breakpoints.css';

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 32px;
  width: 100%;
}

.card {
  padding: 10px 30px 0px;
}

.header {
  display: flex;
  align-items: center;
  font-size: 26px;
  margin-bottom: 25px;
}

.platformHeader {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.platformsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 10px;
  align-items: center;
  min-height: 114px;
  div {
    border: v-standardBorder;
    border-radius: v-standardBorderRadius;
    background-color: var(--staticWhite);
    img {
      padding: 3px;
      max-height: 46px;
    }
  }
}

.imageWrapper {
  background-color: transparent;
  justify-content: center;
  align-items: center;
  height: auto;
  transform: translateY(0);
  transition: all 250ms ease-in-out;
}

.imageWrapperActive {
  transform: translateY(-15px) scale(0.9);
}

.platformWrapperActive {
  transform: translateY(-15px);
}

.subText {
  opacity: 0;
  transition: all 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 34px;
}

.show {
  opacity: 1;
  transform: translateY(-10px);
  transition: all 250ms ease-in-out;
  margin-top: auto;
}

@media v-large {
  .grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .card {
    padding: 20px 10px 0;
  }
}

@media v-med-large {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .platformsWrapper {
    gap: 10px;
  }
}

@media v-med {
  .subText {
    min-height: 72px;
  }
}

@media v-small {
  .grid {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .card {
    padding: 20px 5px 0;
  }
  .card > h2 {
    font-size: 16px;
  }
  .header {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .platformHeader {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .platformsWrapper {
    gap: 10px;
    div {
      max-width: 35px;
      max-height: 35px;
    }
  }
  .imageWrapper {
    max-width: 100px;
  }
  .subText {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 0;
    min-height: 59px;
  }
}

