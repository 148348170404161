.container {
  position: relative;
  width: 100%;
  max-width: 990px;
  margin: 0 auto;
  padding: 30px 15px;
}

.resultsListWrapper {
  width: 100%;
  min-width: 0;
}

.resultsPanel {
  display: flex;
  background: var(--white);
  width: 100%;
}