.enter {
  opacity: 0.01;
  max-height: 0;
}
.enter.enterActive {
  opacity: 1;
  max-height: 500px;
  transition: all 350ms ease-in-out;
}
.exit {
  opacity: 1;
  max-height: 500px;
}
.exit.exitActive {
  opacity: 0.01;
  max-height: 0;
  transition: all 250ms ease-in-out;
}
