@value v-standardBorder from '../../../styles/global_ui/util.css';
@value v-white from '../../../styles/global_ui/colors.css';

.dropdown {
  background-color: v-white;
  border: v-standardBorder;
  border-radius: 2px;
  margin-top: 5px;
  position: absolute;
  z-index: 1000;
}

.bottom {
  bottom: auto;
  top: 100%;
}

.left {
  left: 0;
  right: auto;
}

.right {
  left: auto;
  right: 0;
}

.top {
  bottom: 100%;
  top: auto;
}
