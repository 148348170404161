@value  v-asphalt, v-hackster, v-graphite from '../../../../styles/global_ui/colors.css';

/**
 * List
*/
.list {
  list-style: none;
  padding: 0;
}

.row {
  border-top: 2px solid var(--borderColor);
  display: flex;
}

.row:hover {
  .dragHandle {
    color: v-graphite;
  }
}

/**
 * Card
*/
.actions {
  font-size: 14px;
}

.buttonCntr {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
}

.cell {
  align-items: center;
  border-right: 2px solid var(--borderColor);
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  height: auto;
  overflow: auto;
  padding: 15px;

  &:last-child {
    border-right: none;
  }
}

.cell30 {
  flex-basis: 30%;
}

.cell20 {
  flex-basis: 20%;
}

.cell10 {
  flex-basis: 15%;
}

.dragHandle {
  align-items: center;
  color: v-asphalt;
  display: flex;
  height: auto;
  flex-basis: 8%;
  justify-content: center;
  width: 20px;

  &:hover {
    border-left: 2px solid v-hackster;
    cursor: pointer;
    .dragHandleIcon {
      color: v-hackster;
    }
  }
}

/**
 * NameCell
 */

.nameCell {
  composes: cell;
  composes: cell30;
  justify-content: space-between;
}

.saveBtn {
  margin-right: 5px;
  padding: 2px 6px;
}

.metaMsg {
  color: red;
  font-size: 10px;
}

.tag {
  background-color: var(--hacksterDisabledToJet);
  color: var(--lakeToWhite);
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  max-height: 100%;
  overflow: auto;
  padding: 6px 12px;
}

.title {
  display: flex;
  flex-direction: column;
}

.titleInput {
  height: 30px;
  margin-right: 10px;
  padding: 10px;
}
