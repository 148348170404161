@value v-graphite from '../../../../styles/global_ui/colors.css';
@value v-small from '../../../../styles/breakpoints.css';

.sectionHeader {
  margin-bottom: 10px;
}

.headerTitle {
  color: v-graphite;
  size: 15px;
  font-weight: bold;
}

.headerTitle:hover {
  color: v-graphite;
}

.headerCount {
  color: v-graphite;
  size: 13px;
  margin-left: 8px;
}

.projectList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.router {
  position: relative;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

@media v-small {
  .projectList {
    margin-bottom: 30px;
  }
}
