@value v-staticAsphalt, v-charcoal, v-fog, v-graphite, v-staticGraphite, v-hackster, v-pebble, v-staticPebble, v-white, v-staticWhite from './global_ui/colors.css';
@value v-small, v-med from './breakpoints.css';

.fullWidth {
  padding: 0 20px;
}

.themeAlt {
  background-color: v-fog;
}

.themeLight {
  background-color: v-white;
}

.dropdown {
  padding-bottom: 5px;
  position: relative;
}

.link {
  color: inherit;
  font-weight: bold;
  font-size: 15px;
  display: block;
}

.linkFocused {
  composes: link; /* TODO: why? */
}

.link:hover,
.link:focus {
  color: inherit;
  outline: none;
  outline-offset: 0;
  text-decoration: none;
}

.linkWrapperFocused {
  border-bottom: 3px solid v-hackster;
}

.list {
  -ms-overflow-style: none;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  overflow-x: scroll;
  scrollbar-width: none; /* firefox windows - hide scrollbar */
  padding: 0;
}

.listItem {
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  gap: 10px;
  align-items: center;
  z-index: 10;
  &:first-child {
    margin-left: 0 !important;
  }
}

/** ALT THEME COLORS */
.themeAlt .dropdown {
  background: v-fog;
  padding-bottom: 0;
}

.themeAlt .linkFocused {
  color: v-hackster;
}

.themeAlt .linkWrapper {
  color: v-graphite;

  &:hover {
    color: v-graphite;
  }
}

.themeAlt .subLinkWrapper {
  color: v-graphite;

  &:hover {
    background-color: color-mix(in srgb, var(--asphaltToFog) 15%, transparent);
    color: v-graphite;
  }
}

/** THEME LIGHT COLORS */
.themeLight .dropdown {
  background: v-white;
}

.themeLight .linkFocused {
  color: v-charcoal;
}

.themeLight .linkWrapper {
  color: v-pebble;

  &:hover {
    color: v-graphite;
  }
}

.themeLight .subLinkWrapper {
  color: v-pebble;

  &:hover {
    background-color: v-pebble;
    color: v-graphite;
  }
}

/**FULLWIDTH NAVBAR **/
.fullWidth .dropdown {
  top: 3px;
}

.fullWidth .link {
  padding: 12px 0;
}

.fullWidth .listItem {
  margin: 0 15px;
}

.fullWidth .subLinkWrapper .link {
  padding: 7px 15px;
}

/** CENTER NAVBAR **/
.center .dropdown {
  top: 13px;
}

.center .link {
  padding: 13px 0;
}

.center .listItem {
  margin: 0 22px;
}

.center .subLinkWrapper {
  padding: 5px 15px;
}

.borderBottom {
  border-bottom: var(--standardBorder);
}

@media v-small {
  .center .list {
    white-space: nowrap;
  }

  .center .listItem {
    margin: 0 15px;
  }
}

@media v-med {
  .fullWidth .list {
    margin: 0 -10px;
    white-space: nowrap;
  }
}
