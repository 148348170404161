@value v-fog from '../../../../styles/global_ui/colors.css';

.banner {
  background: v-fog;
  padding-top: 25%;
  position: relative;
  width: 100%;
}

.imageLink {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.image {
  width: 100%;
}
