.linkWrapper {
  margin-bottom: 10px;
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
}

.dropzoneBody {
  align-items: center;
  border: 1px dashed var(--asphalt);
  border-radius: 2px;
  color: var(--asphalt);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0px;
  padding: 20px;
}