.avatar {
  border-radius: 50%;
  max-height: 40px;
  max-width: 40px;
  min-width: 40px;  /* Prevents flash as image loads. */
}

.avatarSm {
  border-radius: 50%;
  max-height: 30px;
  max-width: 30px;
}

.commentRoot {
  margin: 15px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.childCommentRoot {
  margin-top: 15px;
}

.input {
  padding: 10px;
}

.inputDummy {
  height: 43px;
  padding: 10px;
}

.menuWrapper {
  display: none;
  max-height: 20px; /* Prevent jumps on hover, the Icon is a bit taller than the text. */
}

.richTextEditorRoot {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

.showMenuOnHover:hover {
  .menuWrapper {
    display: block;
  }
}

.showPreviousText {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}