@value v-asphalt, v-fog from '../../../../styles/global_ui/colors.css';
@value v-small, v-med from '../../../../styles/breakpoints.css';

.wrapper {
  display: flex;
  padding: 0 15px 30px 15px;
  margin: 15px 0 30px 0;
}

.defaultCardWrapper {
  max-width: 280px
}

.featuredCardWrapper {
  border-bottom: var(--standardBorder);
  border-top: var(--standardBorder);
  max-width: 585px;
  padding-bottom: 30px;
}

/* TODO - remove? */
.sponsoredCardContainer {
}

.sponsoredCardWrapper {
  background-color: v-fog;
  border-radius: 4px;
  padding: 30px;
  max-width: 585px;
}

@media v-med {
  .wrapper { flex-direction: column; }
  .defaultCardContainer { margin-bottom: 30px; }
  .featuredCardWrapper { margin-bottom: 30px; }
}

@media v-small {
  .defaultCardWrapper {
    border-bottom: var(--standardBorder);
    border-top: var(--standardBorder);
    max-width: 100%;
    padding: 30px 0;
  }
}
