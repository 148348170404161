.cardBorder {
  composes: cardWithBorder from '../cards.css';
}

.imageContainer {
  border: var(--secondaryBorder);
  background: var(--cardBGColor);
  display: block;
  flex: 1 0 35%;
  margin-right: 10px;
  max-height: 68px;
  max-width: 90px;
  position: relative;
}

.itemImage {
  display: block;
  height: 100%;
  max-width: 100%;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
}

.lazyImage {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.wrapper {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}
