.overlay {
  align-items: center;
  background-color: var(--white);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 900;
}

.parent {
  position: relative;
}
