@value v-pebble, v-fog from '../../../styles/global_ui/colors.css';
@value v-med, v-small from '../../../styles/breakpoints.css';

.root {
  border-bottom: var(--standardBorder);
  padding: 30px 15px 0 15px;
}

.actions {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.avatar {
  align-self: flex-start;
  border: var(--standardBorder);
  flex: 1 0 auto;
  padding: 3px;

  img {
    height: 75px;
    width: 75px;
  }
}

.body {
  color: v-pebble;
}

.callToAction {
  margin-right: 12px;
}

.content {
  padding: 0px 20px;
}

.content:first-child {
  padding-left: 0;
}

.contentWrapper {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.name {
  font-weight: 500;
  line-height: 1;
  margin-bottom: 6px;
  margin-top: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

@media v-med {
  .root {
    margin-top: 30px;
  }

  .avatar {
    align-self: center;
    margin: 0px 0px 12px 0px;
  }

  .body {
    margin-bottom: 5px;
  }

  .contentWrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .name {
    margin-bottom: 6px;
  }
}

@media v-med, v-small {
  .actions {
    flex-direction: row;
  }
}

@media v-small {
  .body {
    display: none;
  }

  .navList {
    border-top: 1px solid v-fog;
  }
}
