@value v-asphalt, v-graphite from '../../../styles/global_ui/colors.css';

.root {
  display: flex;
  justify-content: center;
}

.item {
  align-items: center;
  color: v-graphite;
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  min-width: 30px;
  border-radius: 2px;
  line-height: 1.5;
  border: var(--standardBorder);
}

.item:hover,
.item:focus {
  text-decoration: none;
  outline: none;
}

.item + .item {
  margin-left: 10px;
}

.arrow {
  composes: item;
}

.number {
  composes: item;
  padding: 0 8px;
}

.currentPage {
  background-color: v-asphalt;
  cursor: default;
}

.arrow:hover,
.number:hover {
  border-color: v-graphite;
}

.currentPage.number:hover,
.currentPage.number:focus {
  color: var(--graphite);
}

.disabled {
  composes: currentPage;
  opacity: 0.2;
}

.buffer {
  font-size: 14px;
  margin: 0 10px;
}
