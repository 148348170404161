@value v-small, v-small-min from '../breakpoints.css';

/* headers */
.h1,
.h1Responsive,
.h2,
.h2Responsive,
.h3,
.h4,
.h5 {
  color: var(--charcoal); /* default header color */
  margin-top: 0; /* reset margins, override bootstrap styles */
  margin-bottom: 0;
}

.h1,
.h1Responsive {
  font-size: 40px;
  line-height: 49px;
}

.h2,
.h2Responsive {
  font-size: 34px;
  line-height: 44px;
}

.h3,
.h3Responsive {
  font-size: 24px;
  line-height: 32px;
}

.h4 {
  font-size: 20px;
  line-height: 26px;
}

.h5 {
  color: var(--pebble);
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 14px;
  text-transform: uppercase;
}

.h1,
.h1Responsive,
.h2,
.h2Responsive,
.h3,
.h3Responsive,
.h4,
.h5 {
  font-weight: bold;
}

.h1 + .h2,
.h1 + .bodyL,
.h1Responsive + .h2,
.h1Responsive + .bodyL,
.h2 + .h3,
.h2 + .bodyL,
.h2 + .bodyM,
.h2Responsive + .h3,
.h2Responsive + .bodyL,
.h2Responsive + .bodyM {
  margin-top: 10px;
}

.h3 + .bodyL,
.h3 + .bodyM,
.h3Responsive + .bodyL,
.h3Responsive + .bodyM,
.h4 + .bodyM,
.h4 + .bodyS {
  margin-top: 5px;
}

.bodyL + .h3,
.bodyL + .h3Responsive {
  margin-top: 30px;
}

/* body */
.bodyXLResponsive {
  font-size: 22px;
  line-height: 30px;
}
.bodyL {
  font-size: 18px;
  line-height: 26px;
}

.bodyM {
  font-size: 16px;
  line-height: 22px;
}

.bodyS {
  font-size: 14px;
  line-height: 18px;
}

.bodyXS {
  font-size: 12px;
  line-height: 14px;
}

.bodyXXS {
  font-size: 10px;
  line-height: 12px;
}
.bodyXLResponsive,
.bodyL,
.bodyM,
.bodyS,
.bodyXS,
.bodyXXS {
  color: var(--graphite); /* default body color */
  font-weight: normal;
}

/* override bootstrap styles */
p.bodyL,
p.bodyM,
p.bodyS,
p.bodyXS,
p.bodyXXS {
  margin-bottom: 0;
}

p.bodyL + p.bodyL {
  margin-top: 15px;
}

/* icons */
.icon,
.iconFontSize {
  fill: currentColor;
  /* default to current font size */
  height: 1em;
  width: 1em;
}

.iconClickable {
  color: var(--charcoal);
  cursor: pointer;
}

.iconClickableBlue {
  color: var(--hackster);
  cursor: pointer;
}

.iconClickableGreen {
  color: var(--success);
  cursor: pointer;
}

.iconClickableRed {
  color: var(--error);
  cursor: pointer;
}

.iconClickableStaticWhite {
  color: var(--staticWhite);
  cursor: pointer;
}

.iconClickable:hover,
.iconClickable:active,
.iconClickable:focus {
  color: var(--hackster);
}

.iconDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* size modifiers */
.icon6 {
  height: 6px;
  width: 6px;
}
.icon8 {
  height: 8px;
  width: 8px;
}
.icon10 {
  height: 10px;
  width: 10px;
}
.icon12  {
  height: 12px;
  width: 12px;
}

.icon14  {
  height: 14px;
  width: 14px;
}

.icon16  {
  height: 16px;
  width: 16px;
}

.icon18 {
  height: 18px;
  width: 18px;
}

.icon20 {
  height: 20px;
  width: 20px;
}

.icon24 {
  height: 24px;
  width: 24px;
}


.iconfill {
  height: 100%;
  width: 100%;
}

/* Pull down icon to be inline with text - for multiline text where textWithIcon won't work */
.iconBaselineFix {
  position: relative;
  top: .125em;
}

/* links */
/* we need to create these specific link<color> classes to override bootstrap's hover rules */
.link,
.link:hover,
.link:focus {
  color: var(--graphite);
}

.linkBlue,
.linkBlue:hover,
.linkBlue:focus {
  color: var(--hackster);
  cursor: pointer;
}

.linkCharcoal,
.linkCharcoal:hover,
.linkCharcoal:focus {
  color: var(--charcoal);
  cursor: pointer;
}

.linkGraphite,
.linkGraphite:hover,
.linkGraphite:focus {
  color: var(--graphite);
  cursor: pointer;
}

.linkPebble,
.linkPebble:hover,
.linkPebble:focus {
  color: var(--pebble);
}

.linkWhite,
.linkWhite:hover,
.linkWhite:focus {
  color: var(--white);
}

.link:hover,
.link:focus,
.linkBlue:hover,
.linkBlue:focus,
.linkCharcoal:hover,
.linkCharcoal:focus,
.linkGraphite:hover,
.linkGraphite:focus,
.linkPebble:hover,
.linkPebble:focus,
.linkWhite:hover,
.linkWhite:focus {
  text-decoration: underline;
}

.link:focus,
.linkCharcoal:focus,
.linkGraphite:focus,
.linkPebble:focus,
.linkWhite:focus {
  outline-offset: 0px;
  outline: 2px solid var(--hackster);
  border-radius: 2px;
}

.linkDisabled {
  pointer-events: none !important;
  cursor: default !important;
}

/* lists */
.ol {
  list-style: inside;
  list-style-type: decimal;
  margin: 0;
  padding: 0;

  li + li {
    margin-top: 15px;
  }
}

ul.bodyL,
ul.bodyM,
ul.bodyS {
  margin: 0 0 0 10px;
  padding: 0 0 0 20px;
}

ul.bodyL + p.bodyL,
p.bodyL + ul.bodyL,
ul.bodyL + ul.bodyL {
  margin-top: 10px;
}

ul.bodyL {
  li + li {
    margin-top: 10px;
  }
}

/*
 * Utilities
 */
.anchorNoUnderlineOnFocus:focus { text-decoration: none !important; }
.anchorNoUnderlineOnHover:hover { text-decoration: none !important; }
.bold { font-weight: bold !important; }
.breakWord { word-break: break-word; }
.capitalize { text-transform: capitalize; }
.fontWeightNormal { font-weight: normal !important; }
.hoverUnderline:hover,
.hoverUnderline:focus { /* Use border-bottom instead of text-decoration to avoid gaps in link underlines */
  text-decoration: none !important;
  border-bottom: 1px solid currentColor;
}
.lineHeight0 { line-height: 0; }
.lineHeight1 { line-height: 1; }
.lineHeightNormal { line-height: normal; }
.overflowBreakWord { overflow-wrap: break-word; }
.textCenter { text-align: center; }
.textLeft { text-align: left; }
.textRight { text-align: right; }
.textTruncate4Lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.textWithIcon {
  align-items: center;
  display: inline-flex;
  .icon, .iconWrapper {
    line-height: 0;  /* Otherwise icons are misaligned */
  }
}
.underline {
  text-decoration: underline;
}
.uppercase { text-transform: uppercase; }
.whitespaceNowrap { white-space: nowrap; }
.whitespacePre { white-space: pre-wrap; } /* Let new lines render */

/* color overrides */
.asphalt { color: var(--asphalt) !important; }
.staticBlack { color: var(--staticBlack) !important; }
.charcoal { color: var(--charcoal) !important; }
.staticCharcoal { color: var(--staticCharcoal) !important; }
.error { color: var(--error) !important; }
.errorToWhite { color: var(--errorToWhite) !important; }
.staticError { color: var(--staticError) !important; }
.staticErrorHover { color: var(--staticErrorHover) !important; }
.fog{ color: var(--fog) !important; }
.staticFog { color: var(--staticFog) !important; }
.graphite { color: var(--graphite) !important; }
.staticGraphite { color: var(--staticGraphite) !important; }
.hackster { color: var(--hackster) !important; }
.hacksterToWhite { color: var(--hacksterToWhite) !important; }
.lake { color: var(--lake) !important; }
.lakeToAsphalt { color: var(--lakeToAsphalt) !important; }
.pebble { color: var(--pebble) !important; }
.rose { color: var(--rose) !important; }
.sky { color: var(--sky) !important; }
.staticGraphite { color: var(--staticGraphite) !important; }
.staticPebble { color: var(--staticPebble) !important; }
.midnight { color: var(--midnight) !important; }
.staticMidnight { color: var(--staticMidnight) !important; }
.success { color: var(--success) !important; }
.white { color: var(--white) !important; }
.staticWhite { color: var(--staticWhite) !important; }

/* Dummy placeholders */
.dummy {
  background: var(--fog);
  width: 100%;
}

.dummyS {
  background: var(--fog);
  height: 14px;
  width: 100%;
}

.dummyM {
  background: var(--fog);
  height: 16px;
  width: 100%;
}

.dummyL {
  background: var(--fog);
  height: 18px;
  width: 100%;
}

@media v-med {
  .bodyXLResponsive {
    font-size: 20px;
    line-height: 26px;
  }
}

@media v-small {
  .h1Responsive {
    font-size: 32px;
    line-height: 41px;
  }

  .h2Responsive {
    font-size: 26px;
    line-height: 32px;
  }

  .h3Responsive {
    font-size: 20px;
    line-height: 26px;
  }
}

/* Mobile-first responsive utilities */
@media v-small-min {
  .bodyL_sm {
    font-size: 18px;
    line-height: 26px;
  }
}