@value v-staticWhite from '../../../../styles/global_ui/colors.css';

.camera {
  margin-bottom: 5px;
  width: 40px;
}

.columnCenter {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.image {
  composes: columnCenter;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0 0 0 115px rgba(0,0,0,0.45);
  cursor: pointer;
  height: 115px;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  width: 115px;
}

.image:hover {
  box-shadow: inset 0 0 0 115px rgba(0,0,0,0.55);
}

.text {
  color: v-staticWhite;
  font-size: 13px;
  font-weight: bold;
  margin: 0px;
}
