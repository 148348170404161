@value v-small from '../../../../styles/breakpoints.css';
@value v-facebook, v-staticWhite, v-staticFog, v-reddit from '../../../../styles/global_ui/colors.css';

.prPrompt {
  text-align: center;
  width: 600px;
}

.prTweetContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.prTweet {
  background-color: var(--fog);
  border: var(--standardBorder);
  border-radius: 3px;
  font-size: 14px;
  padding: 15px 30px;
}

.socialBtnWrapper {
  display: inline-block;
  margin-top: 5px;
}

.socialBtn {
  border-radius: 4px;
  font-size: 14px;
  padding: 2px 10px;

  i { margin-right: 5px; }

  span {
    margin: 0;
    padding-left: 5px;
  }

  &:hover { text-decoration: none; }
}

.socialBtnF {
  background-color: v-facebook;
  color: v-staticWhite;

  span { border-left: 1px solid v-staticFog; }

  &:hover {
    color: v-staticWhite;
    background-color: #1877f2; /* facebook hover color */
    opacity: 1;
  }
}

.socialBtnR {
  background-color: v-staticWhite;
  border: var(--standardBorder);
  color: var(--staticBlack);
  margin-left: 15px;

  i { color: v-reddit; }

  span { border-left: var(--standardBorder); }

  &:hover {
    color: var(--staticBlack);
    opacity: 0.8;
  }
}

.socialBtnT {
  background-color: var(--staticBlack);
  color: v-staticWhite;
  margin-left: 15px;

  span { border-left: 1px solid var(--white); }

  &:hover {
    color: v-staticWhite;
    opacity: 0.7;
  }
}

.triangleDown {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid var(--borderColor);
  height: 0;
  position: relative;
  width: 0;
}

.triangleDown::after {
  content: '';
  border-left: 19px solid transparent;
  border-right: 19px solid transparent;
  border-top: 19px solid var(--fog);
  height: 0;
  left: -19px;
  position: absolute;
  top: -22px;
  width: 0;
}

@media v-small {
  .prPrompt { width: auto; }

  .socialBtnT { margin-left: 5px; }

  .socialBtnR { margin-left: 0 }
}