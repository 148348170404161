@value v-hackster from './colors.css';

.container {
  border-bottom: var(--standardBorder);
}

.tab {
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
}

.tab:hover, .tab:focus, .tab.active {
  text-decoration: none;
  border-bottom: 2px solid v-hackster;
  outline-offset: 0px;
  outline: none;
  border-radius: 0;
}

