@value v-pebble from '../../../../styles/global_ui/colors.css';
@value v-small from '../../../../styles/breakpoints.css';

.avatar {
  height: 115px;
  border-radius: 50%;
  width: 115px;
}

.buttons {
  margin: 14px 0 30px 0;
  height: 45px;
}

.buttons a:first-child,
.buttons button:first-child {
  margin-right: 10px;
}

.name {
  margin: 0;
  font-size: 32px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.root {
  display: flex;
}

.stats {
  margin: 4px 0;
}

.stat {
  margin-right: 15px;
  font-size: 15px;
  color: v-pebble;
  font-weight: bold;
}

.stat:hover {
  color: v-pebble
}

.stat:focus {
  text-decoration: none;
  color: v-pebble;
  outline: none;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 40px;
}

.website {
  composes: stat;
  font-weight: normal;
}

@media v-small {
  .buttons {
    margin: 15px 0 30px 0;
  }

  .name {
    font-size: 24px;
    margin-top: 5px;
  }

  .root {
    flex-direction: column;
    align-items: center;
  }

  .stat {
    margin: 0 7px;
  }

  .stats {
    margin: 6px 0px 0px 0px;
  }

  .userInfo {
    padding-left: 0;
    align-items: center;
  }

  .website {
    text-align: center;
  }
}
