@value v-med from '../../../styles/breakpoints.css';

.brandsWrapper {
  min-width: 33%;
  display: grid;
  row-gap: 22px;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;

  div  {
    height: auto !important;
  }
}

@media v-med {
  .reach {
    flex-direction: column;
    align-items: flex-start;

    div {
      text-align: left;
    }
  }

  .imageHeader {
    display: none;
  }

  .brandsWrapper {
    display: none;
  }
}