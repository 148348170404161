:global {
  .show-flex {
    display: flex!important;
  }

  .draftster-list-item + .draftster-list-item {
    margin-top: 10px;
  }

  label {
    user-select: none;
  }
}