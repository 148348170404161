.historyCol {
  max-height: 100px;
  max-width: 15%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  &:first-child { border-left: none; }
}

.historyColLarge {
  composes: historyCol;
  max-width: 27.5%;
}

.historyLink {
  font-size: 9px;
}
