@value v-staticBlack, v-error, v-fog, v-pebble, v-white from '../../../styles/global_ui/colors.css';
@value v-standardBorder, v-standardBorderRadius from '../../../styles/global_ui/util.css';

.root {
  margin: 50px 0;
  min-height: 500px;
}

.header {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
}

.newSectionBtnWrapper {
  align-items: flex-end;
  display: flex;
}

.toolbar {
  background-color: v-white;
  bottom: 0;
  box-shadow: 0 15px 19px 6px v-staticBlack;
  display: flex;
  left: 0;
  justify-content: flex-end;
  padding: 10px 135px;
  position: fixed;
  width: 100%;
  z-index: 2000;
}

/**
 * Table View
 */
.ghostDragBox {
  display: flex;
  flex-basis: 5%;
}

.headerCell {
  background-color: v-fog;
  border-left: var(--standardBorder);
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
}

.headerCellName {
  composes: headerCell;
  border-left: 0;
}

.tableContainer {
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
  margin-bottom: 40px;
  overflow: hidden;
}

/**
 * AddSectionView
 */
.inputContainer {
  max-width: 500px;
}

.inputContainerError {
  composes: inputContainer;
  border-color: v-error;
}

.menuContainer {
  background-color: v-white;
  border: var(--standardBorder);
  border-top: none;
  max-height: 200px;
  max-width: 500px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.radioGroup {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
}

.radioButtonContainer {
  cursor: pointer;
}

.option {
  display: flex;
  font-size: 14px;
}

.optionDisabled {
  color: v-pebble;
}

.optionName {
  font-weight: bold;
  margin-right: 10px;
}

/**
 * Preview
 */
.previewHeader {
  display: flex;
  max-width: 1170px;
  justify-content: space-between;
  width: 100%;
}

.previewHeaderFixedContainer {
  background-color: v-white;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  z-index: 2000;
}

.previewHeaderWrapper {
  display: flex;
  justify-content: center;
  padding: 20px 0 10px 0;
  width: 100%;
}

.previewHomePageWrapper {
  margin-top: 110px;
  /* Determined on headers height. */
}

.previewRoot {
  background-color: v-white;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  /* Cover up navbar */
}

.previewWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  min-width: 100%;
}