@value v-hackster, v-sky from './colors.css';

.ringSpinner {
  animation: rotate 2s linear infinite;
  circle {
    stroke-dasharray: 125.66;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 3s ease-in-out infinite;
    stroke-linecap: round;
  }
}

.pulse {
  animation: pulse 1.4s ease-in-out 5;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 124.66;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 93.495, 125.66;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 93.495, 125.66;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: v-hackster;
  }
  50% {
    stroke: v-sky;
  }
}

@keyframes pulse {
  0% {
    box-shadow:0px 0px 1px 0px v-hackster;
  }
  60% {
    box-shadow:0px 0px 10px 5px v-hackster;
  }
  100% {
    box-shadow:0px 0px 1px 0px v-hackster;
  }
}