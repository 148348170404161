@value v-charcoal from '../../../styles/global_ui/colors.css';

.container {
  padding-top: 50px;

  &:first-child { padding-top: 0; }
}

.dummyCard {
  margin-top: 15px;
}

.dummyProjectCard {
  composes: dummyCard;
  min-height: 330px;
}

.emtpyMsg {
  margin-top: 15px;
}

.header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  padding: 0 2px;
}

.scrollerInner {
  display: flex;
  padding: 10px 0 0 0;
  width: 100%;
}

.scrollerItem {
  display: flex;
  flex: 1 0 auto;
}

.scrollerItem + .scrollerItem {
  margin-left: 30px;
}

.scrollerItemProject {
  composes: scrollerItem;
  max-width: 270px;
  min-width: 270px;
  width: 100%;
}

.title {
  color: v-charcoal;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
