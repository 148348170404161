@value v-med-large, v-med, v-small from '../../../styles/breakpoints.css';
@value v-fog, v-white from '../../../styles/global_ui/colors.css';
@value v-standardBorder from '../../../styles/global_ui/util.css';

.aboveTheFoldWrapper {
  display: flex;
}

.banner {
  align-self: center;
  display: block;
  max-width: 100%;
  margin-bottom: 30px;
}

.container {
  /* To push the page footer all the way down. 420px is sum of top nav + footer heights */
  min-height: calc(100vh - 420px);
}

.cta {
  margin-top: 30px;
}

.ctaSection {
  border-left: v-standardBorder;
  flex: 1 1 25%;
  padding-left: 30px;
}

.featuredSection {
  display: flex;
  flex: 1;
  flex-basis: 50%;
  flex-direction: column;
  padding-right: 30px;
}

.mainContentWrapper {
  padding-bottom: 30px;
  width: 100%;
}

.sponsoredSection {
  background: linear-gradient(v-white 0%, var(--fogToHalfCharcoal) 50%, var(--fogToHalfCharcoal) 100%);
}

.sponsoredList {
  padding: 0 30px;
}

.stackedContentAdsWrapper {
  display: block;
}

.trendingSection {
  border-left: v-standardBorder;
  display: flex;
  flex: 1;
  flex-basis: 25%;
  flex-direction: column;
  padding: 0 30px;
}

@media v-med-large {
  .ctaSection {
    display: none;
  }
}

@media v-med {
  .aboveTheFoldWrapper { flex-direction: column; }

  .ctaSection {
    border-left: none;
    display: flex;
    flex-direction: column;
    padding: 60px 0 0 0;
  }

  .ctaSection, .featuredSection, .sponsoredSection, .sponsoredList, .sponsoredCardContainer {
    /* IE11 - make sections expand vertically to fit content */
    flex: 1 0 auto;
  }

  .banner { margin-bottom: 0; }

  .featuredSection {
    border-bottom: v-standardBorder;
    padding-bottom: 30px;
    padding-right: 0;
  }

  .mainContentWrapper {
    max-width: 480px;
    padding-bottom: 0;
  }

  .stackedContentAdsWrapper {
    display: flex;
  }

  .stackedContentAdsWrapper > div {
    padding-right: 15px;
  }

  .stackedContentAdsWrapper > div ~ div {
    padding-right: 0px;
    padding-left: 15px;
  }

  /* Sponsored */
  .sponsoredList {
    padding: 0;
  }

  .trendingSection {
    display: none;
  }
}

@media v-small {
  .container {
    min-height: 0;
  }

  .stackedContentAdsWrapper {
    display: flex;
  }

  .stackedContentAdsWrapper > div {
    padding-right: 15px;
  }

  .stackedContentAdsWrapper > div ~ div {
    padding-right: 0px;
    padding-left: 0px;
  }
}
