@value v-small, v-med from '../../../styles/breakpoints.css';

/* Placeholders */
.articlePlaceholder {
  height: 210px;
  width: 280px;
}

.projectPlaceholder {
  height: 202px;
  width: 270px;
}

/* Layouts */
.articleWrapper {
  max-width: 280px;
  padding: 30px 0;
  width: 100%;
}

.projectWrapper {
  max-width: 270px;
  width: 100%;
}

/* Tablet */
@media v-med {
  /* Placeholders */
  .articlePlaceholder, .projectPlaceholder {
    height: 172px;
    width: 230px;
  }

  /* Layouts */
  .articleWrapper, .projectWrapper {
    max-width: 230px;
    width: 100%;
  }
}

/* Mobile */
@media v-small {
  /* Placeholders */
  .articlePlaceholder, .projectPlaceholder {
    height: 124px;
    width: 165px;
  }

  /* Layouts */
  .articleWrapper, .projectWrapper {
    max-width: 165px;
    width: 100%;
  }
}
