@value v-small from '../../../styles/breakpoints.css';

.sidebar {
  width: 330px;
}

.sidebarWrapper {
  composes: sidebar;
  flex-shrink: 0;
  margin-left: 30px;
  position: relative;
}

@media v-small {
  .container {
    padding: 30px 5px;
  }

  .header {
    padding: 0 15px;
  }
}
