@value v-med, v-small from '../../styles/breakpoints.css';
@value v-staticWhite, v-sky from '../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../styles/global_ui/util.css';


.ctaBannerWrapper {
  color: var(--charcoalToWhite);
  background: linear-gradient(90deg, v-staticWhite 0%, v-staticWhite 45%, var(--hacksterDisabled) 100%);
  height: 130px;
  margin-bottom: 60px;
  margin-top: 60px;
  position: relative;
  text-align: center;
}

.ctaImage {
  background: none;
  height: 110px;
}

.ctaImageWrapper {
  top: 10px;
  left: 30px;
  position: absolute;
}

.bannerCardListWrapper {
  margin-bottom: -30px;
}

.bannerCardWrapper {
  flex-basis: 48.72%;
  margin-bottom: 30px;
  padding-bottom: 12.223%;
  position: relative;
}

.bannerListRoot {
  background: linear-gradient(var(--fogToHalfCharcoal) 0%, var(--white) 66.6%)
}

.moreComing {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 348px;
  border: var(--secondaryBorder);
  border-radius: v-standardBorderRadius;
  margin-left: 30px;
  min-width: 270px;
  width: 100%;
  padding: 15px;
  background: linear-gradient(var(--fogToHalfCharcoal) 0%, var(--white) 66.6%)
}

.recentCard {
  display: flex;
  flex: 1 0 auto;
  min-height: 348px;
  max-width: 270px;
  min-width: 270px;
  width: 100%;
}

.recentCard + .recentCard {
  margin-left: 30px;
}

@media v-med {
  .ctaBannerWrapper {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

@media v-small {
  .bannerCardWrapper {
    flex-basis: 100%;
    max-height: 86px;
    min-height: 86px;
  }
}

/* dark overrides - this one will take precedence if the device is set to dark (or auto and it is night) */
@media (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    .ctaBannerWrapper {
      background: linear-gradient(90deg, var(--lake) 9%, rgba(7, 91, 139, 0.7) 59.5%, var(--ocean) 100%);
    }
  }
}
/* // this one will take precedence if the user manually sets their preference to dark (even if the device is set to light this should honor the user's settings  */
[data-theme="dark"] {
  .ctaBannerWrapper {
    background: linear-gradient(90deg, var(--lake) 9%, rgba(7, 91, 139, 0.7) 59.5%, var(--ocean) 100%);
  }
}