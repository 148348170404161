@value v-hackster, v-graphite, v-white from '../../../styles/global_ui/colors.css';

.actions {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 1;
}

.action {
  display: inline-block;
  margin-left: 5px;
  padding: 5px;
  width: 32px;
  height: 32px;
  outline: none;
  border: var(--standardBorder);
  border-radius: 5px;
  background: v-white;
}

.actionsOverlay {
  display: none;
  position: absolute;
  top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  left: 0;
  padding-top: 75%;
  width: 100%;
}

.actionsOverlayAlwaysOn {
  composes: actionsOverlay;
  display: block;
}

.overlayParent:hover .actionsOverlay {
  display: block;
}

.buttonIcon {
  path {
    fill: v-graphite;
    stroke: none;
  }
}

.action:hover .buttonIcon {
  path {
    fill: v-hackster;
  }
}

.item {
  border-radius: 4px;
  display: flex;
  flex: 1;
}

.overlayParent {
  overflow: hidden;
  position: relative;
}

.transparency {
  composes: actionsOverlayAlwaysOn;
  background: rgba(255, 255, 255, 0.75);
}
