@value v-fog, v-pebble from '../../../styles/global_ui/colors.css';

/** Table view */
.tableActions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

/** Selected Videos */
.selectedPlaceholder {
  align-items: center;
  background-color: v-fog;
  border: var(--standardBorder);
  color: v-pebble;
  display: flex;
  font-size: 16px;
  height: 205px;
  justify-content: center;
}

.selectedList {
  list-style: none;
  margin: 0;
  padding: 0;
}
