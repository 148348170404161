/* value imports */
@value v-asphalt, v-staticBlack, v-charcoal, v-cobalt, v-error, v-staticErrorHover, v-fog, v-staticFog, v-graphite, v-staticGraphite, v-hackster, v-lake, v-pebble, v-rose, v-staticPebble, v-midnight, v-staticMidnight, v-sky, v-skyToLake, v-success, v-warning, v-white, v-staticWhite from './colors.css';

/* value exports */
@value v-standardBorder: var(--standardBorder);

/* v-smallBorderRadius for icons and other small things */
@value v-smallBorderRadius: 2px;
/* v-standardBorderRadius for buttons and cards */
@value v-standardBorderRadius: 4px;
/* v-largeBorderRadius for larger panels */
@value v-largeBorderRadius: 8px;

@value v-standardBoxShadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
@value v-secondaryBoxShadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);

@value v-dropShadow: drop-shadow(0px 0px 50px rgba(46, 159, 230, 0.10));

/* end value exports */

/* TODO: Add reusable class for the display attribute based on different viewport sizes/media queries */

.absolutePlaceholderParent {
  position: relative;
  height: 0;
}

.absolutePlaceholderChild {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute !important;
}

/* aspectRatio classes to be used in conjunction with absolutePlaceholderParent to specify aspect ratio of placeholder */
.aspectRatioPadding3_2 {
  padding-bottom: 66.66667%;
}

.aspectRatioPadding4_3 {
  padding-bottom: 75%;
}

.aspectRatioPadding16_9 {
  padding-bottom: 56.25%;
}

.bgAsphalt {
  background-color: v-asphalt;
}

.bgFog {
  background-color: v-fog;
}

.bgStaticFog {
  background-color: v-staticFog;
}

.bgHackster {
  background-color: v-hackster;
}

.bgStaticMidnight {
  background-color: v-staticMidnight;
}

.bgSky {
  background-color: v-sky;
}

.bgSkyToLake {
  background-color: v-skyToLake;
}

.bgTransparent {
  background-color: transparent !important;
}

.bgWarning {
  background-color: v-warning;
}

.bgWhite {
  background-color: v-white;
}

.bgStaticWhite {
  background-color: v-staticWhite;
}
.bottom9 {
  bottom: 9px;
}

.border {
  border: v-standardBorder;
}

.borderBottom {
  border-bottom: v-standardBorder;
}

.borderLeft {
  border-left: v-standardBorder;
}

.borderRight {
  border-right: v-standardBorder;
}

.secondaryBorder {
  border: var(--secondaryBorder);
}

.borderRadius {
  border-radius: v-standardBorderRadius;
}

.borderRadiusRound {
  border-radius: 100px;
}

.borderRadiusCircle {
  border-radius: 100%;
}

.borderRadiusLarge {
  border-radius: v-largeBorderRadius;
}

.borderRadiusSmall {
  border-radius: v-smallBorderRadius;
}

.borderRadiusTopLeftTopRight {
  border-top-left-radius: v-standardBorderRadius;
  border-top-right-radius: v-standardBorderRadius;
}

.borderTop {
  border-top: v-standardBorder;
}

.boxShadow {
  box-shadow: v-standardBoxShadow;
}

.boxShadow2 {
  box-shadow: v-secondaryBoxShadow;
}

.boxShadow3 {
  box-shadow: var(--tertiaryBoxyShadow);
}

.boxShadowNone {
  box-shadow: none;
}

.dropShadow {
  filter: v-dropShadow;
}

.categoryBadge {
  align-items: center;
  border: v-standardBorder;
  border-radius: 4px;
  display: inline-flex;
  padding: 5px 10px;
}

.categoryBadgeRectSmall {
  border-radius: 2px;
  height: 10px;
  min-width: 10px;
  width: 10px;
}

.categoryLakeBG {
  background-color: v-lake;
}

.categoryGrayBG {
  background-color: v-asphalt;
}

.categoryGreenBG {
  background-color: v-success;
}

.categoryOrangeBG {
  background-color: v-warning;
}

.categoryCobaltBG {
  background-color: v-cobalt;
}

.categoryRoseBG {
  background-color: v-rose;
}

.circle {
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.displayNone {
  display: none !important;
}

.height100P {
  height: 100%;
}

.hidden {
  visibility: hidden;
}

.hr {
  border-top: v-standardBorder;
}

.cursorPointer:hover {
  cursor: pointer;
}

.disabled {
  cursor: default !important;
  opacity: 0.5 !important;
  pointer-events: none;
}

.outlineNone {
  outline: none;
}

.overflowFlexHack {
  /* restricts the width of an element whose children are making it overflow its flex parent */
  min-width: 1px;
}

.overflowHidden {
  overflow: hidden;
}

.overflowVisible {
  overflow: visible !important;
}

.posAbsolute {
  position: absolute;
}

.posRelative {
  position: relative;
}

/* Dark Mode Show / Hide */
.showOnDarkMode {
  display: none;
}

.hideOnDarkMode {
  display: inherit;
}

[data-theme="dark"] {
  .showOnDarkMode {
    display: inherit;
  }
  .hideOnDarkMode {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .showOnDarkMode {
    display: inherit;
  }
  .hideOnDarkMode {
    display: none;
  }

  [data-theme="light"] {
    .showOnDarkMode {
      display: none;
    }
    .hideOnDarkMode {
      display: inherit;
    }
  }
}

/* Dark Mode Show / Hide with flex display. Useful when components css is directly set to display: flex LazyImage containers use this in some places  */
.showFlexOnDarkMode {
  display: none;
}

.hideFlexOnDarkMode {
  display: flex;
}

[data-theme="dark"] {
  .showFlexOnDarkMode {
    display: flex;
  }
  .hideOnDarkMode {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .showFlexOnDarkMode {
    display: flex;
  }
  .hideFlexOnDarkMode {
    display: none;
  }

  [data-theme="light"] {
    .showFlexOnDarkMode {
      display: none;
    }
    .hideFlexOnDarkMode {
      display: flex;
    }
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  opacity: 0;
}

.statusCircleGreen {
  background-color: v-success;
  border-radius: 50%;
  flex-shrink: 0;
  height: 7px;
  overflow: hidden;
  width: 7px;
}

.statusCircleGold {
  background-color: v-warning;
  border-radius: 50%;
  flex-shrink: 0;
  height: 7px;
  overflow: hidden;
  width: 7px;
}

.statusCircleRed {
  background-color: v-error;
  border-radius: 50%;
  flex-shrink: 0;
  height: 7px;
  overflow: hidden;
  width: 7px;
}

.ulReset {
  list-style: none;
  margin: 0;
  padding: 0;
}