@value v-asphalt, v-pebble, v-charcoal, v-graphite from '../../../styles/global_ui/colors.css';

.root {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-width: 100%;
  width: 330px;
}

.avatar {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.avatarAnchor {
  align-items: center;
  display: flex;
  margin-right: 15px;
  max-width: 80px;
}

.bio {
  border-top: 2px solid var(--borderColor);
  color: v-graphite;
  font-size: 15px;
  line-height: 1.2;
  overflow-y: auto;
  margin: 10px 0 0;
  padding-top: 10px;
}

.infoContainer {
}

.name {
  color: v-graphite;
}

.name:hover {
  color: v-charcoal;
  text-decoration: none;
}

.statBreaker {
  margin: 0 3px;
}

.stats {
  color: v-pebble;
  font-size: 13px;
  margin-bottom: 10px;
}

.topSection {
  display: flex;
}
