.dropdown {
  width: 270px;
}

.widgetDropdown {
  transform: translateY(-50%);
}

.listToggles {
  list-style-type: none;
  margin: 0;
  max-height: 150px;
  overflow: scroll;
  padding: 0;
}

.listToggle + .listToggle {
  margin-top: 5px;
}

.widgetHeader {
  padding: 10px 15px;
}
