.root {
  display: inline-flex;
  position: relative;
}

.arrowUp {
  transform: scaleY(-1);
}

.divider {
  width: 1px;
  background: var(--white);
}

.dropdownButton {
  align-items: center; /* IE11 */
  display: flex;
  justify-content: center;
  width: 41px;
  height: 100%;
}