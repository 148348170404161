@value v-asphalt, v-graphite from '../../../styles/global_ui/colors.css';

/* TODO: separate color from size for more variability? Get standardized icon from design? */
.dark {
  align-items: center;
  background: v-asphalt;
  cursor: default;
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  justify-content: center;
  width: 20px;
}

.light {
  border: 1px solid v-graphite;
  display: inline-block;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  width: 17px;
}

.tooltipInner {
  width: 300px;
}
