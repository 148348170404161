
@value v-pebble, v-charcoal, v-error, v-errorHover, v-hackster, v-hacksterHover from '../../../styles/global_ui/colors.css';
/**
 * Selectable View
 */
.address {
  display: flex;
}

.address:hover {
  cursor: pointer;
}

.btnWrapper {
  padding-left: 30px; /* 20px (Radio) + 15px (Margin right)*/
  margin-top: 5px;
  display: flex;
  gap: 15px;
}

.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  outline: none;
  padding: 0;
  text-align: center;
}

.editBtn {
  composes: btn;
  color: v-hackster;
}

.editBtn:hover {
  color: v-hacksterHover;
}

.deleteBtn {
  composes: btn;
  color: v-error;
}

.deleteBtn:hover {
  color: v-errorHover;
}

.deleteBtn:disabled {
  color: v-pebble;
  cursor: not-allowed;
}

.makeDefaultBtn {
  composes: btn;
  color: v-charcoal;
}

.radio {
  &:hover {
    cursor: pointer;
  }
}

.selectable {
  display: flex;
  flex: 50%;
  flex-direction: column;
  margin-top: 45px;
}