@value v-small from '../../../../../styles/breakpoints.css';

.container {
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 20px;
}

.image {
  width: 100%;
}

.circleImage {
  composes: image;
  border-radius: 50%;
}

@media v-small {
  .imageDimensions {
    width: 40px;
    height: 40px;
  }
}
