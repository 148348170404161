@value v-pebble, v-white from '../../../styles/global_ui/colors.css';

.appliedFiltersCount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: v-pebble;
  color: v-white;
}
