.root {
  background-color: var(--white);
  border: 1px solid var(--hackster);
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 16px;
  height: auto;
  line-height: 21px;
  max-height: 250px;
  position: absolute;
  overflow-y: auto;
  width: 100%;
  z-index: 2000;
}