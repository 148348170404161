@value v-pebble, v-white from '../../../styles/global_ui/colors.css';

.root:focus,
.root:hover {
  cursor: pointer;
  opacity: 0.7;
  text-decoration: none;

  .info .action {
    color: black;
  }
}

.action {
  color: v-pebble;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.info {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 20px;
}

.itemImage {
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 66.777777777%;
  height: 0;
}

.lazyImage {
  background-color: v-white;
  bottom: 0;
  left: 0;
  padding: 15px 15px 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pitch {
  color: v-pebble;
  font-size: 14px;
  margin-bottom: 15px;
}
