@value v-asphalt, v-error, v-hackster, v-white from '../../../../../styles/global_ui/colors.css';
@value v-standardBorder, v-standardBorderRadius from '../../../../../styles/global_ui/util.css';
@value v-med-large from '../../../../../styles/breakpoints.css';

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  position: relative;
}

.noBorder {
  border: none !important;
}

.sectionHeader {
  border-bottom: var(--standardBorder);
  margin-bottom: 30px;
}

.important {
  background: var(--errorBG);
  margin-bottom: 30px;
  padding: 10px;
}

/**
 * Category
 */
.category {
  padding-top: 30px;
}

.categoryHeader {
  align-items: flex-end;
  border-bottom: var(--standardBorder);
  display: flex;
  justify-content: space-between;
  padding: 0 5px 10px 0px;
}

.categoryHeaderError {
  border-bottom: 1px solid v-error;
}

.categoryHeaderText {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  width: 100%;

  &:hover { cursor: pointer; }
}

.sortableList {
  border: none !important;
}

.sortableCategory {
  composes: category;
  background-color: v-white;
  &:hover {
    .categoryHeaderText { cursor: row-resize; }

    .dragIcon { color: v-hackster; }
  }
}

/**
 * EditableChecklist
 */
.checklistContainer {
  margin-top: 45px;
  width: 700px;
}

.checklistCheckboxWrapper {
  margin-bottom: 15px;
}

/**
 * Prizes
 */
.arrow {
  transition: .2s linear;
  &:hover { cursor: pointer; }
}

.arrowSelected {
  transform: rotate(-180deg);
}

.prizesContainer {
  width: 700px;
}

.trash {
  margin-right: 15px;

  &:hover {
    color: v-error;
    cursor: pointer;
  }
}

/**
 * Prize
 */
.prize {
 display: flex;
 margin-top: 30px;
}

.prizeData {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  justify-content: center;
  width: 100%;
}

.prizeImg {
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
}

.prizeImgWrapper {
  display: flex;
  flex-direction: column;
  max-width: 217px;
  min-width: 217px;
  justify-content: left;

  :global {
    .form-group { margin: 0; }
  }
}

.trophy {
  background-color: v-asphalt;
  height: 60px;
  margin-bottom: 10px;
  width: 60px;
}

/**
 * Menu
 */
.menuContainer {
  align-items: center;
  display: flex;
  margin-top: 45px;
  justify-content: flex-end;
  position: relative;
}

.categoryDropdown {
  margin-left: 30px;
}

@media v-med-large {
  .checklistContainer { width: 100%; }
  .prizesContainer { width: 100%; }
}
