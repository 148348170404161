@value v-hackster, v-staticWhite from '../../../styles/global_ui/colors.css';

.hasLinks {
  a,
  a:hover,
  a:focus {
    color: v-hackster;
    text-decoration: underline;
  }
}

.hasLinksWhite {
  a,
  a:hover,
  a:focus {
    color: v-staticWhite;
    text-decoration: underline;
  }
}
