@value v-standardBorder from '../../../styles/global_ui/util.css';
@value v-fog, v-hackster, v-white from '../../../styles/global_ui/colors.css';

.popoverContent {
  background-color: v-white;
  z-index: 12;
}

.arrow {
  fill: v-white;
  transform: translateY(-1px);
  z-index: 1000;
}

.arrow > path {
  stroke: var(--borderColor);
  stroke-linejoin: round;
  stroke-width: 1px;
  transform: translateY(-1px);
}

.arrow > rect {
  fill: v-white;
  transform: translateX(2px);
}

.popoverClose {
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popoverClose:hover,
.popoverClose:focus,
.popoverClose:focus-visible {
  background-color: v-hackster;
  color: v-white;
}
