.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.author {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}