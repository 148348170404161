@value v-med from '../../styles/breakpoints.css';
@value v-fog, v-white from '../../styles/global_ui/colors.css';

.progressBarWrapper {
  max-width: 234px;
  min-width: 234px;
}

.popupContainer {
  max-width: 289px;
}

.hrBar {
  position: absolute;
  top: 50%;
  z-index: -1;
  width: 100%;
}

.viewMoreButton,
.viewMoreButton:disabled:hover {
  border-color: var(--borderColor);
  border-radius: 9999px;
  padding: 2px 44px;
  background-color: v-white;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.viewMoreButton:active,
.viewMoreButton:focus,
.viewMoreButton:hover {
  background-color: v-fog;
  outline: none;
  border: var(--standardBorder);
  box-shadow: none;
}

.icon {
  transition: 250ms linear all;
}

.toggled {
  transform: rotate(180deg);
}

@media v-med {
  .finishOnboarding {
    flex-direction: column;
    align-items: flex-start;
  }

  .progressBarWrapper {
    flex-direction: row;
    gap: 16px;
    max-width: 100%;
    min-width: 100%;
    p {
      min-width: fit-content;
    }
  }
}
