@value v-small from '../../../../styles/breakpoints.css';
@value v-asphalt, v-pebble from '../../../../styles/global_ui/colors.css';

.focused {
  border: 1px solid v-pebble;
}

.input {
  border: none;
  margin-left: 15px;
  width: 100%;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: v-asphalt;
}

.wrapper {
  display: flex;
  align-items: center;
  height: 45px;
  width: 370px;

  svg {
    cursor: pointer;
  }
}

@media v-small {
  .wrapper { width: 100%; }
}
