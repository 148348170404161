@value v-hackster, v-white from '../../../styles/global_ui/colors.css';
@value v-med from '../../../styles/breakpoints.css';

.applyContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: v-white;
  padding: 10px 20px;
}

.backButton {
  padding: 20px;
}

.backButton:hover {
  color: v-hackster;
}

.panel {
  position: relative;
  padding-left: 1px; /* so radio buttons don't leak out of the left side */
  margin-right: 45px;
}

.settingsDesktop {
  width: 250px;
}

.settingsMobile {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: v-white;
  transform: translateX(0%);
  transition: transform 350ms ease-in-out;
  z-index: 1000;
}

.settingsMobileInner {
  position: absolute;
  top: 65px;
  bottom: 65px;
  left: 0;
  right: 0;
  padding: 0 20px;
  overflow-y: scroll;
}

.settingsHidden {
  composes: settingsMobile;
  transform: translateX(-100%);
}

@media v-med {
  .panel {
    margin-right: 0px;
  }

  .settingsDesktop {
    display: none;
  }

  .settingsMobile {
    display: block;
  }
}
