@value v-error, v-fog, v-graphite, v-pebble, v-white from '../../../../../styles/global_ui/colors.css';

.root {
  position: relative;
}

.actions {
  display: flex;
  &:hover:not(.disabled) {
    cursor: pointer;
  }
}

.action {
  align-items: center;
  display: flex;
  height: 100%;
}

.arrow {
  width: 0.8em;
  transition: .2s linear;
}

.arrowSelected {
  transform: rotate(-180deg);
}

.arrowWrapper {
  composes: action;
  padding-right: 15px;
}

.borderError {
  border: 1px solid v-error !important;
}

.closeX {
  height: 0.6em;
  width: 0.6em;
}

.closeXWrapper {
  composes: action;
  padding-right: 10px;

  &:hover {
    color: v-error;
  }
}

.container {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 2px;
  color: v-graphite;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 52px;
  width: 100%;

  &:hover:not(.disabled) {
    border-color: v-pebble;
    cursor: text;
  }
}

.container.disabled {
  opacity: .5;
  background-color: var(--fog);
}

.input {
  border: none;
  display: inline-flex;
  flex: 1;
  font-size: 16px;
  height: auto;
  line-height: 21px;
  padding: 0;
  width: auto;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 21px;
  padding-left: 15px;
  margin-right: 15px;
  width: 100%;
}

.leftIcon {
  color: v-pebble;
}

.leftIconWrapper {
  align-self: center;
  padding-left: 15px;
}

.menuOpened { /* Container focused */
  border-color: v-pebble;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
}

/* Multi Selected */
.multiOpt {
  background-color: v-fog;
  border-radius: 2px;
  display: inline-flex;
  justify-content: space-between;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.multiText {
  align-self: center;
  padding: 5px 0 5px 10px;
}

.multiOptX {
  margin: 0 10px;
  width: 12px;
}

.multiOptXWrapper {
  align-items: center;
  display: flex;
  height: 100%; /* Safari */
  &:hover {
    color: v-pebble;
    cursor: pointer;
  }
}
