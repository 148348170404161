@value v-facebook, v-google, v-facebookHover, v-googleHover, v-arduino, v-arduinoHover, v-github, v-githubHover, v-white, v-staticWhite from '../../../styles/global_ui/colors.css';

.arduino .button {
  background-color: v-arduino;
  border-color: v-arduino;
  color: v-staticWhite;
}

.arduino .button:hover,
.arduino .button:active {
  background-color: v-arduinoHover;
  border-color: v-arduinoHover;
}

.arduino .button:focus {
  background-color: v-arduino;
  border-color: v-arduinoHover;
}

.button {
  align-items: center;
  display: flex;
}

.button:disabled {
  opacity: 1;
  cursor: not-allowed;
}

.facebook .button {
  background-color: v-facebook;
  border-color: v-facebook;
}

.facebook .button:hover,
.facebook .button:active {
  background-color: v-facebookHover;
  border-color: v-facebookHover;
}

.facebook .button:focus {
  background-color: v-facebook;
  border-color: v-facebookHover;
}

.facebook .icon {
  color: v-facebook;
}

.fullWidth .button {
  padding: 0;
  width: 100%;
}

.fullWidth .icon {
  background: v-staticWhite;
  margin-right: 15px;

  i,
  svg {
    width: 17px;
  }
}

.github .button {
  background-color: v-github;
  border-color: v-github;
}

.github .button:hover,
.github .button:active {
  background-color: v-githubHover;
  border-color: v-githubHover;
}

.github .button:focus {
  background-color: v-github;
  border-color: v-githubHover;
}

.google .button {
  background-color: v-google;
  border-color: v-google;
}

.google .button:hover,
.google .button:active {
  background-color: v-googleHover;
  border-color: v-googleHover;
}

.google .button:focus {
  background-color: v-google;
  border-color: v-googleHover;
}

.icon {
  align-items: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  display: inline-flex;
  height: 47px;
  justify-content: center;
  width: 47px;

  i,
  svg {
    display: block;
  }
}

.square .button {
  padding: 0;
}

.square .icon {
  font-size: 25px;
  height: 41px;
  width: 41px;
}
