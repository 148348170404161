@value v-fog from '../../../styles/global_ui/colors.css';

.fade {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.fadeIn {
  opacity: 1;
}

.image {
  composes: fade;
  height: auto;
  width: 100%;
}

.imageBG {
  composes: fade;
  background: no-repeat center center;
  background-size: cover;
}

.root {
  background-color: v-fog;
  overflow: hidden;
}
