@value v-pebble from '../../../styles/global_ui/colors.css';

/* POST CREATOR */
.postCreatorRoot {
  border: var(--standardBorder);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.postCreatorEditorWrapper {
  align-items: center;
  display: flex;
  flex: 1;  /* So that it doesn't squash the avatar margin. */
  padding-left: 5px;  /* Add an extra 5px padding to the avatar's so it's 15px total as per designs. */
  width: 100%;
}

.postCreatorEditorWrapperFocused {
  align-items: center;
  display: flex;
  margin-top: 15px;
  width: 100%;
}

.postCreatorHeader {
  display: flex;
}

.postCreatorHeaderAndEditor {
  display: flex;
  padding: 10px 15px;
}

.postCreatorHeaderAndEditorFocused {
  border-bottom: var(--standardBorder);
  flex-direction: column;
  padding: 15px;
}

/* POST BODY*/
.markdownBtn {
  border: none !important;
  color: v-pebble;
  font-weight: bold;
  padding: 0;

  &:hover,
  &:focus {
    border: none !important;
    text-decoration: none !important;
  }
}

.markdownBtn:disabled {
  opacity: 0.5;
  &:hover { color: v-pebble; }
}

.postBody {
  border-radius: 4px;
  padding: 15px;
}

.transparentBorder {
  border: 1px solid transparent;
}
