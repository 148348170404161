@value v-graphite, v-error from '../../../../styles/global_ui/colors.css';

/**
 * NOTE: cta_form and promoted_content/content_form shares this file.
 */
.error {
  color: v-error;
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.label {
  color: v-graphite;
  display: block;
  font-size: 15px;
  margin: 0;
}

.sublabel {
  color: v-graphite;
  font-weight: 300;
}

/**
 * Button With Cropper
 */
.cropperTitle {
  margin-bottom: 30px;
  text-align: left;
}

.fourThreeMaxWidth {
  max-width: 370px;
}

.oneOneMaxWidth {
  max-width: 100px;
}