@value v-graphite from '../../../../styles/global_ui/colors.css';
@value v-small from '../../../../styles/breakpoints.css';

.body {
  flex: 1 1 100%;
}

.bodyWrapper {
  min-width: 1px; /* flex hack to prevent overflowing parent */
}

.description {
  composes: body;
}

.dismiss {
  color: v-graphite;
  right: 5px;
  top: 0;
}

.image {
  flex: 0 0 158px;
  height: 158px;
}

.lockHeight {
  height: 158px;
}

.root {
  display: block;
}

.root:active, .root:focus, .root:hover {
  text-decoration: none;
}

.siteName {
  margin-top: 15px;
}

@media v-small {
  .image {
    flex: 0 0 120px;
    height: 120px;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lockHeight {
    height: 120px;
  }

  .siteName {
    margin-top: 5px;
  }
}
