@value v-asphalt, v-pebble, v-staticWhite, v-white from '../../../styles/global_ui/colors.css';

/* Carousel */
.container {
  width: 100%;
  height: 100%;
  margin: 30px 0;

  div {
    user-select: none;
  }

  span {
    user-select: none;
  }

  img {
    user-select: none;
  }
}

.resizeOverlay {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  img {
    margin: 0 auto;
    display: block;
  }
}

.viewerContainer {
  composes: container;
  margin: 0;

  .caption {
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .95));
    width: 100%;
    text-align: center;
    padding: 10px;
    color: v-staticWhite;
  }

  .hoverHighlight {
    background: none;
  }
}

.wrapper {
  position: relative;
  height: 100%;
}

.scrollContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.scrollContainerPreImgLoad {
  position: absolute;
  height: auto;
}

.noScrollContainer {
  composes: scrollContainer;
  overflow: hidden;
}

.navArea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 15%;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;

  &:hover {
    .hoverHighlight {
      background: rgba(0, 0, 0, .5);
    }
  }
}

.hoverHighlight {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, .3);
  border-radius: 50%;
}

.hoverHighlightLeft {
  composes: hoverHighlight;
  margin-left: 5px;
}

.hoverHighlightRight {
  composes: hoverHighlight;
  margin-right: 5px;
}

.navAreaLeft {
  composes: navArea;
  justify-content: flex-start;
  left: 0;
}

.navAreaRight {
  composes: navArea;
  justify-content: flex-end;
  right: 0;
}

.disabled {
  opacity: 0.2;

  &:hover {
    color: v-asphalt;
  }
}

.caption {
  color: v-pebble;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* Image */
.imageContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  height: 100%;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 960px;
  position: relative;
  height: 100%;
}

.image {
  cursor: zoom-in;
  max-width: 100%;
  max-height: 100% !important;
  opacity: 1;
}

.imageLoading {
  opacity: 0;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* viewer */
.viewerImage {
  composes: image;
  cursor: default;
}

/* dialog */
.dialog {
  background-color: rgba(0, 0, 0, .95);
}

.overlay {
  height: 100%;
}

.dialogWrapper {
  height: 100%;
  background-color: transparent;
  padding: 0;
}

.dialogBody {
  height: 100%;
}

.dismiss {
  opacity: 1;
  color: var(--staticWhite);
}