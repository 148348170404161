@value v-fog, v-pebble, v-white from '../../../../../styles/global_ui/colors.css';

.root {
  background-color: v-white;
  border: 1px solid v-pebble;
  border-top: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  font-size: 16px;
  height: auto;
  line-height: 21px;
  max-height: 250px;
  position: absolute;
  overflow-y: auto;
  width: 100%;
  z-index: 2000;
}

.disableMouseEvents {
  pointer-events: none !important;
}

.inner {
  border-top: var(--standardBorder);
  width: 100%;
}

.label {
  flex: 1;
  margin-right: 10px;
}

.option {
  display: flex;
  justify-content: space-between;
  padding: 15px;

  &:hover { cursor: pointer; }
}

.optionCustom { /* Custom options should have no spacing styles. Let the template function handle it. */
  display: flex;
  justify-content: space-between;
}

.optionNoResult {
  composes: option;
  &:hover { cursor: default; }
}

.highlight {
  background-color: v-fog;
}