.wrapper {
  min-width: 155px;
  max-height: 40px;
}

.hacksterLogo {
  width: 100%;
  padding-top: 9px;
  padding-left: 11px;
}

.presentSvg {
  width: 33px;
}