@value v-charcoal, v-white from '../../../styles/global_ui/colors.css';
@value v-largeBorderRadius, v-standardBorder, v-standardBorderRadius from '../../../styles/global_ui/util.css';
@value v-med, v-small from '../../../styles/breakpoints.css';

.dateBadge {
  left: 15px;
  position: absolute;
  top: 15px;
}

.tooltipTarget {
  display: inline-block;
  position: relative;
}

/* DateRange */
.dateDivider {
  border: 1px solid v-charcoal;
  height: 0;
  margin: 0 15px;
  width: 20px;
}

.dateWrapper {
  align-items: center;
  background-color: var(--whiteToCardBGColor);
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
  display: flex;
  justify-content: center;
  padding: 15px;
}
/* End DateRange */

/* EventCardSponsoredLarge */
.ctaLink {
  margin-left: 60px;
}

.imageLarge {
  border-radius: v-largeBorderRadius;
  height: 370px;
  max-width: 555px;
  min-width: 370px;
  overflow: hidden;
}

.largeCardInfoSection + .largeCardInfoSection {
  margin-top: 15px;
}

.largeCardInfoWrapper {}

.largeCardInfoWrapperLeft {
  composes: largeCardInfoWrapper;
  margin-right: 60px;
}

.largeCardInfoWrapperRight {
  composes: largeCardInfoWrapper;
  margin-left: 60px;
}


@media v-med {
  .ctaLink {
    display: flex;
    margin: 10px 0 0 0;
    justify-content: center;
  }

  .imageLarge {
    flex: 1 0 auto;
    width: 100%;
  }

  .largeCard {
    flex-direction: column;
  }

  .largeCardTitle {
    /* be "responsive" at med breakpoint instead of small breakpoint */
    font-size: 26px;
    line-height: 32px;
  }

  .largeCardInfoSection {
    text-align: center;
  }

  .largeCardInfoSection + .largeCardInfoSection {
    margin-top: 10px;
  }

  .largeCardInfoWrapper {
    margin: 22px 0 0 0;
    max-width: 555px;
    width: 100%;
    align-items: center;
  }
}

@media v-small {
  .imageLarge {
    height: 0;
    min-width: unset;
    padding-top: 100%;
  }
}
/* End EventCardSponsoredLarge */
