/* Container is to help the LinkMenu position itself. */
.container {
  height: 48px;
  background-color: var(--white);
}

.wrapper {
  align-items: center;
  background-color: var(--cardBGColor);
  border: var(--standardBorder);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: center;
}

.fixed {
  border: var(--standardBorder);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: fixed;
  top: 0;
  -webkit-backface-visibility: hidden;
}

.toolbar {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 80%;
}