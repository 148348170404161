@value from '../../../styles/global_ui/colors.css';

.card {
  border-bottom: var(--standardBorder);
  padding: 30px 0;

  &:first-child {
    border-top:  var(--standardBorder);
  }
}

.approveIconWrapper {
  padding: 10px 15px 8px 0;
}

.rejectIconWrapper {
  border-left: var(--standardBorder);
  padding: 10px 0 8px 15px;
}
