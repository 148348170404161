@value v-med, v-small from '../../../../styles/breakpoints.css';

.card {
  display: flex;
  align-items: center;
}

.image {
  height: 136px;
  width: 204px;
  flex-shrink: 0;
}

.text {
  margin: 0 0 0 30px;
}

@media v-med {
  .card {
    flex-direction: column;
    align-items: flex-start;
  }

  .image {
    width: 100%;
    height: 0;
    padding-top: 66.66666%;
  }

  .text {
    margin: 15px 0 0 0;
  }
}

@media v-small {
  .image {
    padding-top: 100%;
  }
}
