@value v-large, v-med, v-med-large, v-small from '../../../styles/breakpoints.css';

.section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  gap: 60px;
  width: 100%;
}

.content {
  max-width: 66%;
  min-width: 40%;
}

.imageWrapper {
  min-width: 33%;
  display: flex;
  gap: 22px;
  justify-content: flex-end;

  div {
    height: auto !important;
  }
}

@media v-med-large {
  .imageWrapper {
    flex-wrap: wrap;
  }
}

@media v-med {
  .section {
    gap: 0;
  }
  .content {
    max-width: 100%;
    width: 100%;
  }
  .imageWrapper {
    display: none;
  }
}
