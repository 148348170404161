@value v-pebble, v-charcoal from '../../../styles/global_ui/colors.css';

.breadcrumbs {
  margin: 0;
}

.noProjectsText {
  display: block;
  margin-top: 24px;
}

.header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.headerInner {
  composes: header;
  align-items: center;
}


.pageHeaderContainer {
  composes: header;
  margin-bottom: 24px;
}

.pageHeaderWrapper {
  width: 100%;
  margin-bottom: 10px;
}

.pageHeader {
  font-size: 32px;
}

.viewProfileLink {
  font-size: 15px;
  font-weight: bold; /* change to medium-weight when fonts change*/
  margin-top: -5px;

  a {
    color: v-pebble;

    &:hover {
      color: v-charcoal;
    }
  }
}
