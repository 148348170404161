@value v-fog, v-charcoal from '../../../styles/global_ui/colors.css';
@value v-standardBorderRadius  from '../../../styles/global_ui/util.css';

.popupContainer {
  width: 310px;
}

.container {
  padding: 0;
  width: inherit;
}

.container h4 {
  margin: 0;
  color: v-charcoal;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.container h4 span {
  display: block;
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.container div {
  padding: 6px 7px;
  line-height: 0;
  margin: auto;
  svg {
    width: 28px;
  }
}

.container p {
  max-height: 255px;
  overflow-y: scroll;
  min-width: 100%;
  background-color: v-fog;
  border-radius: v-standardBorderRadius;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: v-charcoal;
}

.iconWrapper {
  padding: 4px;
  background-color: var(--hackster);
  border-radius: 100%;
  &:focus, &:hover {
    background-color: var(--hacksterHover);
  }
}