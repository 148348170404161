@value v-small from '../../../styles/breakpoints.css';
@value v-graphite, v-staticWhite, v-white from '../../../styles/global_ui/colors.css';

.actions {
  margin-top: 20px;
}

.dialog {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}

.dismiss {
  color: v-graphite;
}

.fullScreen {
  flex: 1 0 auto;
  margin-bottom: 0px!important;
  margin-top: 0px!important;
  max-width: 100%!important;
  width: 100%!important;
}

.title {
  line-height: 1;
  margin: 0 0 20px;
  text-align: center;
}

.wrapper {
  background-color: v-white;
  border: 1px solid var(--transparentToHalfFog);
  padding: 60px;
  position: relative;
  margin-bottom: 40px;
  margin-top: 40px;
  width: auto;
  z-index: 1050;
}

/* Dark theme */
.dialogDark {
  composes: dialog;
  background-color: rgba(0, 0, 0, 0.9);
}

.wrapperDark {
  composes: wrapper;
  background: none;
}

.dismissDark {
  color: v-staticWhite;
}

@media v-small {
  .wrapper {
    margin: 20px;
    padding: 30px;
  }
}
