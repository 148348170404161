@value v-pebble, v-charcoal, v-graphite from '../../../../styles/global_ui/colors.css';

.linkContainer {
  margin: 12px 0;
}

.link {
  font-size: 13px;
  a {
    color: v-pebble;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: v-charcoal;
      text-decoration: none;
    }
  }
}

.arrow {
  padding: 0px 5px;
}

.current {
  color: v-graphite;
  font-weight: bold;
}

