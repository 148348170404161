@value v-asphaltHover, v-hackster, v-pebble from '../../../styles/global_ui/colors.css';

.container {
  padding: 30px 20px 15px;
}

.header h5 {
  margin: 0 0 10px;
}

.list {
  display: block;
  padding: 0;
}

.list fieldset {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
}

.list fieldset legend {
  display: none;
}

.textarea {
  border: none;
  padding: 0;
  width: 100%;
}

.textarea textarea {
  border: 1px solid v-asphaltHover;
  border-radius: 4px;
  color: v-charcoal;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  width: 100%;
  resize: vertical !important;  /* override surveyJS default requires the !important */
}

.textarea textarea:hover,
.textarea textarea:focus {
  border-color: v-pebble;
  outline: 0;
  box-shadow: none;
}
