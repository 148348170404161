@value v-med, v-small from '../../../../../styles/breakpoints.css';

.projectCard {
  display: flex;
  flex: 0 0 240px;
}

.scrollerItem {
  composes: projectCard;
  min-height: 330px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.scrollerItem + .scrollerItem {
  margin-left: 16px;
}

.tools {
  flex-grow: 1;
  flex-shrink: 9999;
  margin-right: 30px;
  min-width: 0;
  &:last-child {
    margin-right: 0;
  }
}

.toolsGallery {
  margin-right: 5px;
  display: flex;
}

.toolsImage {
  height: 36px;
  margin-right: 5px;
  width: 48px;
}

@media v-med {
  .tools {
    flex: 1 0 auto;
    margin-right: 0;
  }
}

@media v-small {
  .toolsGallery {
    display: none;
  }
}
