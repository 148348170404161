.zIndex10 {
  z-index: 10;
}

.socialIcon {
  display: inline-block;
  width: 25px;
}

.stickyButton {
  display: block;
  flex-shrink: none;
  width: 60px;
}

.stickyFooter {
  transition: all 400ms ease-in-out;
}

.stickyFooterHidden {
  transform: translateY(100%);
  visibility: hidden;
}

.stickyFooterWrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
}
