.input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: var(--graphite);
  background-color: var(--white);
  background-image: none;
  border: var(--standardBorder);
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: var(--hackster);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    outline: 0;
  }
}

.colorError {
  color: var(--error);
}

.borderError {
  border: 1px solid var(--error);
}