@value v-fog from '../../../styles/global_ui/colors.css';

/**
 * Compose Block
 */
.bgGrey {
  background-color: v-fog;
}

.block {
  composes: dummyTextM from '../cards.css';
  border-radius: 20px;
}
/**
 * End Compose Block
 */

.author {
  composes: block;
  width: 200px;
}

.contentType {
  composes: block;
  margin-bottom: 8px;
  width: 60px;
}

.difficulty {
  composes: block;
  width: 16px;
}

.statNumber {
  composes: block;
  border-radius: 4px;
  height: 15px;
  margin-left: 5px;
  width: 12px;
}

.stats {
  display: flex;
}

.itemImage {
  composes: loader from '../../../styles/animation.css';
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.smallBody {
  width: 100%;
}

.smallTitle {
  composes: block;
  margin-bottom: 5px;
}

.title {
  composes: block;
  margin-bottom: 16px;
  width: 200px;
}

.thumbs {
  align-items: center;
  border: var(--standardBorder);
  border-radius: 4px;
  display: flex;
  font-weight: normal;
  font-size: 13px;
  padding: 6px;
  margin-right: 15px;
}

.views {
  align-items: center;
  display: flex;
  font-weight: normal;
  font-size: 13px;
}
