@value v-small from '../../../styles/breakpoints.css';
@value v-hackster, v-white from '../../../styles/global_ui/colors.css';

.root {
  background: v-white;
  padding: 15px;
  border: var(--standardBorder);
  border-radius: 4px;
  margin-top: 15px;
}

.adminBadge {
  background-color: v-hackster;
  border-radius: 4px;
  color: v-white;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  max-height: 16px;
  padding: 0 5px;
  text-transform: uppercase;
}

.avatar {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@media v-small {
  .root {
    border-left: none;
    border-radius: 0;
    border-right: none;
  }
}
