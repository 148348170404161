/**
 * Dummy Card
 */
.dummy {
  composes: dummyText from '../../../cards/cards.css';
  margin-top: 5px;
}

.dummyButton {
  composes: dummy;
  height: 28px;
  width: 60px;
}

.dummyStats {
  composes: dummy;
  height: 14px;
  width: 160px;
}
