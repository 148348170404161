@value  v-large, v-med, v-med-large, v-small from '../../styles/breakpoints.css';
@value v-largeBorderRadius, v-standardBorder, v-standardBorderRadius from '../../styles/global_ui/util.css';

.root {
 background: linear-gradient(180deg, #FFF 0%, #F0FAFF 100%);
}

.hero {
  background-image: url('data:image/svg+xml,<svg width="1680" height="881" viewBox="0 0 1680 881" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_708_2370" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-1" y="-1" width="1908" height="882"><path d="M582.167 634.093C211.221 629.164 39.4947 795.977 0 880V0H1906V235.264C1580.17 697.39 1045.85 640.255 582.167 634.093Z" fill="%232E9FE6" stroke="white" stroke-opacity="0.5"/></mask><g mask="url(%23mask0_708_2370)"><g opacity="0.8"><rect x="1915.57" y="919.256" width="1953.41" height="919.257" transform="rotate(-180 1915.57 919.256)" fill="%232E9FE6"/></g><rect width="1757.45" height="907.76" fill="url(%23paint0_linear_708_2370)"/></g><defs><linearGradient id="paint0_linear_708_2370" x1="1.38928e-05" y1="392.51" x2="1777.25" y2="473.526" gradientUnits="userSpaceOnUse"><stop stop-color="%230C72AD"/><stop offset="0.305" stop-color="%23088CB1"/><stop offset="0.65" stop-color="%2304A8B5"/><stop offset="1" stop-color="%234ABCDE"/></linearGradient></defs></svg>');
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-origin: border-box;
  padding: 100px 50px calc(100% * 1/4);
}

.left {
  max-width: 660px;
}

.rightImage {
  background-color: transparent;
  min-width: 337px;
  max-width: 100%;
}

.expertise {
  margin: 0px auto;
  margin-top: calc(-100% * 1/10);
  padding: 0 15px 180px;
}

.accordion section:not(:last-child) {
  border-bottom: v-standardBorder;
}

.imageWrapper {
  padding: 111px 0px 136px;
  gap: 164px;

  div {
    height: auto !important;
  }
}

.partnersWrapper {
  margin-top: -150px;
}

.partnersBG {
  margin-top: -13px;
  padding-bottom: 100px;
}

.partners {
  gap: 143px;
}

.logos {
  justify-content: space-around;
  max-width: 50%;
  gap: 40px 22px;
}

.logos div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logos img {
  max-height: 55px;
  width: auto;
}

.footer {
  background: linear-gradient(91deg, #46CDD7 1%, #2E9FE6 49.51%, #3DA9C9 100%);
  padding: 120px 0;
  p {
    margin-bottom: 30px;
    padding: 0 200px;
  }
}

@media v-large {
  .hero {
    padding: 75px 25px 0px;
    padding-bottom: calc(100% * 1/4)
  }

  .imageWrapper {
    padding: 80px 0px 100px;
    gap: 70px;
  }

  .partners {
    gap: 75px;
  }
}

@media v-med-large {
  .imageWrapper {
    padding: 48px 0px 68px;
    gap: 50px;
  }

  .partners {
    gap: 22px;
  }

  .logos {
    min-width: 40%;
    gap: 15px;
    li:nth-child(n+13) {
      display: none;
    }
  }

  .footer {
    padding: 36px 20px 70px;
    h2 {
      margin-bottom: 43px;
    }
    p {
      padding: 0 100px;
    }
  }
}

@media v-med {
  .hero {
    flex-direction: column-reverse;
    text-align: center;
    padding-top: 60px;
    padding-bottom: calc(100% * 1/2)
  }

  .rightImage {
    min-width: unset;
    height: auto !important;
  }

  .imageWrapper {
    gap: 20px;
  }

  .accordionTitle {
    gap: 22px;
  }

  .accordionIcon {
    max-width: 55px;
  }

  .accordion section:not(:last-child) {
    border-bottom: none;
  }

  .partnersWrapper {
    margin-top: -300px;
  }

  .partners {
    flex-direction: column;
    text-align: center;
  }

  .partners > div p {
    display: none;
  }

  .logos {
    max-width: 100%;
    gap: 30px;
  }
}

@media v-small {
  .hero {
    padding: 60px 15px calc(100% * 1/2)
  }

  .expertise {
    padding: 0 15px 0;
  }

  .community {
    display: none;
  }

  .partnersWrapper {
    margin-top: 0;
  }

  .logos {
    gap: 15px;
  }

  .footer {
    padding: 36px 15px 60px;
    p {
      padding: 0;
    }
  }
}
