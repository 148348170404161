@value v-asphalt, v-charcoal, v-fog, v-graphite, v-hackster, v-hacksterHover, v-lake, v-sky, v-white from '../../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../../styles/global_ui/util.css';
@value  v-large, v-med, v-med-large, v-small from '../../../styles/breakpoints.css';


.background {
  min-height: calc(100vh - 360px);
  background:
  /* HERO Section */
    radial-gradient(60vw 30vw at 9% 4%, var(--skyToOnyx), transparent),
    radial-gradient(60vw 30vw at 0% 7.5%, v-hackster, transparent),
    radial-gradient(30vw 25vw at 97% 11%, var(--hacksterToLake), transparent),
    radial-gradient(30vw 25vw at 80% 2%, var(--skyToOnyx), transparent),
  /* Check out PROs section */
    radial-gradient(60vw 50vw at 8% 46%, var(--skyToOnyx), transparent),
    radial-gradient(20vw 25vw at 3% 48.5%, v-hackster, transparent),
    radial-gradient(40vw 40vw at 95% 36.5%, var(--skyToOnyx), transparent),
    radial-gradient(50vw 20vw at 123% 50%, v-hackster, transparent),
    radial-gradient(60vw 60vw at 88% 53%, var(--skyToOnyx), transparent),
    /* FAQ section */
    radial-gradient(45vw 20vw at 3% 82%, var(--skyToOnyx), transparent),
    radial-gradient(25vw 25vw at 3% 88.5%, var(--hacksterToLake), transparent),
    radial-gradient(70vw 40vw at 18% 92.5%, var(--skyToOnyx), transparent),
    radial-gradient(55vw 22vw at 123% 86%, v-hackster, transparent),
    radial-gradient(60vw 20vw at 88% 97%, var(--skyToOnyx), transparent),
    radial-gradient(101vw 12vw at 50% 98%, var(--skyToOnyx), transparent);
}

.main {
  min-height: calc(100vh - 360px);
  padding: 108px 15px 233px;
  backdrop-filter: blur(100px);
  display: flex;
  flex-direction: column;
  gap: 170px;
}

.header {
  font-size: 72px;
  font-weight: 700;
}

.icon {
  width: 189px;
  color: v-hackster;
  margin-bottom: 5px;
}

.subheading {
  position: relative;
  border-radius: 150px;
  border: var(--secondaryBorder);
  background: var(--transparentHalfWhiteToTransparentTenFog);
  padding: 45px 92px 30px;
  margin-top: 82px;
  text-align: center;
  p {
    color: var(--lakeToFog);
  }
}

.checks {
  position: absolute;
  top: -24px;
  left: calc(50% - 176px);
  display: flex;
}

.checks div {
  border: 4px solid transparent;
}

.checks div:first-child {
  border: 4px solid v-hackster;
  background: var(--whiteToJet);
}
.checkmark {
  padding: 10px;
}
.dots {
  height: 24px;
  margin: 0 10px;
  border-bottom: 2px dashed var(--lakeToFog);
  width: 88px;
}

.cta {
  font-size: 24px;
  line-height: 1;
}

.cta:focus {
  border-radius: 100px;
}

.row {
  display: flex;
  gap: 100px;
  width: 100%;
}

.bubbleBlock {
  position: relative;
  display: flex;
  width: 100%;
}

.rightImage {
  margin: 0 45px 0 100px;
  margin-top: auto;
  width: 451px;
}

.bgBubble {
  width: 100%;
  height: 191px;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 178px;
  background: var(--skyToJet);
}

.singleBubble {
  width: 50%;
  height: 232px;
  z-index: -1;
  position: absolute;
  bottom: calc(50% - 116px);
  left: -15%;
  border-radius: 178px;
  background: var(--skyToJet);
}

.largeBubble {
  width: 291px;
  height: 291px;
  z-index: -1;
  position: absolute;
  bottom: calc(50% - 116px);
  left: -10%;
  border-radius: 178px;
  background: var(--skyToJet);
}

.articleBlock {
  margin:  0;
  border: var(--secondaryBorder);
  border-radius: v-standardBorderRadius;
  padding: 10px 20px;
  min-width: 50%;
  position: relative;
  background-color: var(--whiteToCardBGColor);
  display: flex;
  flex-direction: column;
  gap: 15px;

  .check {
    position: absolute;
    top: -21px;
    margin-left: 5px;
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    color: v-charcoal;
    margin-top: 0;
    margin-bottom: 0;
  }

  > p {
    font-size: 14px;
    font-weight: 400;
    color: v-graphite;
    margin-bottom: 0;
  }
}
.author {
  border-radius: 99px;
  background: v-sky;
  width: 30px;
  height: 30px;
}

.articleNotes {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.contestBlock {
  margin: 32px 0;
  border: var(--secondaryBorder);
  border-radius: v-standardBorderRadius;
  padding: 40px 10px 26px 26px;
  min-width: 50%;
  position: relative;
  background-color: var(--whiteToCardBGColor);

  h2 {
    font-size: 38px;
    font-weight: 700;
    line-height: 118.109%;
    color: v-charcoal;
    margin-top: 0;
  }

  p {
    font-size: 32px;
    font-weight: 400;
    color: v-charcoal;
    margin-bottom: 0;
  }
}

.tip {
  background-color: var(--hacksterHoverToLake);
  color: var(--staticWhite);
  padding: 10px;
  font-size: 26px;
  line-height: 1;
  font-weight: 700;
  position: absolute;
  top: -25px;
  display: flex;
  margin-right: 12px;

  svg {
    position: absolute;
    left: 25px;
    bottom: -11px;
    color: v-hacksterHover;
  }
}

.smallIcon {
  width: 95px;
  color: v-hackster;
}

.avatars {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  gap: 50px;
}

.avatar {
  border-radius: 99px;
  border: 1px solid v-hackster;
  background: v-sky;
  width: 99px;
  height: 99px;
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;

  .testimonial:nth-child(1) {
    width: calc(43% - 36px);
  }
  .testimonial:nth-child(2) {
    width: calc(57% - 36px);
  }
  .testimonial:nth-child(3) {
    width: calc(70% - 36px);
  }
  .testimonial:nth-child(4) {
    width: calc(30% - 36px);
  }
}

.testimonial {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(60% - 36px);
  border-radius: 100px;
  border: var(--secondaryBorder);
  background: var(--transparentHalfWhiteToTransparentTenFog);
  padding: 26px 42px;
  font-size: 16px;
}

.quotemark {
  position: absolute;
  color: v-hackster;
  font-size: 64px;
  font-weight: 700;
  left: -16px;
}

.brands {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 42px;
  margin: 60px 100px 0;
}

.ctaBox {
  padding: 50px 70px;
  background-color: var(--hacksterToLake);
  color: var(--staticWhite);
  width: 100%;
  text-align: center;
  border-radius: v-standardBorderRadius;
}

.ctaBox h2 {
  color: v-fog;
  font-size: 32px;
  font-weight: 700;
}

.ctaBox p {
  font-size: 24px;
  margin-bottom: 15px;
}

.ctaBox a {
  border-width: 3px;
  line-height: 1;
}

.faq {
  display: flex;
  flex-direction: column;
  gap: 33px;
  width: 100%;
}

.accordion {
  border: var(--secondaryBorder);
  border-radius: 100px;
  background: var(--transparentHalfWhiteToTransparentTenFog);
}

.accordion[open] {
  border-radius: 50px;
}

.summary {
  color: v-graphite;
  font-size: 32px;
}

@media v-large {
  .row {
    gap: 50px;
  }
  .rightImage {
    width: 351px;
  }
  .tip {
    top: -45px;
  }
  .contestBlock {
    min-width: 48%;
  }
  .avatars {
    gap: 25px;
  }
}

@media v-med-large {
  .header {
    text-align: center;
  }
  .row {
    flex-wrap: wrap;
  }
  .reverse {
    flex-direction: column-reverse;
    gap: 150px;
  }
  .rightImage {
    width: 451px;
    margin: 0 auto;
  }
  .bubbleBlock {
    width: 80%;
    margin: 0 auto;
  }
  .tip {
    top: -20px;
  }
  .avatars {
    margin: 0 auto;
    width: 100%;
  }
  .testimonials {
    .testimonial:nth-child(1) {
      width: calc(95%);
      margin: 0 auto;
    }
    .testimonial:nth-child(2) {
      width: calc(75%);
      margin: 0 auto;
    }
    .testimonial:nth-child(3) {
      width: calc(65% - 36px);
    }
    .testimonial:nth-child(4) {
      width: calc(35%);
    }
  }
}

@media v-med {
  .main {
    gap: 120px;
  }
  .subheading {
    padding: 45px 46px 30px;
  }
  .checks {
    top: -24px;
    left: calc(50% - 136px);
  }
  .dots {
    width: 48px;
  }
  .rightImage {
    width: 340px;
    margin: 0 auto;
  }
  .bubbleBlock {
    width: 95%;
  }
  .bgBubble {
    width: 100%;
    height: 150px;
  }
  .tip {
    top: -45px;
  }
  .avatars {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
  .avatar {
    width: 150px;
    height: 150px;
  }
  .testimonials {
    .testimonial:nth-child(1) {
      width: calc(95%);
      margin: 0 auto;
    }
    .testimonial:nth-child(2) {
      width: calc(75%);
      margin: 0 auto;
    }
    .testimonial:nth-child(3) {
      width: calc(95%);
      margin: 0 auto;
    }
    .testimonial:nth-child(4) {
      width: calc(75%);
      margin: 0 auto;
    }
  }
  .summary {
    font-size: 22px;
    padding: 20px 24px;
  }
}

@media v-small {
  .main {
    padding: 58px 15px 133px;
    gap: 100px;
  }
  .header {
    font-size: 62px;
  }
  .checks {
    top: -14px;
    left: calc(50% - 76px);
  }
  .dots {
    width: 28px;
    height: 15px;
    margin: 0 5px;
  }
  .checkmark {
    padding: 5px;
  }
  .checkmark svg {
    width: 12px;
    height: 12px;
  }
  .reverse {
    gap: 100px;
  }
  .bubbleBlock {
    width: 100%;
  }
  .rightImage {
    width: 340px;
    margin: 0 auto;
  }
  .bgBubble {
    width: 100%;
  }
  .tip {
    top: -45px;
  }
  .avatar {
    width: 125px;
    height: 125px;
  }
  .ctaBox {
    padding: 25px;
  }
}