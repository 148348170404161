@value v-asphalt, v-fog from './global_ui/colors.css';

@keyframes sweep {
  0%{background-position:100% 0%}
  100%{background-position:0% 0%}
}

.loader {
  position: relative;
  width: 100%;
  background: v-fog;
  background: linear-gradient(to right, v-fog 35%, v-asphalt 50%, v-fog 65%);
  background-size: 300% 100%;
  animation: sweep 1s linear infinite;
}

.loader svg {
  display: block;
  width: 100%;
}


@keyframes delayAppear {
  0%{opacity: 0}
  50% {opacity: 0}
  100% {opacity: 1}
}

.delayAppear {
  animation: delayAppear 200ms linear;
}
