@value  v-med-large, v-med from '../../styles/breakpoints.css';

.wrapper {
  gap: 98px;
}

.forms {
  max-width: 570px;
}

.showOnDarkMode {
  display: none;
}

.hideOnDarkMode {
  display: block;
}

[data-theme="dark"] {
  .showOnDarkMode {
    display: block;
  }
  .hideOnDarkMode {
    display: none;
  }
}

@media (prefers-color-scheme: dark) {
  .showOnDarkMode {
    display: block;
  }
  .hideOnDarkMode {
    display: none;
  }

  [data-theme="light"] {
    .showOnDarkMode {
      display: none;
    }
    .hideOnDarkMode {
      display: block;
    }
  }
}

@media v-med-large {
  .wrapper {
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }
  .forms {
    max-width: 100%;
  }
}

@media v-med {
  .wrapper > div {
    justify-content: center;
    max-width: 100%;
    height: unset !important;
  }

  .showOnDarkMode {
    display: none;
  }

  .hideOnDarkMode {
    display: flex;
  }

  [data-theme="dark"] {
    .showOnDarkMode {
      display: flex;
    }
    .hideOnDarkMode {
      display: none;
    }
  }

  @media (prefers-color-scheme: dark) {
    .showOnDarkMode {
      display: flex;
    }
    .hideOnDarkMode {
      display: none;
    }

    [data-theme="light"] {
      .showOnDarkMode {
        display: none;
      }
      .hideOnDarkMode {
        display: flex;
      }
    }
  }
}
