@value v-graphite from '../../../../styles/global_ui/colors.css';

.communityCardWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 250px;
}

.header {
  color: v-graphite;
  margin: 0 0 10px;
}

.result {
  display: flex;
  margin-right: 15px;
}

.wrapper {
  display: flex;
  margin: 15px 0;
  overflow-x: scroll;
}