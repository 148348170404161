@value v-asphalt, v-charcoal, v-error, v-fog, v-graphite, v-hackster, v-pebble from  './colors.css';

/**
 * Main Styles
 */
.body {
  position: relative;
}

.cell {
  align-items: center;
  border-left: var(--standardBorder);
  padding: 15px;
}

.disabled {
  color: v-asphalt !important;
}

/** Tables will extend out a bit exposing a bottom and right border, 101% will cover those up. */
.loadingOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 101%;
  height: 101%;
  background: rgba(255,255,255,0.75);
}

.noContent {
  border-top: var(--standardBorder);
  padding: 15px;
  text-align: center;
}

.row {
  border-top: var(--standardBorder);
}

.table {
  border: var(--standardBorder);
  border-radius: 1px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}

/**
 * Controls (Mass Delete, search bar, etc)
 */
.tableControlsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

/**
 * Actions
 */
.deleteBtn,
.editBtn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: bold;
  outline: none;
  padding: 0;
}

.deleteBtn {
  color: v-error;
  margin-left: 30px;
}

.editBtn {
  color: v-graphite;
}

.configMenu {
  align-items: center;
  display: flex;
}

.configMenuCog {
  color: v-charcoal;
  margin-right: 5px;
}

.configMenuDropdown {
  width: 120px;
}

.configMenuTriangle {
  height: 6px;
  width: 10px;

  polygon {
    fill: v-charcoal;
  }
}

/**
 * Selectable Cell
 */
.selectableCell {
  padding-right: 30px;
  position: relative;
}

.selectableCellBody {
  align-items: center;
  display: flex;
  margin-left: 15px;
  word-break: break-word;
}

/* We need to overlay the table's border and the row's border to chain the selected color vertically amongst rows. */
.selectableCellHighlight {
  background-color: v-hackster;
  height: 101%;
  left: -1px;
  position: absolute;
  top: 0;
  width: 2px;
}

.selectableCellInner {
  display: flex;
  padding: 15px 0 15px 14px;
  width: 100%;
}

.selectableCellPlaceholder {
  background-color: transparent;
  position: relative;
  width: 2px;
}

/**
 * Headers
 */
.thActive {
  .title {
    color: v-graphite;
  }

  .triangleWrapper {
    display: block;

    .triangle > polygon {
      fill: v-graphite;
    }
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.thCell,
.thSelectableCell {
  background-color: v-fog;
  border-left: var(--standardBorder);
  padding: 15px;
  width: 202px;
}

.thInteractive {
  color: v-pebble;

  .triangleWrapper {
    display: none;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;

    .triangleWrapper {
      display: block;
    }
  }
}

.thTitle {
  color: v-pebble;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  margin-right: 5px;
  text-transform: uppercase;
}

.thTitleWrapper {
  align-items: center;
  display: flex;
}

.triangle {
  height: 6px;
  width: 10px;

  polygon {
    fill: v-pebble;
  }
}

.triangleActive {
  transform: rotate(180deg);
}

.triangleWrapper {
  margin-bottom: 5px;
  display: none;
}

/* TODO:  Use cellN to get appropriate widths. Still figuring it out. */
.thSelectableCell {
  border: none;
  width: 570px;
}