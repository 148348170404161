/* Dialog overrides */
.dialog {
  background-color: rgba(0, 0, 0, 0.85);
}

.dismiss {
  top: 18px;
}

.dropdownArrow {
  transition: all 0.2s ease-in-out;
}

.dropdownArrowRotate {
  composes: dropdownArrow;
  transform: rotate(90deg);
}

.overlay {
  justify-content: flex-start;
}

.wrapper {
  align-self: flex-start;
  margin: 0;
  max-width: 480px;
  padding: 0;
  width: 100%;
}

.internalLink {
  align-items: center;
  display: flex;
  width: 100%;
}

.backButton {
  composes: internalLink;
  justify-content: center;
}

.internalCategory {
  composes: internalLink;
  justify-content: space-between;
}

.logo {
  height: 40px;
}

.rowBase {
  align-items: center;
  display: flex;
  padding: 15px;
  position: relative;
}

.rowBase + .rowBase {
  border-top: var(--standardBorder);
}

.row {
  composes: rowBase;
  flex-direction: column;
  align-items: flex-start;
}

.rowCenter {
  composes: rowBase;
  justify-content: center;
}

.rowHome {
  composes: row;
  height: 72px;
}

.backArrow {
  left: 15px;
  position: absolute;
  top: 23px;
}