.tempBoxShadow {
  box-shadow: 1px 1px 12px rgba(150, 230, 247, 1), 3px 3px 12px rgba(0, 0, 0, 1);
}

.imageContainer {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  display: flex;
  margin: 2%;
  transition: box-shadow 0.8s ease;
}

.order {
  align-self: center;
  font-size: 2rem;
  margin-left: 4%;
  margin-right: 2%;
}

.imageWrapper {
  background-color: var(--fog);
  min-width: 40%;
  margin: 0 2%;
}

.image {
  height: 124px;
}

.controls {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 2%;
  width: 100%;
}

.button {
  background: transparent;
  border: none;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}