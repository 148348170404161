@value v-asphalt, v-hackster from '../../../styles/global_ui/colors.css';


.link[aria-disabled="true"] {
  cursor: default;
}

.completedDot {
  border: v-hackster 2px solid;
  background-color: v-hackster;
}

.uncompletedDot {
  border: v-asphalt 2px solid;
  background-color: transparent;
}

.currentDot {
  border: v-hackster 2px solid;
}

.completedBar {
  background: v-hackster;
  height: 2px;
  width: 100%;
}

.uncompletedBar {
  background: v-asphalt;
  height: 2px;
  width: 100%;
}

.icon {
  padding: 3px;
}

