@value v-small, v-med from '../../../styles/breakpoints.css';

.adContainer {
  align-self: center;
  max-width: 270px;
  width: 100%;
}

.mobileImage {
  display: none;
}

.placeholder {
  height: 500px;
  width: 270px;
}

@media v-med {
  .adContainer {
    max-width: 480px;
    width: 100%;
  }

  .desktopImage {
    display: none;
  }

  .mobileImage {
    display: block;
  }

  .placeholder {
    height: 120px;
    width: 480px;
  }
}

@media v-small {
  .adContainer {
    /* Designed 345px for mobile, but 385px is the viewport for iPhone 6/7/8 Plus */
    max-width: 385px;
    width: 100%;
  }

  .placeholder {
    height: 86px;
    width: 346px;
  }
}