.enter {
  opacity: 0.01;
}
.enter.enterActive {
  opacity: 1;
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.exit {
  opacity: 1;
}
.exit.exitActive {
  transition: opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  opacity: 0.01;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}