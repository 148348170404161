@value v-standardBorder from '../../../styles/global_ui/util.css';
@value v-white from '../../../styles/global_ui/colors.css';

.root {
  background-color: v-white;
  border: v-standardBorder;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1100;
}
