@value v-standardBorderRadius from '../../styles/global_ui/util.css';
@value v-pebble from '../../styles/global_ui/colors.css';
@value v-med, v-small from '../../styles/breakpoints.css';

.container {
  container-name: card;
  container-type: inline-size;
}

.card {
  padding: 22px 10px 10px;
}

.dummyCard {
  padding: 45%;
}

.sectionHeader {
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.selectedCount {
  display: flex;
  align-items: flex-end;
}

.filterSearchCountContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
  padding-bottom: 22px;
  border-bottom: 2px solid v-pebble;
}

.filterSearchContainer {
  display: flex;
  justify-content: flex-start;
}

.searchIcon {
  cursor: default;
}

.searchIcon:hover {
  fill: v-pebble !important;
}

.searchInput {
  font-size: 16px;
}

.tooltipIcon {
  height: 20px;
  width: 20px;
}

.searchWrapper {
  margin-right: 20px;
}

.searchRoot {
  height: 40px;
  min-width: initial; /* search has width we dont want on the root */
}

.searchContainer {
  height: 100%;
}

.filterRoot {
  height: 40px;
  width: 209px;
}

.filterContainer {
  min-height: initial;
  border-radius: v-standardBorderRadius;
}

.filterContainer[data-opened='true'] {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
}

.image {
  padding: 3px;
}

@media v-med {
  .selectedCount {
    align-items: center;
    margin-bottom: 10px !important;
  }

  .filterSearchCountContainer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 15px;
  }

  .filterSearchContainer {
    align-items: center;
    width: 100%;
  }

  .searchWrapper {
    opacity: 1;
    margin-right: 10px;
    width: 50%;
    transition: all 250ms ease-in-out;
  }

  .filterWrapper {
    opacity: 1;
    width: 50%;
    transition: all 250ms ease-in-out;
  }

  .filterRoot {
    height: 40px;
    width: 100%;
  }
}

@media v-small {
  .sectionHeader {
    margin-bottom: 20px;
    padding-bottom: 7px;
  }

  .tooltipIcon {
    height: 16px;
    width: 16px;
  }
}

@container card (max-width: 154px) {
  .text {
    font-size: 14px;
  }
}

@container card (max-width: 137px) {
  .card {
    padding: 22px 5px 5px;
  }
}