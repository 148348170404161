@value v-small from '../../../styles/breakpoints.css';

.container {
  width: 100%;
}

.mobileImage {
  display: none;
}

.textContainer {
  margin-top: 22px;
}

@media v-small {
  .container {
    align-items: center;
    display: flex;
  }

  .imageContainer {
    height: 120px;
    padding: 0;
    width: 120px;
  }

  .linkContainer {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .mobileImage {
    display: block;
  }

  .standardImage {
    display: none;
  }

  .subTextContainer {
    display: flex;
    flex-direction: column;
  }

  .textContainer {
    flex: 1 1 0;
    justify-content: center;
    margin-left: 15px;
    margin-top: 0px;
    position: relative;
  }
}
