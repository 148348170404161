.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.resizeWrapper {
  border: var(--standardBorder);
  border-radius: 4px;
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;

  & small {
    color: var(--pebble);
    flex: 1;
    margin-right: 5px;
    white-space: nowrap;
  }

  & input {
    flex: 6;
  }
}