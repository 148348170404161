@value v-fog, v-pebble from '../../../styles/global_ui/colors.css';

.body {
  composes: body from '../cards.css';
  flex: 1 0 auto;
  overflow: hidden;
  padding: 30px;
  position: relative;
}

.user {
  flex: 0 1 auto;
  padding: 0 30px 30px 30px;
}

.linkWrapper {
  flex: 1 0 auto;
}

.linkWrapper:hover, .linkWrapper:focus {
  text-decoration: none;

  .title {
    text-decoration: underline;
  }
}

.placeholderInner {
  align-items: center;
  background-color: v-fog;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 100%;
}

.placeholderOuter {
  border: 1px dashed var(--borderColor);
  margin-bottom: 15px;
  padding: 5px;
  width: 100%;
}

.project {
  border-top: var(--standardBorder);
  padding: 15px 30px;
}

/* TODO: combine with style for nav menu footerSection? */
.projectHeader {
  color: v-pebble;
  margin-bottom: 10px;
  text-transform: uppercase;
}

/* dummy styles */
.dummyBody {
  composes: dummyTextS from '../cards.css';
  margin-bottom: 5px
}

.dummyPlaceholderInner {
  composes: loader from '../../../styles/animation.css';
  height: 56px;
  width: 100%;
}

.dummyTitle {
  composes: dummyTextL from '../cards.css';
  margin-bottom: 15px
}
