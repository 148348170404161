@value v-asphalt, v-fog, v-hackster, v-hacksterHover, v-white from '../../../../styles/global_ui/colors.css';

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemInner {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.listItem {
  background-color: v-white;
  padding: 0;
}

.listItem + .listItem {
  border-top: var(--standardBorder);
}

.listItem:hover .markRead {
  visibility: visible;
  opacity: 1;
}

.markReadPlaceholder {
  height: 15px;
  width: 15px;
}

.markRead {
  composes: markReadPlaceholder;
  background-color: v-hackster;
  opacity: 0;
}

.markRead:hover {
  background-color: v-hacksterHover;
}

.unreadItem {
  composes: listItem;
  background-color: v-fog;
}
