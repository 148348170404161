@value v-small from '../../../styles/breakpoints.css';

/* Members Table */
.memberCard {
  border-bottom: var(--standardBorder);
  padding: 30px 0;

  &:first-child {
    border-top: var(--standardBorder);
  }
}

.memberCardEditIconWrapper {
  padding: 10px 15px 8px 0;
}

.memberCardTrashIconWrapper {
  border-left: var(--standardBorder);
  padding: 10px 0 8px 15px;
}

.dashboardPanel {
  padding: 45px 60px;
}

@media v-small {
  .dashboardPanel { padding: 10px; }

  .roleFilterWrapper {
    margin-top: 10px;
    align-self: flex-end;
  }

  .roleHeader { display: none; }

  .searchInputWrapper { flex-direction: column; }
}