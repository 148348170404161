@value v-med from '../../../styles/breakpoints.css';

@media v-med  {
  .imageWrapper {
    display: none;
  }

  .listsWrapper {
    flex-wrap: wrap;
    gap: 22px
  }
}