@value v-hackster, v-fog, v-graphite from '../../../styles/global_ui/colors.css';
@value v-small from '../../../styles/breakpoints.css';
@value v-standardBorder from '../../../styles/global_ui/util.css';

.dropdown {
  border-radius: 2px;
  min-width: 100%;
  max-height: 195px;
  overflow-y: scroll;
  padding: 10px 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.icon {
  margin-left: 10px;
}

.iconOpen {
  composes: icon;
  transform: scaleY(-1);
}

.optionBase {
  color: v-graphite;
  font-size: 14px;
  font-weight: bold;
}

.label {
  composes: optionBase;
  border: v-standardBorder;
  border-radius: 2px;
  padding: 4px 10px;
}

.option {
  composes: optionBase;
  cursor: pointer;
  display: block;
  padding: 5px 30px 5px 15px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

/* This is for components that require click events, we defer the padding to itself to capture the click event. */
.optionPaddingOnChild {
  composes: option;
  padding: 0px;

  & > * {
    display: block;
    padding: 5px 30px 5px 15px;
    width: 100%;
  }
}

.option:hover {
  background-color: v-fog;
}

.optionSelected {
  composes: option;
  color: v-hackster;
}

.optionDisabled {
  composes: option;
  cursor: default;
  opacity: .5;
}

.optionDisabled:hover {
  background-color: transparent;
}

/**
 * isMobile (userAgent sniffery)
 */
.select {
  appearance: none;
  border: none;
  background: none;
  margin: 15px 0;
  min-width: 125px;
  outline: none;
  overflow: auto;
  padding: 0;
  width: 100%;
}

.select:active {
  color: v-hackster;
}

.selectIcon {
  margin: 0;
  pointer-events: none;
  position: absolute;
  right: 10px;
}

.selectWrapper {
  align-items: center;
  border: var(--standardBorder);
  display: flex;
  height: 29px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding: 0 10px;
}

@media v-small {
  .selectIcon {
    right: 0;
  }

  .selectWrapper {
    border: none;
    height: 47px;
    padding: 0;
  }
}
