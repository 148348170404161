@value v-graphite, v-pebble, v-white from '../../../../../styles/global_ui/colors.css';

.container {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 2px;
  color: v-graphite;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 43px;
  width: 100%;

  &:hover:not(.disabled) {
    border-color: v-pebble;
    cursor: text;
  }
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 21px;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-right: 15px;
  width: 100%;
}
