@value v-fog, v-hackster, v-white from '../../styles/global_ui/colors.css';

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px;
}

.card {
  background: var(--whiteToCardBGColor);
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cardBody {
  padding: 15px;
}

.cardWithBorder {
  composes: card;
  border: var(--secondaryBorder);
}

.corner {
  background: v-hackster;
  color: v-white;
  padding: 5px;
  position: absolute;
  right: -27px;
  text-align: center;
  top: 21px;
  transform: rotate(45deg);
  width: 116px;
}

.icon {
  align-self: flex-start;
  border: var(--standardBorder);
  padding: 3px;
}

.lazyImage {
  align-items: center;
  background-color: v-white;
  display: flex;
  justify-content: center;
}

.newBadgeContainer {
  color: v-hackster;
  text-align: center;
}

.newBadge1 {
  margin: 0;
  font-size: 28px;
}

.newBadge2 {
  margin: 0;
  font-size: 11px;
  margin-top: -6px;
}

.spacer {
  flex-grow: 1;
  min-height: 0px;
}

.stat + .stat {
  margin-left: 15px;
}

.stats {
  margin: 15px 0;
}

.title {
  font-weight: bold;
  display: inline-block;
  overflow-wrap: break-word;
  word-break: break-word;
}

.wrapper {
  display: flex;
  flex: 1;
}

/* Dummy Card Styles */
.dummyText {
  background: v-fog;
  width: 100%;
}

.dummyTextS {
  composes: dummyText;
  height: 14px;
}

.dummyTextM {
  composes: dummyText;
  height: 16px;
}

.dummyTextL {
  composes: dummyText;
  height: 18px;
}
