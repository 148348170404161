@value v-standardBorder, v-standardBorderRadius from '../../../../styles/global_ui/util.css';
@value v-small from '../../../../styles/breakpoints.css';

.contentWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.formWrapper {
  display: flex;
}
/**
 * FAQ
 */
.faqContainer {
  flex: 1;
  padding-left: 60px;
}

.faqBox {
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
  padding: 30px;
}

@media v-small {
  .formWrapper {
    flex-wrap: wrap;
  }

  .faqContainer {
    padding: 0;
    margin-top: 60px;
  }
}
