@value v-fog, v-graphite, v-white from '../../../styles/global_ui/colors.css';
@value v-med-large from '../../../styles/breakpoints.css';

.list {
  border: var(--standardBorder);
  border-top: none;
  border-radius: 0 0 2px 2px;
  background-color: v-white;
  left: -1px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 44px;
  width: 370px;
  z-index: 1;
}

.listItem {
  background-color: v-white;
  color: v-graphite;
  cursor: pointer;
  font-size: 15px;
  padding: 6px 15px;
  text-align: left;
}

.focused {
  background-color: v-fog;
}

@media v-med-large {
  .list {
    border: none;
    width: 100.3%;
    margin-top: 6px;
  }
}
