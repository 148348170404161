.background {
  min-height: calc(100vh - 116px - 266px);
  background: radial-gradient(30vw 30vh at 9% 8%, #E1F4FF, transparent),
    radial-gradient(30vw 30vh at 0% 12.5%, #2E9FE6, transparent),
    radial-gradient(30vw 30vh at 100% 18%, #2E9FE6, transparent),
    radial-gradient(30vw 30vh at 96% 3%, #E1F4FF, transparent);
  display: flex;
  justify-content: center;
  align-items: center ;
}
