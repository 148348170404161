@value v-med, v-small from '../../../../styles/breakpoints.css';
@value v-asphalt, v-hackster, v-pebble from '../../../../styles/global_ui/colors.css';
@value v-standardBorderRadius from '../../../../styles/global_ui/util.css';

/*
 * Components sharing this file. ['home_edit_page/ArticleSelectorList', 'home_edit/ads/list/AdCampaignListItem', 'home_edit/ads/list/AdListItem']
 */

.actionsWrapper {
  display: flex;
}

.addBtnDefault {
  max-height: 42px;
}
.addBtnPlus { display: none; }

.header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}

.listItem {
  align-items: center;
  border-bottom: var(--standardBorder);
  display: flex;
  justify-content: space-between;
  min-height: 85px;
  padding: 15px 0;
}

.listItemSelectable:hover {  /* Only used in ArticleSelectorList */
  border-bottom: 1px solid v-hackster;
}

.listItemCoverImage {
  border-radius: v-standardBorderRadius;
  height: 54px;
  width: 96px;
}

.listItemCoverImageWrapper {
  display: block;
  position: relative;
  margin-right: 30px;
}

.listItemAuthorCell {
  flex-basis: 10%;
}

.listItemDateCell {
  flex-basis: 25%;
}

.listItemMobileAuthorStatus { display: none; }

.listItemTitleCell {
  align-items: center;
  display: flex;
  flex-basis: 50%;
  word-break: break-word;
}

.listWrapper {
  min-height: 500px /* Height of the loader */
}

.star {
  color: v-pebble;
  margin-left: 5px;
  width: 24px !important;
}


@media v-med {
  .header {
    align-items: flex-start;
    flex-direction: column;
  }

  .actionsWrapper {
    margin-top: 45px;
    width: 100%;
  }

  .inputWrapper {
    width: 100%;
  }
}

@media v-small {
  .addBtnDefault { display: none; }
  .addBtnPlus { display: inline-block; }
  .listItemCoverImage {
    height: 90px;
    width: 90px;
  }
  .listItem {
    align-items: flex-start;
    min-height: 120px;
  }
  .listItemCoverImageWrapper { margin-right: 15px; }
  .listItemAuthorCell { display: none; }
  .listItemDateCell { display: none; }
  .listItemData {
    display: flex;
    flex-direction: column;
  }
  .listItemMobileAuthorStatus { display: block; }
  .listItemTitleCell {
    align-items: flex-start;
    flex-basis: 90%;
  }
  .listWrapper { min-height: 250px; }
  .statusColorBlock {
    align-self: flex-start;
    margin-top: 5px;
  }
}
