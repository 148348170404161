@value v-standardBorderRadius from '../../../../styles/global_ui/util.css';
@value v-hackster from '../../../../styles/global_ui/colors.css';

.respectButton, .respectedButton {
  position: relative;
}

.respectButton:before,
.respectButton:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: v-standardBorderRadius;
  box-shadow: 0 0 0 1.5em transparent;
}

.respectButton:hover:before,
.respectButton:hover:after {
  animation: ease 200ms;
  box-shadow: 0 0 0 0 transparent;
}

.respectButton:not(:disabled):before {
  animation: pulse 10s linear 5;
}

.respectButton:not(:disabled):after {
  animation: pulse 10s linear 1s 5;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 v-hackster; }
  20%, 100% { box-shadow: 0 0 0 1.5em transparent; }
}
