@value v-med-large from '../../../styles/breakpoints.css';

.arrowLeft {
  align-items: flex-start;
  padding: 5px 10px;
  background-image: linear-gradient(var(--cardGradientLeft));
}

.arrowRight {
  align-items: flex-start;
  padding: 5px 10px;
  background-image: linear-gradient(var(--cardGradientRight));
}

.divider {
  margin: 0 30px;
  border-left: var(--standardBorder);
  height: 43px;
}

.left {
  display: flex;
  align-items: flex-end;
  align-self: flex-end;
}

.link {
  composes: link from '../../../styles/global_ui/typography.css';
  composes: tab from '../../../styles/global_ui/nav-tabbed.css';
  display: block;
}

.linkWrapper + .linkWrapper {
  margin-left: 45px;
}

.root {
  composes: bodyM from '../../../styles/global_ui/typography.css';
  composes: wrapper1170 from '../../../styles/global_ui/layout.css';
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media v-med-large {
  .linkWrapper + .linkWrapper {
    margin-left: 15px;
  }

  .left {
    justify-content: space-between;
    width: 100%;
  }
}
