@value v-secondaryBoxShadow from '../../../styles/global_ui/util.css';
@value v-small from '../../../styles/breakpoints.css';
@value v-white from '../../../styles/global_ui/colors.css';


.banner {
  align-items: center;
  background-color: v-white;
  border-radius: 4;
  box-shadow: v-secondaryBoxShadow;
  display: flex;
  justify-content: space-between;
  flex: 0 1 1170px;
  padding: 15px;
  border: var(--secondaryBorder);
}

.buttons {
  display: flex;
  flex: 0 0 auto;
}

.content {
  display: flex;
  align-items: center;
}

.cancelButtonDesktop {
  composes: content;
  margin-left: 30px;
}

.cancelButtonMobile {
  display: none;
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

.container {
  bottom: 30px;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  width: 100%;
}

.text {
  margin: 0 30px;
}

@media v-small {
  .banner {
    flex-direction: column;
    align-items: center;
  }

  .buttons {
    align-items: stretch;
    flex-direction: column;
    width: 100%;
  }

  .cancelButtonDesktop {
    display: none;
  }

  .cancelButtonMobile {
    display: block;
  }

  .content {
    flex-direction: column;
    text-align: center;
    width: 100%; /* IE hack */
  }

  .container {
    bottom: 0;
  }

  .text {
    font-size: 16px;
    line-height: 22px;
    margin: 15px 0;
    width: 100%; /* IE hack */
  }
}
