.centerLink {
  align-items: center;
  display: flex;
  justify-content: center;
}

.secondaryBtns {
  align-items: center;
  justify-content: center;
  height: 116px;
  margin-bottom: 12px;
}
