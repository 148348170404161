@value v-largeBorderRadius from '../../../styles/global_ui/util.css';

.card {
  border-bottom: var(--standardBorder);
  padding: 30px 0;

  &:first-child {
    border-top:  var(--standardBorder);
  }
}

.leftIconWrapper {
  padding: 10px 15px 10px 0;
}

.rightIconWrapper {
  border-left: var(--standardBorder);
  padding: 10px 0 10px 15px;
}

.eventPreviewImage {
  border-radius: v-largeBorderRadius;
  height: 300px;
  max-width: 450px;
  min-width: 370px;
  overflow: hidden;
}
