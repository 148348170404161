.fixed {
  position: fixed;
  top: 10px;
  right: 10px;
  outline: 0;
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  z-index: 100;
}

.absolute {
  composes: fixed;
  position: absolute;
}
