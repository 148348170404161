@value v-med, v-small from '../../../../styles/breakpoints.css';
@value v-white from '../../../../styles/global_ui/colors.css';

.loader {
  display: flex;
  justify-content: center;
  padding: 30px;
  font-size: 32px;
}

.placeholder {
  padding: 12px 25px;
  background: v-white;
}

.placeholderLabelSegment {
  font-weight: normal;
  font-size: 15px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
}

.sectionHeader {
  align-items: center;
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
}

.sectionHeader a {
  font-size: 14px;
  font-weight: bold;
}

.count {
  margin-left: 5px;
}

.toolsAndCommunities {
  display: flex;
}

/**
 * UserInfo
 */
.infoPanel {
  display: flex;
}

.panelPositionLarge {
  flex: 70%;
  margin-right: 45px;
}

.panelPositionSmall {
  flex: 30%;
}

.userInfo {
  background-color: v-white;
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  margin-bottom: 30px;
  font-size: 15px;

  p {
    margin: 0;
  };
}

.userInfoContent {
  margin-top: 6px;
}

.userInfoPanelA {
  composes: infoPanel;
}

.userInfoPanelB {
  composes: infoPanel;
  margin-top: 30px;
}
/**
 * Ends UserInfo
 */

/**
 * Awards
 */
.award {
  align-items: center;
  background-color: v-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 25px;
  text-align: center;
  width: 100%;
}

.awardIconContainer {
  height: 55px;
  display: inline-block;
}

.awardIcon {
  vertical-align: bottom;
}
/**
 * Ends Awards
 */

/**
 * Communities
 */
.communities {
  flex-basis: 30%;
}
/**
 * Ends Communities
 */

@media v-med {
  .toolsAndCommunities {
    flex-direction: column;
  }

  .communities {
    flex: 1 0 auto;
  }
}

@media v-small {
  .container {
    padding: 16px;
  }

  .infoPanel:last-child > div:last-child {
    margin: 0;
  }

  .infoPanel {
    flex-direction: column;
  }

  .userInfoPanelB {
    margin: 0;
  }

  .panelPositionLarge {
    flex: 1 0 auto;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .panelPositionSmall {
    flex: 1 0 auto;
    margin-bottom: 20px;
  }
}
