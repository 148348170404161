@value v-large-min, v-med, v-med-large, v-med-large-min, v-small  from '../../styles/breakpoints.css';
@value v-standardBorder from '../../styles/global_ui/util.css';

.layoutWrapper {
  padding: 0 15px 120px;
}

.maxWidth240 {
  max-width: 240px;
}

.nav {
  display: flex;
  flex-direction: row;
}

/*
  forces content the same size as the logo at the end of the flex row
  to center the progress bar
*/
.nav:after {
  display: block;
  content: ' ';
  width: 155px;
  opacity: 0;
}

@media v-large-min {
  .layoutWrapper {
    padding: 0 0 120px;
  }
}

@media v-med {
  .nav {
    flex-direction: column;
  }

  .nav:after {
    display: none;
  }
}

@media v-med-large-min {
  .footer.fixed {
    position: fixed;
    bottom: 0;
    width: 100vw !important;
    border-top: v-standardBorder;
    padding: 15px;
  }

  .page {
    width: 100vw; /* forces the page to NOT resize when the scrollbar appears */
  }
}

@media v-med-large {
  .layoutWrapper {
    padding: 0 15px 0px;
    gap: 30px;
  }

  .nav {
    gap: 20px;
  }

  .footer {
    position: sticky;
    bottom: 0;
    width: calc(100% + 30px) !important;
    border-top: v-standardBorder;
    padding: 15px;
  }
}

@media v-small {
  .footerRight {
    gap: 10px;
  }
}
