.root {
  width: 100%;
  max-width: 78px;
  border: 1px solid color-mix(in srgb, var(--staticFog) 10%, transparent);
  background-color: color-mix(in srgb, var(--staticFog) 5%, transparent);
  border-radius: 100px;
  padding: 2px;
}

.optContainer {
  padding: 6px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelContainer {
  margin: 0px;
}

.labelContainer:focus-within > div {
  outline: 1px solid var(--hackster);
}

.svgHalf {
  color: var(--staticFog);
  opacity: 50%;
}

.svgFull {
  color: var(--staticFog);
}

.svgHalf:hover {
  opacity: 70%;
}

.fog10 {
  background-color: color-mix(in srgb, var(--staticAsphalt) 10%, transparent);
}