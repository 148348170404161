@value v-asphalt, v-charcoal, v-error, v-errorBG, v-errorToWhite, v-fog, v-graphite, v-hackster, v-pebble, v-warning, v-white from './colors.css';

.formGroup, /* "formGroup" here for now for legacy support */
.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

/* Above-input label */
.label,
.labelDisabled {
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}

.label {
  color: v-charcoal;
}

.labelDisabled {
  color: v-asphalt;
}

/* Below-input help/errors/warnings */
.msgWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.charCount,
.error,
.help,
.warning {
  font-size: 14px;
  line-height: 19px;
}

.charCount,
.error,
.help {
  margin-top: 5px;
}

.charCount,
.error:first-child,
.help:first-child,
.warning:first-child {
  margin-top: 10px;
}

.charCount,
.help {
  color: v-pebble;
}

.error,
.warning {
  font-weight: bold;
}

.charCount {
  position: absolute;
  right: 0;
}

.error {
  color: v-error;
}

.warning {
  color: v-warning;
}

/* Form error - for errors not specific to one input */
.formError {
  color: v-errorToWhite;
  background: v-errorBG;
  border-radius: 2px;
  display: block;
  margin: 15px 0 30px;
  padding: 15px;
  width: 100%;
}

.input[type="date"] {
  cursor: text;
  line-height: inherit;
  text-transform: uppercase;
}

.input,
.number,
.textarea {
  display: block; /* enforce vertical layout, remove ghost margin on textarea elements */
  border: var(--standardBorder);
  border-radius: 2px;
  color: v-graphite;
  font-size: 16px;
  line-height: 21px;
  padding: 15px;
  width: 100%;
}

.input::placeholder,
.textarea::placeholder
{
  color: v-asphalt;
}

/* Size modifiers */
.sm {
  padding: 10px;
}

/* Color modifiers */
.input:hover,
.input:focus,
.textarea:hover,
.textarea:focus {
  border-color: v-pebble;
  outline: 0;
  box-shadow: none;
}

.input:disabled,
.textarea:disabled
{
  color: v-asphalt;
  background-color: v-fog;
}

.inputError,
.inputError:active,
.inputError:focus,
.inputError:hover
{
  border-color: v-error;
}

/* Force "focused" border color */
.inputFocus,
.inputFocus:active,
.inputFocus:focus,
.inputFocus:hover
{
  border-color: v-pebble;
}

.halfWidth {
  max-width: 50%;
}

.number {
  -moz-appearance:textfield !important;
}

.number::-webkit-inner-spin-button,
.number::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.openRight {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/**
 * Radio
 */
/* HTML5 Boilerplate accessible hidden styles */
.hiddenInput,
.radioButton {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 0;
  height: 0;
  border: 0;
}
/* Creates the circle */
.radioButton + span:before {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 7px solid v-white;
  border-radius: 100%;
  box-shadow: 0 0 0 1px v-asphalt;
  content: '';
  transition: 0.125s ease-out all;
}
.radioButton:checked + span:before {
  background: v-white;
  border-color: v-hackster;
  box-shadow: 0 0 1px v-hackster;
}
.radioButton:checked:disabled + span:before {
  box-shadow: 0 0 0 1px v-fog;
}
.radioButton:focus + span:before {
  box-shadow: 0 0 0 1px v-charcoal;
}

.radioGroup {
  display: inline-flex;
  flex-direction: column;
}

.radioButtonContainer {
  cursor: pointer;
  margin-bottom: 10px; /* The inputs have a ghost margin-top of 5px. We want 15px alltogether. */
  max-width: fit-content;
}

.radioButtonContainer:last-child {
  margin-bottom: 0;
}

.radioButtonError + span:before {
  box-shadow: 0 0 0 1px v-error;
}

.radioGroupWrapper {
  display: inline-flex;
  align-items: center;
}

.radioGroupWrapper:hover > .radioButton:not([disabled]) + span:before {
  box-shadow: 0 0 0 1px v-charcoal;
}

/**
 * End Radio
 */

.textarea {
  resize: none;
}

/* Separating this for now to not break other textareas. This is for AutosizeTextarea. */
.textareaAutosize {
  overflow: hidden;
}

/* compose with .textarea to get preview styles - remove if this turns out not to be necessary (see note in TextArea.js) */
.textareaPreview {
  background: v-asphalt;
  padding: 3px 6px;
}
