@value v-med, v-small from '../../../styles/breakpoints.css';
@value v-fog, v-white from '../../../styles/global_ui/colors.css';

.breadcrumbs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flexSwitch {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.flexSwitchChild {}

.ideaWrapper {
  flex: 1 1 100%;
  max-width: 675px;
}

.judgingPanel {
  background-color: v-fog;
  display: flex;
  flex: 0 0 505px;
  justify-content: center;
  padding: 30px;
}

.root {
  background-color: v-white;
  display: flex;
  min-height: 100vh;
  position: relative;
}

.statTable {
  composes: flexSwitchChild;
  width: 100%;
  margin-bottom: 30px;
}

@media v-med {
  .flexSwitch {
    flex-direction: row;
  }

  .flexSwitchChild + .flexSwitchChild {
    margin-left: 30px;
  }

  .judgingPanel {
    flex: 1 0 auto;
    order: -1;
  }

  .root {
    flex-direction: column;
  }

  .statTable {
    flex-basis: 100%;
    margin-bottom: 0;
  }
}

@media v-small {
  .flexSwitch {
    flex-direction: column;
  }

  .flexSwitchChild + .flexSwitchChild {
    margin-left: 0;
  }

  .ideaHeader {
    font-size: 24px;
    letter-spacing: -0.3;
    line-height: 32px;
  }

  .statTable {
    width: 100%;
  }
}
