@value v-med-large from '../../../../styles/breakpoints.css';
@value v-fog, v-hackster, v-white from '../../../../styles/global_ui/colors.css';
@value v-standardBorder, v-standardBorderRadius from '../../../../styles/global_ui/util.css';

/**
 * Default View
 */
.defaultView {
  display: flex;
  height: 281px; /* Image dimensions 375x281 */
}

.listContainer {
  border-top: 5px solid v-hackster;
  display: flex;
  flex-direction: column;
  height: 281px;
  justify-content: space-around;
  margin-left: 30px;
  min-height: 281px;
  overflow-y: auto;
  width: 370px;
}

.listItem {
  align-items: center;
  border-top: v-standardBorder;
  display: flex;
  flex:  1;
  justify-content: space-between;
}

.listItem:first-child {
  border: none;
}

.listItem:last-child {
  padding-bottom: 0; /* NOTE: When we allow the list to be scrollable, remove this style. */
}

.listItemContentWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.listItemImageDims70 {
  background-color: v-fog;
  display: block;
  height: 70px;
  margin-left: 15px;
  min-height: 70px;
  min-width: 70px;
  width: 70px;
}

.listItemImageDims100 {
  background-color: v-fog;
  display: block;
  height: 100px;
  margin-left: 15px;
  min-height: 100px;
  min-width: 100px;
  width: 100px;
}

.listItemTitle70 {
  display: block;
  max-height: 45px;
  overflow-y: auto;
  scrollbar-width: none;  /* firefox windows - hide scrollbar */
  white-space: break-spaces;
}

.listItemTitle100 {
  display: block;
  max-height: 70px;
  overflow-y: auto;
  scrollbar-width: none;  /* firefox windows - hide scrollbar */
  white-space: break-spaces;
}

.mainCard {
  background-color: v-white;
  display: flex;
}

.mainImageWrapper { /* Maintain dimensions for loading placeholder */
  background-color: v-fog;
  display: block;
  height: 281px;
  min-height: 281px;
  min-width: 375px;
  width: 372px;
}

.mainInfo {
  background: var(--cardBGColor);
  border: var(--secondaryBorder);
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 30px;
  width: 415px;
}

.titleWrapper {
  margin-bottom: 15px;
}

.twoByCard {
  border: v-standardBorder;
  border-radius: v-standardBorderRadius;
  display: flex;
  height: 214px;  /* Image dims 285x214 */
  max-width: 570px;
}

.twoByCardInfo {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: none;  /* firefox windows - hide scrollbar */
  width: 285px;
}

.twoByMainImageWrapper {
  background-color: v-fog;
  display: block;
  height: 212px;
  min-height: 212px;
  min-width: 285px;
  overflow: hidden;
  width: 285px;
}

/**
 * Mobile View
 */
.horizontalScrollWrapper {
  display: none;
}

.mobileView {
  display: flex;
}

.mobileCard {
  border: v-standardBorder;
  border-radius: 4px;
  display: flex;
  flex: 1 0 auto;
  padding: 15px;
  width: 280px;
}

.mobileCard + .mobileCard {
  margin-left: 30px;
}

.mobileCardInfo {
  max-height: 82px;
  overflow-y: auto;
}

.mobileImageWrapper {
  align-self: center;
  background-color: v-fog;
  display: block;
  height: 82px;
  margin-right: 15px;
  max-height: 82px;
  min-height: 82px;
  min-width: 82px;
  width: 82px;
}

/**
 * Dummy View
 */
.dummyMobileView {
  display: none;
  overflow: auto;
}

.dummyImageSmall {
  background-color: v-fog;
  height: 70px;
  width: 70px;
}

.dummyImageLarge {
  background-color: v-fog;
  height: 281px;
  width: 375px;
}

.dummyImageTwoBy {
  background-color: v-fog;
  height: 212px;
  overflow: hidden;
  min-height: 212px;
  min-width: 285px;
  width: 285px;
}

.dummyMobileCardImage {
  background-color: v-fog;
  height: 82px;
  margin-right: 15px;
  width: 82px;
}

@media v-med-large {
  .defaultView { display: none; }

  .dummyMobileView { display: flex; }

  .horizontalScrollWrapper { display: block; }

  .titleWrapper {
    margin-bottom: 0; /* Horizontal Scroll adds its own margin */
  }

  .twoByView { display: none; }
}
