@value v-asphalt from '../../../../../styles/global_ui/colors.css';

.day {
  font-size: 36px;
  line-height: 28px;
}

.eventDate {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}

.eventDateVertical {
  composes: eventDate;
  display: inline-block;
  margin-bottom: 20px;
}

.eventDateHorizontal {
  composes: eventDate;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background: v-asphalt;
}
