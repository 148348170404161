@value v-standardBorder from '../../../../styles/global_ui/util.css';
@value v-med from '../../../../styles/breakpoints.css';

.body {
  flex: 1 1 100%;
  max-width: 615px;
  min-width: 1px;
}

.content {
  word-break: break-word;
}

.sidebar {
  flex: 0 0 280px;
}

.sidebarInner {
  display: flex;
  flex-direction: column;
}

.signupCTA {
  padding-top: 45px;
}

@media v-med {
  .coverImage {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .row1 {
    flex-direction: column;
  }

  .sidebar {
    flex: 1 1 0%;
  }

  .sidebarInner {
    display: flex;
    flex-direction: column-reverse;
  }

  .signupCTA {
    border-top: v-standardBorder;
    margin-top: 45px;
  }
}
