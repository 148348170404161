@value v-graphite, v-white from '../../../../../../styles/global_ui/colors.css';

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  backgroud-color: v-white;
  color: v-graphite;
  cursor: arrow;
  padding: 5px;
}

.focused {
  background-color: var(--fog);
}
