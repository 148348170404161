@value v-large, v-small from '../../../styles/breakpoints.css';
@value v-error, v-hackster, v-white from '../../../styles/global_ui/colors.css';

.container {
  background: v-white;
  margin-right: -30px;
  margin-top: 30px;
  max-width: 355px;
  min-width: 355px; /* For FireFox */
  z-index: 1000;
}

.flexLargeRightSmallCenter {
  display: flex;
  justify-content: flex-end;
}

@media v-large {
  .container {
    margin-right: 30px;
    margin-top: 30px;
  }
}

@media v-small {
  .container {
    margin: 30px 15px 0px;
  }

  .flexLargeRightSmallCenter {
    justify-content: center;
  }
}
