@value v-asphaltDisabled, v-hackster, v-hacksterHover, v-white from '../../../../styles/global_ui/colors.css';

.card:hover, .label:focus-within > div {
  outline: 1px solid v-hacksterHover;
}

.card:disabled {
  opacity: .5;
  background-color: v-asphaltDisabled;
}

.selected {
  outline: 2px solid v-hackster;
  border-color: v-white;
  position: relative;
}

.icon {
  right: 10px;
  top: 10px;
  padding: 2px;
}
