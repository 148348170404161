/**
 * Updates not in invision prototype:
 * Added 22px spacing unit
 */

@value v-small, v-med, v-med-large, v-large, v-small-min, v-med-min, v-med-large-min, v-large-min from '../breakpoints.css';
@value v-white from './colors.css';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 15px;
}

.dashboardPanel960 {
  background-color: v-white;
  border: var(--standardBorder);
  border-radius: 2px;
  max-width: 960px;
  min-height: 500px;
  width: 100%;
}

.wrapper960,
.wrapper1170 {
  width: 100%;
}

.wrapper960 {
  max-width: 960px;
}

.wrapper1170 {
  max-width: 1170px;
}

.block { display: block !important; }

.borderNone {
  border: none !important;
}

/* layout utilities */
.alignSelfCenter { align-self: center; }
.alignSelfStart { align-self: start; }
.flex { display: flex !important; }
.flexAlignSelfEnd {
  align-self: flex-end;
}
.flexStartItems {
  align-items: flex-start;
  display: flex;
}
.flexCenterItems {
  align-items: center;
  display: flex;
}
.flexEndItems {
  align-items: flex-end;
  display: flex;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexColumnCenterCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flexColumnStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flexColumnTopCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.flexColumnBetweenCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between
}

.flexColumn.gap0 > *:not(:first-child) {
  margin-top: 0px;
}
.flexColumn.gap10 > *:not(:first-child) {
  margin-top: 10px;
}
.flexColumn.gap15 > *:not(:first-child) {
  margin-top: 15px;
}
.flexColumn.gap30 > *:not(:first-child) {
  margin-top: 30px;
}
.flexColumn.gap45 > *:not(:first-child) {
  margin-top: 45px;
}
.flexColumn.gap60 > *:not(:first-child) {
  margin-top: 60px;
}

/* flex: 1 should behave like flex: 1 1 0 but doensn't in all browsers. https://stackoverflow.com/a/42630660 */
/* also, unitless flex-basis values can cause inconsistencies, so we need to add a % to the 0 */
.flex1 { flex: 1 1 0%; }
.flex00Auto { flex: 0 0 auto; }
.flex10Auto { flex: 1 0 auto; }
.flexCenterCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexJustifyCenter {
  display: flex;
  justify-content: center;
}
.flexJustifyStart {
  display: flex;
  justify-content: flex-start;
}
.flexJustifySpaceAround {
  display: flex;
  justify-content: space-around;
}
.flexJustifySpaceBetween {
  display: flex;
  justify-content: space-between;
}
.flexJustifyEnd {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.flexRow {
  display: flex;
  flex-direction: row;
}

.gutter2 {
  gap: 2px;
}
.gutter5 {
  gap: 5px;
}
.gutter10 {
  gap: 10px;
}
.gutter15 {
  gap: 15px;
}
.gutter20 {
  gap: 20px;
}
.gutter22 {
  gap: 22px;
}
.gutter25 {
  gap: 25px;
}
.gutter30 {
  gap: 30px;
}
.gutter45 {
  gap: 45px;
}
.gutter60 {
  gap: 60px;
}

.flexRow.gap0 > *:not(:first-child) {
  margin-left: 0px;
}
.flexRow.gap10 > *:not(:first-child) {
  margin-left: 10px;
}
.flexRow.gap15 > *:not(:first-child) {
  margin-left: 15px;
}
.flexRow.gap30 > *:not(:first-child) {
  margin-left: 30px;
}
.flexRow.gap45 > *:not(:first-child) {
  margin-left: 45px;
}
.flexRow.gap60 > *:not(:first-child) {
  margin-left: 60px;
}

.flexRowWrap {
  display: flex;
  flex-flow: row wrap;
}
.flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
}
.flexColumnWrap {
  display: flex;
  flex-flow: column wrap;
}

.fullScreenHeight { min-height: 100vh; }
.fullHeight { height: 100% !important; }
.heightAuto { height: auto !important; }
.fullWidth { width: 100% !important; }
.widthFit { width: fit-content !important;}

.inlineFlex {
  display: inline-flex;
}

.marginAuto { margin: auto !important; }

.margin0 { margin: 0 !important; }
.margin10 { margin: 10px !important; }

.marginBottom0 { margin-bottom: 0 !important; }
.marginBottom5 { margin-bottom: 5px !important; }
.marginBottom10 { margin-bottom: 10px !important; }
.marginBottom15 { margin-bottom: 15px !important; }
.marginBottom22 { margin-bottom: 22px !important; }
.marginBottom25 { margin-bottom: 25px !important; }
.marginBottom30 { margin-bottom: 30px !important; }
.marginBottom45 { margin-bottom: 45px !important; }
.marginBottom60 { margin-bottom: 60px !important; }

.marginLeftAuto { margin-left: auto; }
.marginLeft0 { margin-left: 0 !important; }
.marginLeft3 { margin-left: 3px !important; }
.marginLeft5 { margin-left: 5px !important; }
.marginLeft10 { margin-left: 10px !important; }
.marginLeft15 { margin-left: 15px !important; }
.marginLeft30 { margin-left: 30px !important; }
.marginLeft45 { margin-left: 45px !important; }
.marginLeft60 { margin-left: 60px !important; }

.marginRight0 { margin-right: 0 !important; }
.marginRight2 { margin-right: 2px !important; }
.marginRight5 { margin-right: 5px !important; }
.marginRight10 { margin-right: 10px !important; }
.marginRight15 { margin-right: 15px !important; }
.marginRight30 { margin-right: 30px !important; }
.marginRight45 { margin-right: 45px !important; }
.marginRight60 { margin-right: 60px !important; }

.marginTopAuto { margin-top: auto !important; }
.marginTop0 { margin-top: 0 !important; }
.marginTop5 { margin-top: 5px !important; }
.marginTop10 { margin-top: 10px !important; }
.marginTop12 { margin-top: 12px !important; }
.marginTop15 { margin-top: 15px !important; }
.marginTop22 { margin-top: 22px !important; }
.marginTop30 { margin-top: 30px !important; }
.marginTop45 { margin-top: 45px !important; }
.marginTop60 { margin-top: 60px !important; }

.maxWidth100P { max-width: 100%; }
.maxWidth575 { max-width: 575px; }

.noScrollBar::-webkit-scrollbar { display:none; }
.noScrollBar::-moz-scrollbar { display:none; }
.noScrollBar::-o-scrollbar { display:none; }
.noScrollBar::-google-ms-scrollbar { display:none; }
.noScrollBar::-khtml-scrollbar { display:none; }

.padding0 { padding: 0 !important; }
.padding5 { padding: 5px !important; }
.padding10 { padding: 10px !important; }
.padding15 { padding: 15px !important; }
.padding22 { padding: 22px !important; }
.padding30 { padding: 30px !important; }
.padding60 { padding: 60px !important; }

.padding015 { padding: 0px 15px !important; }
.padding510 { padding: 5px 10px; }
.padding1015 { padding: 10px 15px !important; }
.padding1020 { padding: 10px 20px !important; }
.padding2015 { padding: 20px 15px !important; }
.padding3015 { padding: 30px 15px !important; }
.padding3020 { padding: 30px 20px !important; }
.padding4560 { padding: 45px 60px !important; }

.paddingBottom0 { padding-bottom: 0 !important; }
.paddingBottom5 { padding-bottom: 5px !important; }
.paddingBottom10 { padding-bottom: 10px !important; }
.paddingBottom15 { padding-bottom: 15px !important; }
.paddingBottom22 { padding-bottom: 22px !important; }
.paddingBottom30 { padding-bottom: 30px !important; }
.paddingBottom45 { padding-bottom: 45px !important; }
.paddingBottom60 { padding-bottom: 60px !important; }

.paddingLeft0 { padding-left: 0px !important; }
.paddingLeft5 { padding-left: 5px !important; }
.paddingLeft10 { padding-left: 10px !important; }
.paddingLeft15 { padding-left: 15px !important; }
.paddingLeft20 { padding-left: 20px !important; }
.paddingLeft30 { padding-left: 30px !important; }

.paddingRight0 { padding-right: 0px !important; }
.paddingRight10 { padding-right: 10px !important; }
.paddingRight15 { padding-right: 15px !important; }
.paddingRight45 { padding-right: 45px !important; }

.paddingTop0 { padding-top: 0px !important; }
.paddingTop10 { padding-top: 10px !important; }
.paddingTop15 { padding-top: 15px !important; }
.paddingTop22 { padding-top: 22px !important; }
.paddingTop30 { padding-top: 30px !important; }
.paddingTop45 { padding-top: 45px !important; }
.paddingTop60 { padding-top: 60px !important; }

@media v-small {
  .hiddenSmallDown {
    display: none !important;
  }
}

@media v-small-min {
  .hiddenSmallUp {
    display: none !important;
  }
}

@media v-med {
  .hiddenMedDown {
    display: none !important;
  }
}

@media v-med-min {
  .hiddenMedUp {
    display: none !important;
  }

  .flexCenterItemsMedUp {
    align-items: center;
    display: flex;
  }
}

@media v-med-large {
  .hiddenMedLargeDown {
    display: none !important;
  }
}

@media v-med-large-min {
  .hiddenMedLargeUp {
    display: none !important;
  }
}

@media v-large {
  .hiddenLargeDown {
    display: none !important;
  }
}

@media v-large-min {
  .hiddenLargeUp {
    display: none !important;
  }
}
