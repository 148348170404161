.enter {
  opacity: 0.01;
  right: -500px;
}
.enterActive {
  opacity: 1;
  right: 0px;
  transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.enterDone {
  right: 0px;
}
.exit {
  opacity: 1;
  right: 0px;
}
.exitActive {
  opacity: 0;
  right: -500px;
  transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}