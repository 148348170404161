@value v-small from '../../../../styles/breakpoints.css';

.dialogWrapper {
  margin-left: 30px;
  margin-right: 30px;
  max-width: 675px;
  padding: 45px 30px;
}

@media v-small {
  .dialogWrapper {
    margin-left: 15px;
    margin-right: 15px;
    padding: 45 15px;
  }
}
