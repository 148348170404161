.enter, .appear {
  transform: translateX(100%);
}

.enter.enterActive, .appear.appearActive {
  transform: translateX(0);
}

.exit {
  transform: translateX(0);
}

.exit.exitActive {
  transform: translateX(100%);
}

.appear.appearActive, .enter.enterActive, .exit.exitActive {
  transition: transform 250ms ease-out;
}
