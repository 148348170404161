.title {
  margin-bottom: 60px;
  text-align: center;
}

/**
 * VerifyAddressView
 */
.footnoteMsgs {
  display: flex;
  flex-direction: column;
}

.footnoteMsg {

}