@value v-white from '../../../../styles/global_ui/colors.css';
@value v-med from '../../../../styles/breakpoints.css';

.container {
  justify-content: space-between;
}

.image {
  background-color: v-white;
}

.imageContainer {
  width: 740px;
}

.infoColumn {
  justify-content: space-between;
  width: 370px;
}

@media v-med {
  .container {
    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
  }

  .infoColumn {
    margin: 0 0 30px;
    width: 100%;
  }
}
