@value v-standardBorder from '../../../styles/global_ui/util.css';

.card {
  flex: 0 0 120px;
  text-align: center;
  width: 120px;
}

.image {
  border: var(--secondaryBorder);
  background: var(--cardBGColor);
  border-radius: 4px;
  flex: 0 0 120px;
  height: 120px;
  padding: 5px;
  width: 120px;
}

.dummyImage {
  composes: loader from '../../../styles/animation.css';
  composes: image;
}


.dummyTitle {
  composes: loader from '../../../styles/animation.css';
  height: 15px;
  margin-top: 5px;
}

.wrapper {
  composes: wrapper from '../cards.css';
}

.wrapper + .wrapper {
  margin-left: 30px;
}
