.root {
  border-width: 2px;
  position: fixed;
  max-width: 90%;
  padding: 15px 20px;
  top: 10px;
  width: 500px;
  z-index: 1050;
}

.body {
  padding: 3% 10%;
}

.error {
  background-color: var(--errorBG);
  color: var(--errorToWhite);

  .body {
    border-left: 14px solid var(--errorToDarkError);
  }
}

.closeBtn {
  background: transparent;
  border: none;
  position: absolute;
  top: 15px;
  right: 25px;

  &:focus { outline: none; }
}

.success {
  background-color: var(--successBG);
  color: var(--successToWhite);

  .body {
    border-left: 14px solid var(--successToForest);
  }
}
