@value v-med from '../../../styles/breakpoints.css';
@value v-largeBorderRadius, v-standardBorder from '../../../styles/global_ui/util.css';

.details {
  border-radius: 20px;
}

.summary {
  cursor: pointer;
  padding: 22px 30px;
  font-size: 32px;
  gap: 5px;
  margin-bottom: 0px;
  transition: all 350ms ease-in-out;
}

.summary::-webkit-details-marker {
  display: none;
}

.marker {
  transition: all 250ms ease-in-out;
  min-width: 16px;
}

.details[open] > .summary {
  border-bottom: v-standardBorder;
  margin-bottom: 10px;
  transition: all 350ms ease-in-out;

  .marker {
    transform: rotate(180deg);
  }
}

.details > .content {
  padding: 0px 90px;
  transition: padding 250ms ease-in-out;
}

.details[open] > .content {
  padding: 50px 90px 60px;
  transition: padding 250ms ease-in-out;
}

@media v-med {
  .details {
    border-radius: v-largeBorderRadius;
    border: v-standardBorder;
  }
  .summary {
    padding: 18px 18px 18px 10px;
    font-size: 16px;
  }
  .details[open] > .content {
    padding: 30px 20px;
  }
}