.radiusTop {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.overlay {
  align-items: center;
  background: rgba(0,0,0,0.2);
  cursor: pointer;
  display: flex;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.overlay:hover .playButton circle {
  fill: rgba(0,0,0,0.25);
}

.thumbnail {
  position: relative;
}


/* dummy styles */
.dummyImage {
  composes: loader from '../../../styles/animation.css';
  height: 0;
  padding-top: 56.25%;
  width: 100%;
}

.dummyText {
  composes: block from '../project_card/dummy_card.css';
  width: 100%;
}
